import React, { useState, useEffect } from "react";

import SystemError from "./SystemError";
import TradeRelated from "./TradeRelated";

export default function Notification(props) {
  const { type } = props;

  return (
    <div className="col-lg-8">
      {type && type == "system_error" && <SystemError />}
      {type && type == "trade_relate" && <TradeRelated />}
    </div>
  );
}
