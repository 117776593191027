import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// import lib
import isLogin from "../../lib/isLogin";

const ConditionRoute = (props) => {
  const { type, children } = props;

  if (type === "auth" && isLogin() === true) {
    return <Navigate to="/trade/BTC-USDT/Binance/dca" />;
  } else if (type === "private" && isLogin() !== true) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default React.memo(ConditionRoute);
