import React, { useEffect, useState } from "react";
import Header from "../Header.js";
import ProfileSideMenu from "../ProfileSideMenu";
import Footer from "../Footer.js";

import PersonalDet from "./PersonalDet";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Profile() {
  // state
  const [editable, setEditable] = useState(true);

  const handleChange = () => {
    setEditable(false);
  };
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 d-none d-lg-block">
              <ProfileSideMenu />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="userContent">
                <div className="flexTitle profileTitleFlex">
                  <h2>My Profile</h2>
                  <button className="edit_btn" onClick={handleChange}>
                    <i className="bi bi-pencil-fill"></i> Edit
                  </button>
                </div>
                <PersonalDet editable={editable} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
