// import constant
import { SET_BOT_TYPE } from "../constant";

const initialValue = {
  tab: "open",
};
const botType = (state = initialValue, action) => {
  switch (action.type) {
    case SET_BOT_TYPE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default botType;
