// import constant
import { SET_BOT_ID } from "../constant";

const initialValue = {
  positionId: "",
};
const botId = (state = initialValue, action) => {
  switch (action.type) {
    case SET_BOT_ID:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default botId;
