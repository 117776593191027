// import package
import React, { useState, useEffect, Fragment, memo } from "react";
import { Link } from "react-router-dom";
import config from "../config";

const Logo = () => {
  console.log("looooooo");
  return (
    <a href="/trade/BTC-USDT/Binance/dca" className="navbar-brand">
      <img
        src={config.API_URL + "/settings/logo.png?" + new Date().getTime()}
        className="img-fluid"
        alt=""
      />
      {/* <img
        src={require("../assets/images/logo_icon.png")}
        className="img-fluid d_d_none"
        alt=""
      /> */}
    </a>
  );
};

export default memo(Logo);
