import React, { useEffect } from "react";
import Header from "../components/Header.js";
import NotificationSideMenu from "../components/NotificationSideMenu";
import Footer from "../components/Footer.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function NotificationAlerts() {
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <NotificationSideMenu />
            </div>
            <div className="col-lg-8">
              <div className="userContent">
                <div className="flexTitle">
                  <h2>Alerts</h2>
                  {/* <div className="d-flex">
                        <label class="label_large me-3">Allow Notification</label>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                        </div>
                      </div> */}
                </div>
                <div className="notification_panel">
                  <div className="mt-3 text-center">
                    <h4>No Notifications Found</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
