// import constant
import { SET_GRIDBOT_STATUS } from "../constant";

const initialValue = {
  id: "",
  status: false,
  botData: {},
};
const gridbotStatus = (state = initialValue, action) => {
  // console.log(state, action, "---------5234653463456745")
  switch (action.type) {
    case SET_GRIDBOT_STATUS:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default gridbotStatus;
