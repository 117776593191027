let key = {};
if (process.env.REACT_APP_MODE == "production") {
  console.log("Set Production Config");
  const API_URL = "https://api.miraclebot.online";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "DCA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://api.miraclebot.online",

    SOCKET_TICKERBINANCE_URL: `https://binanceapi.miraclebot.online`,
    SOCKET_TICKERBITMART_URL: `https://stagingbitmart.wearedev.team`,
    SOCKET_TICKERKUCOIN_URL: `https://stagingkucoin.wearedev.team`,
    SOCKET_TICKERBYBIT_URL: `https://stagingbybit.wearedev.team`,

    FRONT_URL: "https://www.miraclebot.online",
    ADMIN_URL: "https://controls.miraclebot.online",
    SOCKET_URL: "https://api.miraclebot.online",
    getGeoInfo: "https://ipapi.co/json/",
    ipBind:
      "148.113.6.215,148.113.12.195,148.113.12.196,148.113.12.197,148.113.12.198,148.113.12.199,148.113.12.200,148.113.12.201,148.113.12.202",
    ipBindBm:
      "198.244.215.81,51.68.218.160,51.77.126.79,51.77.100.232,51.68.218.161,51.68.218.213,51.77.126.126,51.77.126.137,51.77.126.138",

    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE == "miracle_staging") {
  console.log("Set Miracle Config");
  const API_URL = "https://api.miraclebot.online";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "DCA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://api.miraclebot.online",

    SOCKET_TICKERBINANCE_URL: `https://binancetickerapi.miraclebot.online`,
    SOCKET_TICKERBITMART_URL: `https://bitmarttickerapi.miraclebot.online`,
    SOCKET_TICKERKUCOIN_URL: `https://stagingkucoin.wearedev.team`, // we don't use this
    SOCKET_TICKERBYBIT_URL: `https://bybittickerapi.miraclebot.online`,

    FRONT_URL: "https://www.miraclebot.online",
    ADMIN_URL: "https://controls.miraclebot.online",
    SOCKET_URL: "https://api.miraclebot.online",
    getGeoInfo: "https://ipapi.co/json/",
    ipBind:
      "198.244.215.81,51.68.218.160,51.77.126.79,51.77.100.232,51.68.218.161,51.68.218.213,51.77.126.126,51.77.126.137,51.77.126.138",
    ipBindBm:
      "198.244.215.81,51.68.218.160,51.77.126.79,51.77.100.232,51.68.218.161,51.68.218.213,51.77.126.126,51.77.126.137,51.77.126.138",

    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else if (process.env.REACT_APP_MODE == "client_stagging") {
  console.log("Set client_staggin Config");
  const API_URL = "https://arbitribeapi.arbitribe.live";

  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    // RECAPTCHA_SITE_KEY: "6LfBAlcgAAAAAIeo-0Q2m2gh35MnNt7GDfbOaz2o", //local
    REFERRAL_CODE: "DCA1234",
    RECAPTCHA_SITE_KEY: "6LeZE38iAAAAAJELdMKs5UMOycTRcmUpOHKlrGPx", //local secret_Key: 6LeZE38iAAAAANVOgrzpnv8I63UDHtGFO8NiigB8
    API_URL: "https://arbitribeapi.arbitribe.live",

    SOCKET_TICKERBINANCE_URL: `https://arbitribebinanceapi.arbitribe.live`,
    SOCKET_TICKERBITMART_URL: `https://arbitribebitmarttickerapi.arbitribe.live`,
    SOCKET_TICKERKUCOIN_URL: `https://stagingkucoin.wearedev.team`,
    SOCKET_TICKERBYBIT_URL: `https://arbitribebybittickerapi.arbitribe.live`,
    FRONT_URL: "https://arbibot.arbitribe.live",
    ADMIN_URL: "https://arbiadmin.arbitribe.live",
    SOCKET_URL: "https://arbitribeapi.arbitribe.live",
    getGeoInfo: "https://ipapi.co/json/",
    ipBind:
      "148.113.6.215,148.113.12.195,148.113.12.196,148.113.12.197,148.113.12.198,148.113.12.199,148.113.12.200,148.113.12.201,148.113.12.202", //binace and bybit
    ipBindBm:
      "198.244.215.81,51.68.218.160,51.77.126.79,51.77.100.232,51.68.218.161,51.68.218.213,51.77.126.126,51.77.126.137,51.77.126.138", //bitmart
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://127.0.0.1"; // 'http://localhost' 'http://192.168.0.124';  //http://192.168.29.63:3000/  http://192.168.0.124
  key = {
    secretOrKey: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LeHezUfAAAAAE_uuY_HFN5HoEVsQv8bpyC3xTat", //local
    REFERRAL_CODE: "DCA1234",
    API_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000", //'http://localhost:3000',
    ADMIN_URL: "http://localhost:3001/admin",
    SOCKET_URL: `${API_URL}:2053`,
    SOCKET_TICKERBINANCE_URL: `${API_URL}:2055`,
    SOCKET_TICKERBITMART_URL: `${API_URL}:2056`,
    // SOCKET_TICKERKUCOIN_URL: `${API_URL}:2057`,
    SOCKET_TICKERBYBIT_URL: `${API_URL}:2057`,

    ipBind:
      "148.113.6.215,148.113.12.195,148.113.12.196,148.113.12.197,148.113.12.198,148.113.12.199,148.113.12.200,148.113.12.201,148.113.12.202",
    ipBindBm:
      "198.244.215.81,51.68.218.160,51.77.126.79,51.77.100.232,51.68.218.161,51.68.218.213,51.77.126.126,51.77.126.137,51.77.126.138",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
  };
}

export default {
  ...key,
  ...{ SITE_DETAIL: require("./siteConfig").default },
};
