// import constant
import { SET_BOT_EDIT } from "../constant";

const initialValue = {
  isAuth: false,
};
const botEdit = (state = initialValue, action) => {
  switch (action.type) {
    case SET_BOT_EDIT:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default botEdit;
