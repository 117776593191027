// import config
import axios, { handleResp } from "../config/axios";
import {
  RESET,
  SET_GRIDBOT_STATUS,
  SET_GRID_BOT_DETAIL,
  SET_GRID_SB_OPEN,
  UPDATE_GRID_BOT_DETAIL,
} from "../constant";

export const GridOrderPlace = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid/grid-orderplace`,
      data: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    console.log("----636", err.response.data.message);
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGridTradeHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid/getTradeHistory`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const openBotApi = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/grid/open-bot`,
      params: data,
    });
    return {
      status: "success",
      message: respData.data.message,
      result: respData.data.result,
      pairData: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      message: err.response.data.message,
    };
  }
};

export const closeBotApi = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/grid/close-bot`,
      params: data,
    });
    return {
      status: "success",
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      message: err.response.data.message,
    };
  }
};

export const closeBot = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid/close-bot`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const modifyGrid = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid/modify-bot`,
      data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const botDetail = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/grid/bot-detail`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const setGridBotStatus = (data) => {
  console.log(data, "-----------data3243r");
  return { type: SET_GRIDBOT_STATUS, data };
};

export const getGridBotHistory = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/api/grid/getGridBotHistory`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
export const getGCHistory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/grid/getGCHistory`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const curBotDetail = (data, dispatch) => {
  try {
    dispatch({ type: SET_GRID_BOT_DETAIL, data });
    return true;
  } catch (err) {
    return false;
  }
};
export const openSB = (data, dispatch) => {
  try {
    dispatch({ type: SET_GRID_SB_OPEN, data });
    return true;
  } catch (err) {
    return false;
  }
};
export const resetCurBotDetail = (dispatch) => {
  try {
    dispatch({ type: RESET });
    return true;
  } catch (err) {
    return false;
  }
};

export const getOpenPosition = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/api/grid/get-open-position`,
      params: data,
    });
    return {
      status: "success",
      loading: false,
      message: respData.data.message,
      result: respData.data.result,
      pairData: respData.data.pairData,
    };
  } catch (err) {
    handleResp(err, "error");
    return {
      status: "failed",
      loading: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};
