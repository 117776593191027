// import package
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";

// import component
import Chart from "../BacktestChart/DCA/Chart";

// import lib
import { dateTimeFormat } from "../../lib/dateFilter.js";
import { firstCapital } from "../../lib/String.js";
import DatePicker from "react-datepicker";
import { toFixed } from "../../lib/roundOf.js";
import isEmpty from "../../lib/isEmpty.js";

// const initChrData = {
//   stoploss: {},
//   takeprofit: {},
//   avgData: [],
//   avgPrice: {},
// };

let thirtyDate = new Date(new Date().setDate(new Date().getDate() - 30));

let backForm = {
  tFee: 0.1,
  startDate: thirtyDate,
  endDate: new Date(),
  editable: false,
};
const Backtest = (props) => {
  console.log("Backtest dca-----");

  // props
  const {
    bTModal,
    toggleBtModal,
    data,
    params,
    tradedata,
    pData,
    updateBTest,
  } = props;

  const initChrParam = {
    pair: params.pair.replace(/\-/g, ""),
    exchange: params.exchange,
  };

  // state
  const [tab, setTab] = useState("complete"); // complete | open
  // const [chrData, setChrData] = useState(initChrData);
  const [chrParams, setChrParams] = useState(initChrParam);
  const [trxData, setTrxData] = useState([]);
  const [openData, setOpenData] = useState([]);

  const [bForm, setBForm] = useState(backForm);
  const [errMsg, setErrMsg] = useState({});
  // function
  const handleChangeTab = (t) => {
    setTab(t);
  };
  let { tFee, startDate, endDate, editable } = bForm;

  // useEffect(() => {
  //   if (data && data.orders && data.orders.length > 0) {
  //     const completed = [];
  //     const open = [];
  //     data.orders.forEach((obj) => {
  //       if (obj.st === "completed") {
  //         completed.push(obj);
  //       } else if (obj.st === "open") {
  //         open.push(obj);
  //       }
  //     });
  //     console.log(completed, "----completed");
  //     console.log(open, "----open");

  //     setTrxData(completed);
  //     setOpenData(open);
  //   }

  // }, [data]);

  useEffect(() => {
    setChrParams({
      pair: params.pair.replace(/\-/g, ""),
      exchange: params.exchange,
    });
  }, [params.pair]);

  const splitOne = (data, type) => {
    let check = data.split("-");
    if (type == "first") {
      return check[0];
    }
    if (type == "second") {
      return check[1];
    }
  };
  useEffect(() => {
    if (
      !isEmpty(tFee) &&
      parseFloat(tFee) > 0 &&
      !isEmpty(startDate && startDate.toString()) &&
      !isEmpty(endDate && endDate.toString()) &&
      !isEmpty(tradedata.investmentAmount) &&
      tradedata.investmentAmount > 0 &&
      editable
    ) {
      updateBTest(bForm);
    }
  }, [startDate, endDate, tFee]);
  const columns = [
    {
      name: "Date & Time",
      selector: (row) => dateTimeFormat(row.tb),
      width: "155px",
    },
    {
      name: "Side",
      width: "80px",
      selector: (row) => (
        <span className={row.side === "buy" ? "greenTxt" : "redText"}>
          {firstCapital(row.side)}
        </span>
      ),
    },
    {
      name: "Amount," + splitOne(params.pair, "first"),
      selector: (row) => row.quantity,
    },
    {
      name: "Price buy",
      selector: (row) => row.price,
    },
    {
      name: "Fee",
      selector: (row) => row.tfee,
    },
    {
      name: "Profit, USDT",
      selector: (row) => row.profit?.pf,
    },
    {
      name: "Total, USDT",
      selector: (row) => row.profit?.percent_pf,
    },
  ];
  const hDChange = (type, date) => {
    setErrMsg({});

    if (date < thirtyDate || date > new Date()) {
      // setErrMsg({ date: "Select One Month Date" });
      return;
    }
    if (isEmpty(date && date.toString())) {
      setErrMsg({ date: "Kindly Select Correct Date" });
      return;
    }
    let formValue = bForm;
    if (type == "start") {
      formValue = { ...formValue, ...{ startDate: date } };
    }
    if (type == "end") {
      formValue = { ...formValue, ...{ endDate: date } };
    }
    formValue = { ...formValue, ...{ editable: true } };
    setBForm(formValue);
  };
  const handleChange = (e) => {
    setErrMsg({});
    let { value } = e.target;
    if (isEmpty(value) || value <= 0) {
      setErrMsg({ tFee: "Please set the Fee to a value greater than 0" });
    }
    setBForm({ ...bForm, ...{ tFee: e.target.value, editable: true } });
  };

  return (
    <Modal
      show={bTModal}
      onHide={toggleBtModal}
      centered
      size="xl"
      className="modal fade primary_modal primary_modal_large backtest_modal"
    >
      <Modal.Header closeButton>
        <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
          Backtest
        </h1>
      </Modal.Header>
      <Modal.Body>
        <div className="filter_flx p-3">
          <div>
            <div>
              <div>
                <div aria-label="">
                  <span class="defwrsu_0tOVO9Ab8q1t">
                    <span>{splitOne(params.pair, "first")}</span> /{" "}
                    <span>{splitOne(params.pair, "second")}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            Backtest mode is&nbsp;using historical data to&nbsp;emulate bot
            trading. {tradedata.investmentAmount} USDT is&nbsp;used
            as&nbsp;an&nbsp;initial investment. Please remember, past
            performance is&nbsp;not indicative of&nbsp;future results.
          </div>

          <div class="createbot_box trade_fee">
            <div
              class={
                errMsg && errMsg.tFee
                  ? "inputgrp_box mb-12 red_border"
                  : "inputgrp_box mb-12"
              }
            >
              <div class="w-100 input-group">
                <label class="grey">Trade Fee </label>
                <input
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={tFee}
                  onChange={(e) => handleChange(e)}
                  class="form-control"
                />
                <span class="grey input-group-text" id="basic-addon1">
                  %
                </span>
              </div>
              <span
                className={
                  errMsg && errMsg.tFee
                    ? "text-danger error_msg"
                    : "text-danger"
                }
              >
                {errMsg && errMsg.tFee}
              </span>
            </div>
          </div>
          <div class="createbot_box date_pic me-2">
            <div
              class={
                errMsg && errMsg.date
                  ? "inputgrp_box mb-12 red_border"
                  : "inputgrp_box mb-12"
              }
            >
              <label className="grey">Date range</label>
              <div className="d-flex">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => hDChange("start", date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
                <div className="bg">-</div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => hDChange("end", date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                <div className="icon">
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium wH0RJFohAnbMUDd_Wkfj css-vubbuv"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                    fill="#5c5d6c"
                    data-testid="DateRangeIcon"
                  >
                    <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path>
                  </svg>
                </div>
                <span
                  className={
                    errMsg && errMsg.date
                      ? "text-danger error_msg"
                      : "text-danger"
                  }
                >
                  {errMsg && errMsg.date}
                </span>
              </div>
            </div>
          </div>
          {!editable && (
            <>
              {/* <div>
                <h6>3 days result</h6>
                <p className="green">{pData && pData?.threeDays}%</p>
              </div>
              <div>
                <h6>7 days result</h6>
                <p className="green">{pData && pData?.sevenDays}%</p>
              </div> */}
              <div>
                <h6>30 days result</h6>
                <p className="green">{pData && pData?.thirtyDays}%</p>
              </div>
            </>
          )}
          {editable && editable && (
            <div>
              <h6>
                {pData && pData?.days}{" "}
                {pData && pData?.days == 1 ? "day" : "days"} result
              </h6>
              <p className="green">{pData && pData?.diffDays}%</p>
            </div>
          )}
        </div>
        {toggleBtModal && (
          <Chart params={chrParams} data={data} className="img-fluid" />
        )}

        <div className="card trade_card trade_history_card">
          <div
            className="card-header"
            style={{
              background: "transparent",
              borderBottom: "none",
              paddingLeft: 0,
            }}
          >
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  type="button"
                  className={
                    tab === "complete" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => handleChangeTab("complete")}
                >
                  Transactions ({data && data.length})
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  type="button"
                  className={tab === "open" ? "nav-link active" : "nav-link"}
                  onClick={() => handleChangeTab("open")}
                >
                  Active Orders (0)
                </button>
              </li> */}
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane active show"
                id="complet-tb-tab"
                role="tabpanel"
                aria-labelledby="complet-tb-tab"
              >
                <div className="primary_table">
                  <DataTable columns={columns} data={data} pagination />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="open-bt-tab"
                role="tabpanel"
                aria-labelledby="open-bt-tab"
              >
                <div className="primary_table">
                  <DataTable columns={columns} data={openData} pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Backtest;
