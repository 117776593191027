import React, { Fragment, useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.png";
import { toFixed } from "../../lib/roundOf";
import { dateHours, dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";
import { firstCapital, firstOnCap } from "../../lib/String";
import { botDetail, getOpenPosition } from "../../actions/gridbot";
import { Modal } from "react-bootstrap";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function OpenPosition() {
  const [selectedValue, setSelectedValue] = useState("Binance");
  const [data, setData] = useState();
  const [hData, setHData] = useState();
  const [pair, setPair] = useState();
  const [gridbotMod, setGridBotMod] = useState(false);
  const [actives, setActives] = useState([]);

  const onGridModHide = async () => {
    setGridBotMod(false);
  };
  const getOpenOrder = async (row) => {
    try {
      let reqData = {
        botId: row._id,
      };
      const { status, result } = await botDetail(reqData);
      if (status == "success") {
        setGridBotMod(true);
        setActives(result.actives);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };
  const oColumns = [
    {
      name: "Exchange",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{row.exchange}</p>
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Pair / Bot type",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{row.pairName}</p>
            <div className="table_pairtype">
              <p className="mb-0">GRID</p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Investment",
      selector: (row) => {
        return calcInvt(row.initial, row.secondCurrency, 1);
      },
    },
    {
      name: "Current Value",
      selector: (row) => {
        return (
          <div>
            <p className="mb-0">
              {toFixed(row.curVal, 4)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </div>
        );
      },
      width: "15%",
    },
    {
      name: "Total PnL",
      selector: (row) => {
        return (
          <Fragment>
            <p className={row.pnl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
              {toFixed(row.pnl, 2)} %
            </p>
            <p className="mb-0">
              {toFixed(row.pnl / 100, 2)}{" "}
              <span className="greyTxt">{row.secondCurrency}</span>
            </p>
          </Fragment>
        );
      },
      width: "15%",
    },
    {
      name: "Txns / Time",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2"> {row?.txCnt} </p>
            <p className="mb-0 greyTxt">{dateHours(row.createdAt)}</p>
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <div>
            <p
              className={row && row.status == "close" ? "redText" : "greenTxt"}
            >
              {row && row.status}{" "}
              {/* <p className="greyTxt">{toFixed(0, 2)}{row.secondCurrency}</p> */}
            </p>
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <button
            className="primary_btn primary_black_btn m-0"
            onClick={() => getOpenOrder(row)}
          >
            +
          </button>
        );
      },
    },
  ];
  const activeCol = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type">
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{row.pairName}</p>
            <div className="table_pairtype">
              <p className="mb-0">GRID</p>
              {/* <p className="mb-0"> Long</p>  */}
            </div>
          </div>
        );
      },
    },
    {
      name: "Date & Time",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{dateTimeFormat(row.orderDate)}</p>
          </div>
        );
      },
      width: "150px",
    },
    {
      name: "Side",
      selector: (row) => {
        return (
          <p
            className={
              row && row.buyorsell == "sell" ? "redText mb-0" : "greenTxt mb-0"
            }
          >
            {firstOnCap(row.buyorsell)}
          </p>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{firstOnCap(row.action.replace("_", " "))}</p>
          </div>
        );
      },
    },
    {
      name: "Amount",
      selector: (row) => {
        return (
          <p className="mb-0">
            {toFixed(row.quantity, 6)}{" "}
            <span className="greyTxt">{row.firstCurrency}</span>
          </p>
        );
      },
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: (
        <div>
          <p className="mb-0">Status</p>
        </div>
      ),
      selector: (row) => {
        return (
          <div>
            <p className="greyTxt mb-2">{firstCapital(row.status)}</p>
            {/* <p className="tablebot_status mb-0">TU</p>  */}
          </div>
        );
      },
    },
  ];
  const calcInvt = (filled, secondCurrency, decimal) => {
    try {
      let totalInv = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      return (
        <p className="mb-0">
          {toFixed(totalInv, decimal)}{" "}
          <span className="greyTxt">{secondCurrency}</span>
        </p>
      );
    } catch (err) {
      return (
        <p className="mb-0">
          {toFixed(0, decimal)}{" "}
          <span className="greyTxt">{secondCurrency}</span>
        </p>
      );
    }
  };

  const handleSelect = (eventKey, event) => {
    if (eventKey != "All") {
      setSelectedValue(eventKey);
    }
    FOHistory(pair, eventKey);
  };

  const handleChange = async (e) => {
    const { value } = e.target;
    setPair(value);
    // FOHistory(value, selectedValue);
    var searcharr = [];
    if (hData && hData.length > 0) {
      for (var i = 0; i < hData.length; i++) {
        var temppair = hData[i].pairName.toLowerCase();
        if (temppair.indexOf(value.toLowerCase()) !== -1) {
          searcharr.push(hData[i]);
        }
        if (i == hData.length - 1) {
          setData(searcharr);
        }
      }
    }
  };

  const FOHistory = async (pair = "", exchange = "") => {
    console.log(!isEmpty(pair), "------pair");
    console.log(exchange, "------exchange");
    if (exchange == "All") {
      exchange = "";
    }

    try {
      let reqData = {
        botType: "Genie",
        exchange: exchange,
        pair: !isEmpty(pair) ? pair.toUpperCase() : pair,
      };

      const { status, result, pairData } = await getOpenPosition(reqData);
      if (status == "success") {
        let botList = [];
        for (let item of result) {
          let sPair = pairData.find((el) => el.exchange == item.exchange);
          let spotPair = sPair?.data.find(
            (el) => el._id.toString() == item.pairId.toString()
          );
          let pnl = calcPnl(item.actives, spotPair);
          let curVal = calcCV(item.filled, item.actives, spotPair);
          botList.push({ ...item, pnl: pnl, curVal: curVal });
        }

        setData(botList);
        setHData(botList);
      }
    } catch (err) {}
  };

  const calcPnl = (filled, spotData) => {
    try {
      let totalpnl = 0;
      if (filled && filled.length > 0) {
        for (let el of filled) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl =
              spotData.markPrice * el.quantity -
              prevOrd.price * prevOrd.quantity;
            totalpnl = totalpnl + pnl;
          }
        }
      }
      return totalpnl;
    } catch (err) {
      return 0;
    }
  };

  const calcCV = (initial, filled, spotData) => {
    try {
      let totalpnl = 0,
        totalInv = 0;

      if (initial && initial.length > 0) {
        for (let el of initial) {
          totalInv = totalInv + el.price * el.quantity;
        }
      }
      if (filled && filled.length > 0) {
        for (let el of filled) {
          if (el.side == "sell") {
            let prevOrd = el.prevOrd;
            let pnl =
              spotData.markPrice * el.quantity -
              prevOrd.price * prevOrd.quantity;
            totalpnl = totalpnl + pnl;
          }
        }
      }
      return totalInv + totalpnl;
    } catch (err) {
      return 0;
    }
  };
  useEffect(() => {
    FOHistory("", "Binance");
  }, []);

  const handleDownload = async (action) => {
    const csvData = [
      [
        "Pair / Bot type",
        "Date & Time",
        "Side",
        "Action",
        "Amount",
        "Price",
        "Status",
        "Exchange",
      ],
    ];

    for (let item of actives) {
      let arrData = [
        item.pairName,
        dateTimeFormat(item.orderDate),
        firstOnCap(item.buyorsell),
        item.action,
        `${toFixed(item.quantity, 6)} ${item.firstCurrency}`,
        item.price,
        firstCapital(item.status),
        item.exchange,
      ];
      csvData.push(arrData);
    }

    // Convert the data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "active.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };
  return (
    <>
      <div className="abv_flx justify-content-between">
        <div className="d-lg-flex gap-2 align-items-center">
          <div className="drpdwn_box">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="exchange">Exchange</span>
                {selectedValue}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="All">
                  {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
                  Select Exchange
                </Dropdown.Item>
                <Dropdown.Item eventKey="Binance">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  Binance
                </Dropdown.Item>

                <Dropdown.Item eventKey="Bybit">
                  <img src={bybit} class="img-fluid crypto me-2" alt="icon" />
                  Bybit
                </Dropdown.Item>

                <Dropdown.Item eventKey="BitMart">
                  <img src={bitmart} class="img-fluid crypto me-2" alt="icon" />
                  BitMart
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="inputgrp_box">
            <div class="input-group">
              <span class="input-group-text pe-0" id="basic-addon1">
                <img
                  src={require("../../assets/images/search.png")}
                  alt="Icon"
                  className="img-fluid search"
                />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search assets"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                name="pair"
                value={pair}
              />
            </div>
          </div>
        </div>
        {/* <div>
          <button className="primary_btn export_btn locked">
            <img
              src={require("../../assets/images/lock.png")}
              class="img-fluid me-2"
              alt="icon"
            />
            <span>Export CSV</span>
          </button>
        </div> */}
      </div>
      <div className="table_sec table_sec_chg">
        <DataTable columns={oColumns} data={data} pagination />
      </div>
      <Modal
        show={gridbotMod}
        onHide={onGridModHide}
        centered
        size="xl"
        className="modal fade primary_modal primary_modal_large"
      >
        <Modal.Header closeButton>
          <h1 class="modal-title fs-5" id="add_indicators_modalLabel">
            Bot orders
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="card trade_card trade_history_card">
            <div className="card-header">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="openorder-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#openorder"
                    type="button"
                    role="tab"
                    aria-controls="openorder"
                    aria-selected="false"
                  >
                    Open Orders{" "}
                  </button>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div
                className="tab-pane active show"
                id="openorder"
                role="tabpanel"
                aria-labelledby="openorder-tab"
              >
                <div className="primary_table">
                  <DataTable columns={activeCol} data={actives} pagination />
                </div>
                <div>
                  <button
                    className="continue w-auto mx-auto"
                    onClick={() => handleDownload("openOrder")}
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
