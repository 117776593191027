import React, { useEffect, useState } from "react";
import Header from "../components/Header.js";
import ProfileSideMenu from "../components/ProfileSideMenu";
import Footer from "../components/Footer.js";
import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import binance from "../assets/images/binance.svg";
import bitmart from "../assets/images/bitmart.png";
import kucoin from "../assets/images/kucoin.png";

import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
// import lib
import isEmpty from "../lib/isEmpty";
import { toastAlert } from "../lib/toastAlert";
import { Link } from "react-router-dom";
import clsx from "classnames";

import validation from "../components/ApiBinding/validation.js";
import { CopyToClipboard } from "react-copy-to-clipboard";

import config from "../config";
import { decryptString } from "../lib/cryptoJS";
import { SET_DASHBOARD_EXCHANGE } from "../constant";
import { useDispatch } from "react-redux";

// import action
import {
  createBind,
  editBind,
  deleteBind,
  sentEmailOtp,
  getBindData,
  KucoinBind,
  BitmartBind,
  getBalanceData,
  setBalanceData,
} from "../actions/users";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  apiKey: "",
  secretKey: "",
  code: "",
  riskStatus: false,
  type: "Binance",
  showapikey: false,
  showsecretKey: false,
  passphrase: "",
  memo: "",
};
export default function Myexchange() {
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [keyData, setKeyData] = useState();
  const [dltLoad, setDltLoad] = useState();
  const [loader, setLoader] = useState(false);
  const [resent, setResent] = useState(false);
  const [Timer, setTimer] = useState(300);
  const [bindData, setBindData] = useState([]);
  const dispatch = useDispatch();
  const [BalanceBinance, setBalanceBinance] = useState({});
  const [BalanceKucoin, setBalanceKucoin] = useState({});
  const [BalanceBitMart, setBalanceBitMart] = useState({});

  const {
    apiKey,
    secretKey,
    code,
    showapikey,
    showsecretKey,
    riskStatus,
    type,
    passphrase,
    memo,
  } = formValue;

  const [selectedValue, setSelectedValue] = useState("Binance"); // Initialize with a default value
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSelect = (eventKey, event) => {
    setSelectedValue(eventKey);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    // if (Timer == 0) {
    //   if (isEmpty(code)) {
    //     toastAlert(
    //       "success",
    //       "Please click 'Resend code' to receive the updated code in your mail id"
    //     );
    //   }
    // }
    let reqData = {
      type: selectedValue,
      apiKey: apiKey,
      secretKey: secretKey,
      code: code,
      riskStatus: riskStatus,
      passphrase: passphrase,
      memo: memo,
    };
    console.log(reqData, "---98");
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      if (selectedValue == "Binance") {
        const { status, loading, message, error } = await createBind(reqData);

        setLoader(loading);
        if (status == "success") {
          setResent(false);
          toastAlert("success", message, "kycsection");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          // fetchData();
        } else {
          if (error) {
            setValidateError(error);
          }
          toastAlert("error", message, "kycsection");
        }
      }

      if (selectedValue == "BitMart") {
        const { status, loading, message, error } = await BitmartBind(reqData);

        setLoader(loading);
        if (status == "success") {
          setResent(false);
          toastAlert("success", message, "kycsection");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          // fetchData();
        } else {
          if (error) {
            setValidateError(error);
          }
          toastAlert("error", message, "kycsection");
        }
      }

      if (selectedValue == "Kucoin") {
        const { status, loading, message, error } = await KucoinBind(reqData);

        setLoader(loading);
        if (status == "success") {
          setResent(false);
          toastAlert("success", message, "kycsection");
          setTimeout(() => {
            window.location.reload(true);
          }, 1000);
          // fetchData();
        } else {
          if (error) {
            setValidateError(error);
          }
          toastAlert("error", message, "kycsection");
        }
      }
    } catch (err) {
      console.log(err, "----118");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };

  const fetchData = async () => {
    const { status, loading, result, message, error } = await getBindData();
    if (status == "success") {
      setBindData(result);
    }
  };

  const fetchBalanceBinance = async () => {
    let exchangetype = "Binance";

    let reqData = { exchange: "Binance" };
    const { status, loading, result, message, error } = await getBalanceData(
      reqData
    );
    if (status == "success") {
      setBalanceBinance(result);
      setLoader(true);
    }
  };

  const fetchBalanceKucoin = async () => {
    let reqData = { exchange: "Kucoin" };
    const { status, loading, result, message, error } = await getBalanceData(
      reqData
    );
    if (status == "success") {
      setBalanceKucoin(result);
      setLoader(true);
    }
  };

  const fetchBalanceBitMart = async () => {
    let reqData = { exchange: "BitMart" };
    const { status, loading, result, message, error } = await getBalanceData(
      reqData
    );
    if (status == "success") {
      setBalanceBitMart(result);
      setLoader(true);
    }
  };

  const ApiBalanceBinance = async () => {
    let reqData = {
      exchange: "Binance",
    };
    const { status, result } = await setBalanceData(reqData);
    if (status == "success") {
      console.log(result, "-----211");

      setBalanceBinance(result);
      setLoader(true);
    }
  };

  useEffect(() => {
    fetchData();
    ApiBalanceBinance();
  }, []);

  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <div className="page-content mt-5 pt-5">
        <div className="container">
          <div className="my_exchange">
            <div className="head_flx mb-3">
              <h2 className="head_h2">My exchanges</h2>
            </div>

            <div className="row">
              {bindData &&
                bindData.map((item, key) => {
                  return (
                    <div className="col-md-3">
                      <div className="ex_card">
                        <div className="abv">
                          {item.exchange == "Binance" && (
                            <img src={binance} class="img-fluid" alt="icon" />
                          )}
                          {item.exchange == "Kucoin" && (
                            <img src={kucoin} class="img-fluid" alt="icon" />
                          )}
                          {item.exchange == "BitMart" && (
                            <img src={bitmart} class="img-fluid" alt="icon" />
                          )}
                          <p>{item.exchange}</p>
                        </div>
                        <div className="balance">
                          <span>Balance</span>
                          <span>
                            {item.exchange == "Binance" &&
                              BalanceBinance &&
                              BalanceBinance?.binance?.[1]?.free}{" "}
                            USDT
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="no_data py-5">
              <img
                src={require("../assets/images/nodata.png")}
                alt="Icon"
                className="img-fluid mb-3"
              />
              <p>No exchanges connected</p>
              <button
                className="primary_btn export_btn add_exchange"
                onClick={handleShow}
              >
                <span>+ Add new exchange</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal show={show} className="custom_modal" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connect New Exchange</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="drpdwn_box mb-3">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="exchange">Exchange</span>
                {selectedValue}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="Binance">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  Binance
                </Dropdown.Item>

                <Dropdown.Item eventKey="Kucoin">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  Kucoin
                </Dropdown.Item>

                <Dropdown.Item eventKey="BitMart">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  BitMart
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="floating_input  mb-3">
            <FloatingLabel controlId="floatingPassword" label="API Key">
              <Form.Control
                type="text"
                placeholder="Password"
                name="apiKey"
                onChange={handleChange}
                value={apiKey}
              />
            </FloatingLabel>
            {validateError && validateError.secretKey && (
              <p className="text-danger">{validateError.secretKey}</p>
            )}
          </div>
          <div className="floating_input  mb-3">
            <FloatingLabel controlId="floatingPassword" label="Secret Key">
              <Form.Control
                type="text"
                placeholder="Password"
                name="secretKey"
                onChange={handleChange}
                value={secretKey}
              />
            </FloatingLabel>
            {validateError && validateError.secretKey && (
              <p className="text-danger">{validateError.secretKey}</p>
            )}
          </div>
          {selectedValue == "Kucoin" && (
            <div className="floating_input  mb-3">
              <FloatingLabel controlId="floatingPassword" label="PassPhrase">
                <Form.Control
                  type="text"
                  placeholder="Password"
                  name="passphrase"
                  onChange={handleChange}
                  value={passphrase}
                />
              </FloatingLabel>
              {validateError && validateError.passphrase && (
                <p className="text-danger">{validateError.passphrase}</p>
              )}
            </div>
          )}

          {selectedValue == "BitMart" && (
            <div className="floating_input  mb-3">
              <FloatingLabel controlId="floatingPassword" label="Memo">
                <Form.Control
                  type="text"
                  placeholder="Memo"
                  name="memo"
                  onChange={handleChange}
                  value={memo}
                />
              </FloatingLabel>
              {validateError && validateError.passphrase && (
                <p className="text-danger">{validateError.passphrase}</p>
              )}
            </div>
          )}

          <div className="text-center">
            <a href="#" className="atag mb-3 d-block">
              How to connect?
            </a>
          </div>
          <p className="para">
            <strong>Important:</strong> You must also add Arbitrage Bot IP
            addresses from below to the Binance list of trusted IPs. Otherwise,
            if there are some days of inactivity, your API key will be deleted
            by the exchange.
          </p>
          <div className="floating_input floating_input_chg  mb-3">
            <FloatingLabel controlId="floatingPassword" label="IP address">
              <Form.Control
                value={"157.90.247.49 78.47.250.43 157.90.252.155"}
              />
              <span className="input_text">
                <svg
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 27 27"
                  width="27"
                  height="27"
                >
                  <path d="M16.758 6.78H6.708A1.71 1.71 0 0 0 5 8.49v14.419a1.71 1.71 0 0 0 1.709 1.709h10.05a1.71 1.71 0 0 0 1.708-1.71V8.49a1.716 1.716 0 0 0-1.709-1.71Zm.51 16.124c0 .284-.23.514-.514.514H6.704a.514.514 0 0 1-.513-.514V8.49c0-.284.23-.514.513-.514h10.05c.283 0 .513.23.513.514v14.414Z"></path>
                  <path d="M20.601 3h-10.05a1.71 1.71 0 0 0-1.708 1.709c0 .332.265.598.597.598a.595.595 0 0 0 .598-.598c0-.283.23-.514.514-.514h10.05c.282 0 .513.23.513.514v14.419c0 .283-.23.513-.514.513a.595.595 0 0 0-.598.598c0 .332.266.598.598.598a1.71 1.71 0 0 0 1.709-1.71V4.71A1.71 1.71 0 0 0 20.6 3Z"></path>
                </svg>
              </span>
            </FloatingLabel>
          </div>
          <button className="primary_btn blue_btn mb-4" onClick={handleSubmit}>
            Connect
          </button>
          <p className="para text-center">
            Don’t have a Binance account?
            <a href="javascript:void(0)" className="atag">
              Create it now
            </a>{" "}
          </p>
        </Modal.Body>
      </Modal>
    </main>
  );
}
