import React, { useEffect, useState, useContext, useRef } from "react";
import clsx from "classnames";
import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewBotModal from "../components/NewBotModal";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import { dateTimeFormat } from "../lib/dateFilter";
// import action
import { logout, setTradeTheme, getNotifCount } from "../actions/users";
import { getTradeHistoryopen } from "../actions/Bottrade";

// import lib
import { firstCapital } from "../lib/String";
import { toastAlert } from "../lib/toastAlert";
import Logo from "../components/Logo";

// import context
import SocketContext from "../components/Context/SocketContext";
// import NotificationSound from "../notification-sound.mp3";

export default function Header() {
  const { pair, exchange, bot } = useParams();
  const urlpath = window?.location?.href.split("/")[3];

  //const audioPlayer = useRef(null);

  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = { bot: "Smart", exchange: "Binance", pair: "BTC-USDT" };
  // redux-state
  const accountData = useSelector((state) => state.account);
  const tradeThemeData = useSelector((state) => state.tradeThemeReducers);
  const initialFormValue = {
    showBalance: false,
    showId: false,
    showEmail: false,
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const { showId, showEmail } = formValue;
  const [notiCount, setNotiCount] = useState();
  const { showBalance } = formValue;
  const [statcount, setStatCount] = useState();
  useEffect(() => {
    // loadScript();
    fetchbotcount();
    fetchNotiCount();
    socketContext.socket.on("notiCount", (result) => {
      setNotiCount(result.count);
      if (
        result.count > 0 &&
        ((location.pathname != "/notification-trade-related" &&
          result.dataType == "systemError") ||
          (location.pathname != "/notification-system-errors" &&
            result.dataType == "tradeRelated"))
      ) {
        playaudio();
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("online", () => {
      logout(navigate, dispatch);
    });
    window.addEventListener("offline", () => {
      toastAlert("error", "No Internet Connection");
    });
  }, []);

  const playaudio = async () => {
    // audioPlayer.current.play();
  };
  const fetchNotiCount = async () => {
    try {
      const { status, result } = await getNotifCount();
      if (status == "success") {
        setNotiCount(result.count);
      }
    } catch (err) {}
  };

  const fetchbotcount = async () => {
    try {
      const { status, result } = await getTradeHistoryopen();
      if (status == "success") {
        // console.log(result[0], "---result[0]");
        setStatCount(result[0]);
      }
    } catch (err) {}
  };

  useEffect(() => {
    socketContext.socket.on("headerSocket", (result) => {
      setStatCount(result[0]);
    });
  }, []);

  // function loadScript() {
  //   $(".theme_button").off("click");
  //   $(".theme_button").on("click", function () {
  //     $("body").toggleClass("light_theme");
  //     $(".theme_button i").toggleClass("d-none");
  //   });
  // }

  const logoutUser = (e) => {
    e.preventDefault();
    logout(navigate, dispatch);
    toastAlert("success", "Logout Successfully");
  };

  const [activeTab, setActiveTab] = useState(initialFormValue);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top userTopMenu trade_menu">
        <div className="container-fluid">
          <div className="menubar_left">
            <Logo />
            <ul className="leftside_menu">
              <a
                href="/trade/BTC-USDT/Binance/dca"
                className={`nav-item ${bot === "dca" ? "active" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                <div className={"nav-link"}> DCA Bot</div>
              </a>

              <a
                href="/trade/BTC-USDT/Binance/grid"
                className={`nav-item ${bot === "grid" ? "active" : ""}`}
                onClick={() => handleTabClick(2)}
              >
                <div className={"nav-link"}>Grid Bot</div>
              </a>

              <NavLink
                to="/trade-signal/Binance"
                className={`nav-item ${
                  activeTab === 3 || urlpath == "trade-signal" ? "active" : ""
                }`}
                onClick={() => handleTabClick(3)}
              >
                <div className="nav-link">Signal Bot</div>
              </NavLink>

              <NavLink
                to="/portfolio"
                className={`nav-item ${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabClick(4)}
              >
                <div className="nav-link">Portfolio</div>
              </NavLink>
              <NavLink
                to="/api-binding"
                className={`nav-item ${activeTab === 5 ? "active" : ""}`}
                onClick={() => handleTabClick(5)}
              >
                <div className="nav-link">My Exchanges</div>
              </NavLink>
              <li className="dash"></li>
            </ul>
            {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab href="/trade/BTC-USDT/Binance/dca" label={"Bots"} onClick={one} />
          <Tab href="/trade-signal/Binance" label={"Signal Bot"} onClick={two} />
          <Tab href="/portfolio" label={"Portfolio"} />
          <Tab href="/api-binding" label={"My Exchanges"} />
          
        </Tabs> */}
          </div>
          <div className="d-flex">
            <ul className="topProfileDropdown topProfileDropdown_mobile">
              {/* <li className="nav-item">
                <a
                  src={tradeThemeData == "light" ? true : false}
                  name=""
                  onClick={() =>
                    setTradeTheme(
                      dispatch,
                      tradeThemeData == "light" ? "dark" : "light"
                    )
                  }
                  className="theme_button menu_round_btn"
                >
                  {tradeThemeData == "dark" && <i className="bi bi-moon-fill"></i>}
                  {tradeThemeData == "light" && <i className="bi bi-sun-fill"></i>}
                </a>
              </li> */}
              <li className="nav-item">
                <a
                  href="javascript:void(0)"
                  className="notification_link menu_round_btn"
                >
                  <i className="bi bi-bell"></i>
                  {notiCount > 0 && <sup>{notiCount}</sup>}
                </a>
              </li>
              <li>
                <div className="bot_plan_details">
                  <button
                    className="new_bot_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#new_bot_modal"
                  >
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AddCircleIcon"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                        fill="#fff"
                      ></path>
                    </svg>
                  </button>

                  <div
                    className="running_bots_count dropdown-toggle dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <div>
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.6667 28C21.9333 28 21.3333 27.4 21.3333 26.6667L21.3333 5.33333C21.3333 4.6 21.9333 4 22.6667 4C23.4 4 24 4.6 24 5.33333L24 26.6667C24 27.4 23.4 28 22.6667 28ZM8 26.6667L8 21.3333C8 20.6 8.6 20 9.33333 20C10.0667 20 10.6667 20.6 10.6667 21.3333L10.6667 26.6667C10.6667 27.4 10.0667 28 9.33333 28C8.6 28 8 27.4 8 26.6667ZM14.6667 13.3333L14.6667 26.6667C14.6667 27.4 15.2667 28 16 28C16.7333 28 17.3333 27.4 17.3333 26.6667L17.3333 13.3333C17.3333 12.6 16.7333 12 16 12C15.2667 12 14.6667 12.6 14.6667 13.3333Z"
                          fill="#61C065"
                        ></path>
                      </svg>
                      <span>{statcount?.dcaCount}</span>
                    </div>

                    <div>
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.6667 5.33331H5.33333C4.6 5.33331 4 5.93331 4 6.66665V9.33331C4 10.0666 4.6 10.6666 5.33333 10.6666H26.6667C27.4 10.6666 28 10.0666 28 9.33331V6.66665C28 5.93331 27.4 5.33331 26.6667 5.33331ZM5.33333 13.3333H26.6667C27.4 13.3333 28 13.9333 28 14.6666V16C28 16.7333 27.4 17.3333 26.6667 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16V14.6666C4 13.9333 4.6 13.3333 5.33333 13.3333ZM5.33333 20H26.6667C27.4 20 28 20.6 28 21.3333C28 22.0666 27.4 22.6666 26.6667 22.6666H5.33333C4.6 22.6666 4 22.0666 4 21.3333C4 20.6 4.6 20 5.33333 20ZM4.66667 25.3333H27.3333C27.7067 25.3333 28 25.6266 28 26C28 26.3733 27.7067 26.6666 27.3333 26.6666H4.66667C4.29333 26.6666 4 26.3733 4 26C4 25.6266 4.29333 25.3333 4.66667 25.3333Z"
                          fill="#61C065"
                        ></path>
                      </svg>
                      <span>
                        {statcount?.manualCount + statcount?.gridCount}
                      </span>
                    </div>
                  </div>
                  <div class="dropdown-menu profile_dropdown profile_dropdown botstatus_dropdown">
                    <h3>Active bots limit</h3>
                    <div class="bot_preview_box">
                      <div>
                        <span className="running_bots_count">
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            width="32"
                            height="32"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M22.6667 28C21.9333 28 21.3333 27.4 21.3333 26.6667L21.3333 5.33333C21.3333 4.6 21.9333 4 22.6667 4C23.4 4 24 4.6 24 5.33333L24 26.6667C24 27.4 23.4 28 22.6667 28ZM8 26.6667L8 21.3333C8 20.6 8.6 20 9.33333 20C10.0667 20 10.6667 20.6 10.6667 21.3333L10.6667 26.6667C10.6667 27.4 10.0667 28 9.33333 28C8.6 28 8 27.4 8 26.6667ZM14.6667 13.3333L14.6667 26.6667C14.6667 27.4 15.2667 28 16 28C16.7333 28 17.3333 27.4 17.3333 26.6667L17.3333 13.3333C17.3333 12.6 16.7333 12 16 12C15.2667 12 14.6667 12.6 14.6667 13.3333Z"
                              fill="#61C065"
                            ></path>
                          </svg>
                          <span>DCA bots</span>
                        </span>
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.dcaCount}</span>{" "}
                          {/* <span class="greyTxt">/</span>{" "}
                          <span class="greyTxt">10</span> */}
                        </span>
                      </div>

                      {/* <div>
                        <span className="running_bots_count">
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            width="32"
                            height="32"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M26.6667 5.33331H5.33333C4.6 5.33331 4 5.93331 4 6.66665V9.33331C4 10.0666 4.6 10.6666 5.33333 10.6666H26.6667C27.4 10.6666 28 10.0666 28 9.33331V6.66665C28 5.93331 27.4 5.33331 26.6667 5.33331ZM5.33333 13.3333H26.6667C27.4 13.3333 28 13.9333 28 14.6666V16C28 16.7333 27.4 17.3333 26.6667 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16V14.6666C4 13.9333 4.6 13.3333 5.33333 13.3333ZM5.33333 20H26.6667C27.4 20 28 20.6 28 21.3333C28 22.0666 27.4 22.6666 26.6667 22.6666H5.33333C4.6 22.6666 4 22.0666 4 21.3333C4 20.6 4.6 20 5.33333 20ZM4.66667 25.3333H27.3333C27.7067 25.3333 28 25.6266 28 26C28 26.3733 27.7067 26.6666 27.3333 26.6666H4.66667C4.29333 26.6666 4 26.3733 4 26C4 25.6266 4.29333 25.3333 4.66667 25.3333Z"
                              fill="#61C065"
                            ></path>
                          </svg>
                          <span>GRID Bots</span>
                        </span> 
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.gridCount}</span>{" "} 
                        </span>
                      </div> */}

                      <div>
                        <span className="running_bots_count">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M11.2096 4.69602C11.0376 4.69602 10.8676 4.61502 10.7496 4.45802C10.6786 4.36402 9.07764 2.30402 6.05364 2.30402C3.02964 2.30402 1.42464 4.37002 1.35764 4.45802C1.14864 4.73402 0.773638 4.77402 0.520638 4.54502C0.267638 4.31702 0.230638 3.90802 0.437638 3.63002C0.517638 3.52302 2.44264 0.999023 6.05364 0.999023C9.66464 0.999023 11.5896 3.52202 11.6696 3.63002C11.8776 3.90902 11.8396 4.32002 11.5856 4.54802C11.4756 4.64702 11.3416 4.69502 11.2096 4.69502V4.69602Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M9.82162 6.43509C9.65062 6.43509 9.48062 6.35509 9.36362 6.19909C9.31262 6.13309 8.18262 4.69609 6.05362 4.69609C3.92462 4.69609 2.79062 6.13809 2.74362 6.20009C2.53262 6.47409 2.15862 6.51209 1.90762 6.28309C1.65562 6.05409 1.61962 5.64609 1.82762 5.36909C1.88862 5.28809 3.34162 3.39209 6.05462 3.39209C8.76762 3.39209 10.2206 5.28809 10.2816 5.36909C10.4906 5.64709 10.4536 6.05809 10.1996 6.28709C10.0886 6.38709 9.95462 6.43509 9.82262 6.43509H9.82162Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M8.23556 8.17396C8.05756 8.17396 7.88156 8.08696 7.76456 7.92096C7.73456 7.87996 7.14556 7.08696 6.05356 7.08696C4.96156 7.08696 4.36656 7.88696 4.34256 7.92196C4.14056 8.20196 3.76756 8.25496 3.50956 8.03496C3.25156 7.81596 3.20156 7.41196 3.39956 7.12696C3.43756 7.07196 4.35256 5.78296 6.05356 5.78296C7.75456 5.78296 8.66956 7.07196 8.70756 7.12696C8.90656 7.41396 8.85656 7.82296 8.59456 8.04096C8.48656 8.13096 8.36056 8.17396 8.23456 8.17396H8.23556Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M6.05523 11.0001C6.71245 11.0001 7.24523 10.4163 7.24523 9.69609C7.24523 8.97591 6.71245 8.39209 6.05523 8.39209C5.39802 8.39209 4.86523 8.97591 4.86523 9.69609C4.86523 10.4163 5.39802 11.0001 6.05523 11.0001Z"
                              fill="#5FED65"
                            />
                          </svg>
                          <span>Signal bots</span>
                        </span>
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.manualCount}</span>{" "}
                          {/* <span class="greyTxt">/</span>{" "}
                          <span class="greyTxt">10</span> */}
                        </span>
                      </div>
                    </div>
                    {/* <p>
                      If you want to increase the limit, please upgrade your
                      plan
                    </p>
                    <div className="flex_btns">
                      <button class="primary_btn primary_black_btn m-0">
                        Cancel
                      </button>
                      <button class="primary_btn primary_blue_btn m-0">
                        Upgrade
                      </button>
                    </div> */}
                  </div>
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <input
                type="checkbox"
                id="checkbox4"
                className="checkbox4 visuallyHidden"
              />
              <label for="checkbox4">
                <div className="hamburger hamburger4">
                  <span className="bar bar1"></span>
                  <span className="bar bar2"></span>
                  <span className="bar bar3"></span>
                  <span className="bar bar4"></span>
                  <span className="bar bar5"></span>
                </div>
              </label>
            </button>
          </div>
          <div
            className="collapse navbar-collapse profileSideMenu trade_sidemenu"
            id="navbarSupportedContent"
          >
            <div className="profileSideMenuBottom">
              <button
                className="navbar-toggler d-block d-lg-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <input
                  type="checkbox"
                  id="checkbox4"
                  className="checkbox4 visuallyHidden"
                />
                <label for="checkbox4">
                  <div className="hamburger hamburger4">
                    <span className="bar bar1"></span>
                    <span className="bar bar2"></span>
                    <span className="bar bar3"></span>
                    <span className="bar bar4"></span>
                    <span className="bar bar5"></span>
                  </div>
                </label>
              </button>

              <ul className="navbar-nav topProfileDropdown">
                <div className="bot_plan_details d_m_none">
                  <button
                    className="new_bot_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#new_bot_modal"
                  >
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AddCircleIcon"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
                        fill="#fff"
                      ></path>
                    </svg>
                    Start new bot
                  </button>

                  <div
                    className="running_bots_count dropdown-toggle dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <div>
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.6667 28C21.9333 28 21.3333 27.4 21.3333 26.6667L21.3333 5.33333C21.3333 4.6 21.9333 4 22.6667 4C23.4 4 24 4.6 24 5.33333L24 26.6667C24 27.4 23.4 28 22.6667 28ZM8 26.6667L8 21.3333C8 20.6 8.6 20 9.33333 20C10.0667 20 10.6667 20.6 10.6667 21.3333L10.6667 26.6667C10.6667 27.4 10.0667 28 9.33333 28C8.6 28 8 27.4 8 26.6667ZM14.6667 13.3333L14.6667 26.6667C14.6667 27.4 15.2667 28 16 28C16.7333 28 17.3333 27.4 17.3333 26.6667L17.3333 13.3333C17.3333 12.6 16.7333 12 16 12C15.2667 12 14.6667 12.6 14.6667 13.3333Z"
                          fill="#61C065"
                        ></path>
                      </svg>
                      <span>{statcount?.dcaCount}</span>
                    </div>

                    {/* <div>
                      <svg
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.6667 5.33331H5.33333C4.6 5.33331 4 5.93331 4 6.66665V9.33331C4 10.0666 4.6 10.6666 5.33333 10.6666H26.6667C27.4 10.6666 28 10.0666 28 9.33331V6.66665C28 5.93331 27.4 5.33331 26.6667 5.33331ZM5.33333 13.3333H26.6667C27.4 13.3333 28 13.9333 28 14.6666V16C28 16.7333 27.4 17.3333 26.6667 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16V14.6666C4 13.9333 4.6 13.3333 5.33333 13.3333ZM5.33333 20H26.6667C27.4 20 28 20.6 28 21.3333C28 22.0666 27.4 22.6666 26.6667 22.6666H5.33333C4.6 22.6666 4 22.0666 4 21.3333C4 20.6 4.6 20 5.33333 20ZM4.66667 25.3333H27.3333C27.7067 25.3333 28 25.6266 28 26C28 26.3733 27.7067 26.6666 27.3333 26.6666H4.66667C4.29333 26.6666 4 26.3733 4 26C4 25.6266 4.29333 25.3333 4.66667 25.3333Z"
                          fill="#61C065"
                        ></path>
                      </svg>
                      <span>{statcount?.gridCount}</span>
                    </div> */}

                    <div>
                      <svg
                        viewBox="0 0 12 12"
                        width="10"
                        height="10"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                      >
                        <path
                          d="M11.2096 4.69602C11.0376 4.69602 10.8676 4.61502 10.7496 4.45802C10.6786 4.36402 9.07764 2.30402 6.05364 2.30402C3.02964 2.30402 1.42464 4.37002 1.35764 4.45802C1.14864 4.73402 0.773638 4.77402 0.520638 4.54502C0.267638 4.31702 0.230638 3.90802 0.437638 3.63002C0.517638 3.52302 2.44264 0.999023 6.05364 0.999023C9.66464 0.999023 11.5896 3.52202 11.6696 3.63002C11.8776 3.90902 11.8396 4.32002 11.5856 4.54802C11.4756 4.64702 11.3416 4.69502 11.2096 4.69502V4.69602Z"
                          fill="#6DD176"
                        ></path>
                        <path
                          d="M9.82162 6.43509C9.65062 6.43509 9.48062 6.35509 9.36362 6.19909C9.31262 6.13309 8.18262 4.69609 6.05362 4.69609C3.92462 4.69609 2.79062 6.13809 2.74362 6.20009C2.53262 6.47409 2.15862 6.51209 1.90762 6.28309C1.65562 6.05409 1.61962 5.64609 1.82762 5.36909C1.88862 5.28809 3.34162 3.39209 6.05462 3.39209C8.76762 3.39209 10.2206 5.28809 10.2816 5.36909C10.4906 5.64709 10.4536 6.05809 10.1996 6.28709C10.0886 6.38709 9.95462 6.43509 9.82262 6.43509H9.82162Z"
                          fill="#6DD176"
                        ></path>
                        <path
                          d="M8.23556 8.17396C8.05756 8.17396 7.88156 8.08696 7.76456 7.92096C7.73456 7.87996 7.14556 7.08696 6.05356 7.08696C4.96156 7.08696 4.36656 7.88696 4.34256 7.92196C4.14056 8.20196 3.76756 8.25496 3.50956 8.03496C3.25156 7.81596 3.20156 7.41196 3.39956 7.12696C3.43756 7.07196 4.35256 5.78296 6.05356 5.78296C7.75456 5.78296 8.66956 7.07196 8.70756 7.12696C8.90656 7.41396 8.85656 7.82296 8.59456 8.04096C8.48656 8.13096 8.36056 8.17396 8.23456 8.17396H8.23556Z"
                          fill="#6DD176"
                        ></path>
                        <path
                          d="M6.05523 11.0001C6.71245 11.0001 7.24523 10.4163 7.24523 9.69609C7.24523 8.97591 6.71245 8.39209 6.05523 8.39209C5.39802 8.39209 4.86523 8.97591 4.86523 9.69609C4.86523 10.4163 5.39802 11.0001 6.05523 11.0001Z"
                          fill="#5FED65"
                        ></path>
                      </svg>
                      <span className="ms">{statcount?.manualCount}</span>
                    </div>
                  </div>
                  <div class="dropdown-menu profile_dropdown profile_dropdown botstatus_dropdown">
                    <h3>Active bots limit</h3>
                    <div class="bot_preview_box">
                      <div>
                        <span className="running_bots_count">
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            width="32"
                            height="32"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M22.6667 28C21.9333 28 21.3333 27.4 21.3333 26.6667L21.3333 5.33333C21.3333 4.6 21.9333 4 22.6667 4C23.4 4 24 4.6 24 5.33333L24 26.6667C24 27.4 23.4 28 22.6667 28ZM8 26.6667L8 21.3333C8 20.6 8.6 20 9.33333 20C10.0667 20 10.6667 20.6 10.6667 21.3333L10.6667 26.6667C10.6667 27.4 10.0667 28 9.33333 28C8.6 28 8 27.4 8 26.6667ZM14.6667 13.3333L14.6667 26.6667C14.6667 27.4 15.2667 28 16 28C16.7333 28 17.3333 27.4 17.3333 26.6667L17.3333 13.3333C17.3333 12.6 16.7333 12 16 12C15.2667 12 14.6667 12.6 14.6667 13.3333Z"
                              fill="#61C065"
                            ></path>
                          </svg>
                          <span>DCA bots</span>
                        </span>
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.dcaCount}</span>{" "}
                          {/* <span class="greyTxt">/</span>{" "}
                          <span class="greyTxt">10</span> */}
                        </span>
                      </div>
                      {/* <div>
                        <span className="running_bots_count">
                          <svg
                            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jQPKXWQUqDHKmz2U2RF3 css-vubbuv"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 32 32"
                            width="32"
                            height="32"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M26.6667 5.33331H5.33333C4.6 5.33331 4 5.93331 4 6.66665V9.33331C4 10.0666 4.6 10.6666 5.33333 10.6666H26.6667C27.4 10.6666 28 10.0666 28 9.33331V6.66665C28 5.93331 27.4 5.33331 26.6667 5.33331ZM5.33333 13.3333H26.6667C27.4 13.3333 28 13.9333 28 14.6666V16C28 16.7333 27.4 17.3333 26.6667 17.3333H5.33333C4.6 17.3333 4 16.7333 4 16V14.6666C4 13.9333 4.6 13.3333 5.33333 13.3333ZM5.33333 20H26.6667C27.4 20 28 20.6 28 21.3333C28 22.0666 27.4 22.6666 26.6667 22.6666H5.33333C4.6 22.6666 4 22.0666 4 21.3333C4 20.6 4.6 20 5.33333 20ZM4.66667 25.3333H27.3333C27.7067 25.3333 28 25.6266 28 26C28 26.3733 27.7067 26.6666 27.3333 26.6666H4.66667C4.29333 26.6666 4 26.3733 4 26C4 25.6266 4.29333 25.3333 4.66667 25.3333Z"
                              fill="#61C065"
                            ></path>
                          </svg>
                          <span>GRID Bots</span>
                        </span> 
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.gridCount}</span>{" "} 
                        </span>
                      </div> */}
                      <div>
                        <span className="running_bots_count">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M11.2096 4.69602C11.0376 4.69602 10.8676 4.61502 10.7496 4.45802C10.6786 4.36402 9.07764 2.30402 6.05364 2.30402C3.02964 2.30402 1.42464 4.37002 1.35764 4.45802C1.14864 4.73402 0.773638 4.77402 0.520638 4.54502C0.267638 4.31702 0.230638 3.90802 0.437638 3.63002C0.517638 3.52302 2.44264 0.999023 6.05364 0.999023C9.66464 0.999023 11.5896 3.52202 11.6696 3.63002C11.8776 3.90902 11.8396 4.32002 11.5856 4.54802C11.4756 4.64702 11.3416 4.69502 11.2096 4.69502V4.69602Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M9.82162 6.43509C9.65062 6.43509 9.48062 6.35509 9.36362 6.19909C9.31262 6.13309 8.18262 4.69609 6.05362 4.69609C3.92462 4.69609 2.79062 6.13809 2.74362 6.20009C2.53262 6.47409 2.15862 6.51209 1.90762 6.28309C1.65562 6.05409 1.61962 5.64609 1.82762 5.36909C1.88862 5.28809 3.34162 3.39209 6.05462 3.39209C8.76762 3.39209 10.2206 5.28809 10.2816 5.36909C10.4906 5.64709 10.4536 6.05809 10.1996 6.28709C10.0886 6.38709 9.95462 6.43509 9.82262 6.43509H9.82162Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M8.23556 8.17396C8.05756 8.17396 7.88156 8.08696 7.76456 7.92096C7.73456 7.87996 7.14556 7.08696 6.05356 7.08696C4.96156 7.08696 4.36656 7.88696 4.34256 7.92196C4.14056 8.20196 3.76756 8.25496 3.50956 8.03496C3.25156 7.81596 3.20156 7.41196 3.39956 7.12696C3.43756 7.07196 4.35256 5.78296 6.05356 5.78296C7.75456 5.78296 8.66956 7.07196 8.70756 7.12696C8.90656 7.41396 8.85656 7.82296 8.59456 8.04096C8.48656 8.13096 8.36056 8.17396 8.23456 8.17396H8.23556Z"
                              fill="#6DD176"
                            />
                            <path
                              d="M6.05523 11.0001C6.71245 11.0001 7.24523 10.4163 7.24523 9.69609C7.24523 8.97591 6.71245 8.39209 6.05523 8.39209C5.39802 8.39209 4.86523 8.97591 4.86523 9.69609C4.86523 10.4163 5.39802 11.0001 6.05523 11.0001Z"
                              fill="#5FED65"
                            />
                          </svg>
                          <span>Signal bots</span>
                        </span>
                        <span class="border_line"></span>
                        <span>
                          <span class="blueText">{statcount?.manualCount}</span>{" "}
                          {/* <span class="greyTxt">/</span>{" "}
                          <span class="greyTxt">10</span> */}
                        </span>
                      </div>
                    </div>

                    {/* <p>
                      If you want to increase the limit, please upgrade your
                      plan
                    </p>
                    <div className="flex_btns">
                      <button class="primary_btn primary_black_btn m-0">
                        Cancel
                      </button>
                      <button class="primary_btn primary_blue_btn m-0">
                        Upgrade
                      </button>
                    </div> */}
                  </div>
                </div>

                {/* <li className="nav-item">
                  <button
                    className="new_bot_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#new_bot_modal"
                  >
                    Mini Bots
                  </button>
                </li> */}
                {/* <li className="nav-item d_m_none">
                  <a
                    src={tradeThemeData == "light" ? true : false}
                    name=""
                    onClick={() =>
                      setTradeTheme(
                        dispatch,
                        tradeThemeData == "light" ? "dark" : "light"
                      )
                    }
                    className="theme_button menu_round_btn"
                  >
                    {tradeThemeData == "dark" && (
                      <i className="bi bi-moon-fill"></i>
                    )}
                    {tradeThemeData == "light" && (
                      <i className="bi bi-sun-fill"></i>
                    )}
                  </a>
                </li> */}
                <li className="nav-item d_m_none">
                  <a
                    href="/portfolio?tb=notification"
                    className="notification_link menu_round_btn"
                  >
                    <i className="bi bi-bell"></i>
                    {notiCount > 0 && <sup>{notiCount}</sup>}
                  </a>
                </li>
                <li className="nav-item dropdown menu_round_btn d_m_none profile_email">
                  <a
                    className="nav-link"
                    href="javascript:void(0);"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-person-fill"></i>
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-end profile_dropdown"
                    aria-labelledby="navbarDropdown"
                  >
                    {/* <li className="demo_list">
                      <label className="dropdown-item">
                        <span><img src={require("../assets/images/dropdown_icon_01.png")} alt="Icon" className="img-fluid"/>Demo</span>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                        </div>
                      </label>
                    </li> */}
                    {/* <li>
                      <Link to="/dashboard" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_07.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Dashboard
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/wallet" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_03.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Wallet
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/profile" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_02.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        My Profile
                      </Link>
                    </li>
                    {/* <li><Link to="#" className="dropdown-item"><img src={require("../assets/images/dropdown_icon_04.png")} alt="Icon" className="img-fluid" />Referral</Link></li>
                    <li><Link to="#" className="dropdown-item"><img src={require("../assets/images/dropdown_icon_05.png")} alt="Icon" className="img-fluid" />Settings</Link></li> */}
                    <li>
                      <Link to="/security" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_09.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Security
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/notification-trade-related"
                        className="dropdown-item"
                      >
                        <img
                          src={require("../assets/images/dropdown_icon_08.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Notification
                      </Link>
                    </li>
                    <li>
                      <Link to="/kyc" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_11.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        KYC Verification
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/api-binding" className="dropdown-item">
                        <img
                          src={require("../assets/images/dropdown_icon_10.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        API Binding
                      </Link>
                    </li> */}
                    {/* <li>
                      <a
                        className="nav-link"
                        target="_blank"
                        href="/terms"
                      >
                        <img
                          src={require("../assets/images/menuIcon-14.png")}
                          alt="Icon"
                        />{" "}
                        User’s Guide
                      </a>
                    </li>
                    <li>
                      <Link to="/video-tutorials" className="dropdown-item">
                        <img
                          src={require("../assets/images/menuIcon-15.png")}
                          alt="Icon"
                        />{" "}
                        Video Tutorials
                      </Link>
                    </li>
                    <li>
                      <Link to="/referral" className="dropdown-item">
                        <img
                          src={require("../assets/images/menuIcon-16.png")}
                          alt="Icon"
                        />{" "}
                        Invite a Friend
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/strategy-setting"
                        state={{ type: data }}
                        className="nav-link"
                      >
                        <img
                          src={require("../assets/images/menuIcon-10.png")}
                          alt="Icon"
                        />{" "}
                        Strategy Settings
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="#"
                        className="dropdown-item"
                        onClick={logoutUser}
                      >
                        <img
                          src={require("../assets/images/dropdown_icon_06.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item demo_list d_d_none">
                  <label className="nav-link">
                    <span><img src={require("../assets/images/dropdown_icon_01.png")} alt="Icon" className="img-fluid" />Demo</span>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                    </div>
                  </label>
                </li> */}
                <li className="nav-item d_d_none">
                  <NavLink
                    to="/trade/BTC-USDT/Binance/dcatrade_chart"
                    className="nav-link"
                  >
                    <img
                      src={require("../assets/images/dropdown_icon_12.png")}
                      alt="Icon"
                    />
                    Bots
                  </NavLink>
                </li>

                <li className="nav-item d_d_none">
                  <NavLink to="/trade-signal/Binance" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_12.png")}
                      alt="Icon"
                    />
                    Signal Bot
                  </NavLink>
                </li>

                <li className="nav-item d_d_none">
                  <NavLink to="/portfolio" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_13.png")}
                      alt="Icon"
                    />
                    Portfolio
                  </NavLink>
                </li>
                <li className="nav-item d_d_none">
                  <NavLink to="/api-binding" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_14.png")}
                      alt="Icon"
                    />
                    My Exchanges
                  </NavLink>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/dashboard" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_07.png")}
                      alt="Icon"
                    />
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/wallet" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_03.png")}
                      alt="Icon"
                    />
                    Wallet
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/profile" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_02.png")}
                      alt="Icon"
                    />
                    My Profile
                  </Link>
                </li>
                {/* <li className="nav-item d_d_none"><Link to="#" className="nav-link"><img src={require("../assets/images/dropdown_icon_04.png")} alt="Icon" />Referral</Link></li>
                <li className="nav-item d_d_none"><Link to="#" className="nav-link"><img src={require("../assets/images/dropdown_icon_05.png")} alt="Icon" />Settings</Link></li> */}
                <li className="nav-item d_d_none">
                  <Link to="/security" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_09.png")}
                      alt="Icon"
                    />
                    Security
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/portfolio" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_08.png")}
                      alt="Icon"
                    />
                    Notification
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/kyc" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_11.png")}
                      alt="Icon"
                    />
                    KYC Verification
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/api-binding" className="nav-link">
                    <img
                      src={require("../assets/images/dropdown_icon_10.png")}
                      alt="Icon"
                    />
                    API Binding
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <a className="nav-link" href="/terms" target="_blank">
                    <img
                      src={require("../assets/images/menuIcon-14.png")}
                      alt="Icon"
                    />{" "}
                    User’s Guide
                  </a>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/video-tutorials" className="nav-link">
                    <img
                      src={require("../assets/images/menuIcon-15.png")}
                      alt="Icon"
                    />{" "}
                    Video Tutorials
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="/referral" className="nav-link">
                    <img
                      src={require("../assets/images/menuIcon-16.png")}
                      alt="Icon"
                    />{" "}
                    Invite a Friend
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link
                    to="/strategy-setting"
                    state={{ type: data }}
                    className="nav-link"
                  >
                    <img
                      src={require("../assets/images/menuIcon-10.png")}
                      alt="Icon"
                    />{" "}
                    Strategy Settings
                  </Link>
                </li>
                <li className="nav-item d_d_none">
                  <Link to="#" className="nav-link" onClick={logoutUser}>
                    <img
                      src={require("../assets/images/dropdown_icon_06.png")}
                      alt="Icon"
                    />
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <audio ref={audioPlayer} src={NotificationSound} /> */}
      </nav>

      <NewBotModal />
    </>
  );
}
