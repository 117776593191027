import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "classnames";
// import lib
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { decryptString } from "../../lib/cryptoJS";

import config from "../../config";
import validation from "./validation";
import { CopyToClipboard } from "react-copy-to-clipboard";

// import action
import {
  BitmartBind,
  sentEmailOtp,
  getBindData,
  editBitBind,
  deleteBind,
} from "../../actions/users";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  apiKey: "",
  secretKey: "",
  code: "",
  memo: "",
  riskStatus: false,
  type: "Binance",
  showapikey: false,
  showsecretKey: false,
  showmemo: false,
};

export default function GroupBinding(props) {
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState();
  const [keyData, setKeyData] = useState();
  const [dltLoad, setDltLoad] = useState();
  const [loader, setLoader] = useState(false);
  const [resent, setResent] = useState(false);
  const [Timer, setTimer] = useState(300);
  const { result } = props;
  const {
    apiKey,
    secretKey,
    memo,
    showapikey,
    showsecretKey,
    showmemo,
    code,
    riskStatus,
    type,
  } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(validateError)) {
      setValidateError({});
    }
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    let errors = {};
    setDltLoad(true);
    if (isEmpty(code)) {
      errors.code = "Kindly Send and Verify Your Code";
      setValidateError(errors);
      setDltLoad(false);
      return;
    }
    if (!riskStatus) {
      errors.riskStatus = "Please Select Risk Notice";
      setValidateError(errors);
      setDltLoad(false);
      return;
    }
    let reqData = {
      _id: keyData._id,
      code: code,
      exchange: result,
    };
    try {
      const { status, message, error } = await deleteBind(reqData);
      setDltLoad(false);
      if (status == "success") {
        setResent(false);
        toastAlert("success", message, "kycsection");
        fetchData();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      _id: keyData._id,
      type: result,
      apiKey: apiKey,
      secretKey: secretKey,
      code: code,
      riskStatus: riskStatus,
      memo: memo,
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      const { status, loading, message, error } = await editBitBind(reqData);
      setLoader(false);
      if (status == "success") {
        setResent(false);
        toastAlert("success", message, "kycsection");
        fetchData();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let reqData = {
      type: result,
      apiKey: apiKey,
      secretKey: secretKey,
      code: code,
      riskStatus: riskStatus,
      memo: memo,
    };
    let validationError = validation(reqData);
    if (!isEmpty(validationError)) {
      setValidateError(validationError);
      setLoader(false);
      return;
    }

    try {
      const { status, loading, message, error } = await BitmartBind(reqData);
      setLoader(loading);
      if (status == "success") {
        setResent(false);
        toastAlert("success", message, "kycsection");
        fetchData();
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "kycsection");
      }
    } catch (err) {}
  };

  const handleSentOTP = async (e) => {
    e.preventDefault();
    let reqData = {
      type: "keyVerification",
    };
    try {
      let { status, loading, error, message } = await sentEmailOtp(reqData);
      if (status == "success") {
        setResent(true);
        callinterval();
        toastAlert("success", message, "mobileForm");
      } else {
        if (error) {
          setValidateError(error);

          return;
        }
        toastAlert("error", message, "mobileForm");
      }
    } catch (err) {}
  };

  async function callinterval() {
    var ts = 300;
    setTimer(ts);
    let setInt = setInterval(() => {
      ts = ts - 1;
      if (ts == 0) {
        setTimer(ts);
        clearInterval(setInt);
        setResent(false);
      } else {
        setTimer(ts);
      }
    }, 1000);
  }

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, [name]: checked };
    setFormValue(formData);
    // setValidateError(validation(formData))
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { status, loading, result, message, error } = await getBindData();
    if (status == "success") {
      let item = result.find((el) => el.exchange == "BitMart");
      if (!isEmpty(item)) {
        let formData = {
          ...formValue,
          ...{
            apiKey: decryptString(item.ApiKey),
            secretKey: decryptString(item.SecretKey),
            memo: item.memo,
            code: "",
          },
        };
        setFormValue(formData);
        setKeyData(item);
      } else {
        setFormValue(initialFormValue);
        setKeyData();
      }
    }
  };

  return (
    <>
      {!isEmpty(keyData) && (
        <div
          className="alert info_alert alert-dismissible fade show"
          role="alert"
        >
          <img src={require("../../assets/images/info_icon.png")} alt="Icon" />
          <p>Your BitMart API Key Binded</p>
        </div>
      )}
      <div className="api_binding_card mt-4">
        <div className="api_binding_card_title">
          <h3>
            IP Group Binding <span>(Optional)</span>
          </h3>
        </div>
        <div className="api_binding_card_content">
          <p>
            For security, DCA BOT recommends binding the server IP address when
            creating the API. For users who need to bind the IP address, simply
            copy the IP addresses from below and paste it in the input box of
            Bitmart Exchange. Please note that IP address binding is optional.
          </p>
          <div class="form-group">
            <div class="input-group">
              <textarea
                type="text"
                class="form-control"
                value={config.ipBindBm}
              />
              <button class="input-group-text">
                <CopyToClipboard
                  text={config.ipBindBm}
                  onCopy={() => {
                    toastAlert("success", "Copied!", "twoFa");
                  }}
                >
                  <a href="#" className="btn btnType1 py-0 my-0 px-2">
                    <img
                      src={require("../../assets/images/copy_icon_white.png")}
                      alt="QR"
                      className="img-fluid"
                    />
                  </a>
                </CopyToClipboard>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="api_binding_card mt-4">
        <div class="form-group">
          <label>API Key</label>
          <div className="input-group regGroupInput mt-2">
            <input
              type={showapikey ? "text" : "password"}
              class="form-control"
              placeholder="Please enter API Key"
              name="apiKey"
              onChange={handleChange}
              value={apiKey}
              // disabled={!isEmpty(keyData) ? true : false}
            />{" "}
            <div className="input-group-text inp_grp_width">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue((el) => {
                    return {
                      ...el,
                      ...{ showapikey: !el.showapikey },
                    };
                  });
                }}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showapikey },
                    { "bi-eye-slash": !showapikey }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
          {validateError && validateError.apiKey && (
            <p className="text-danger">{validateError.apiKey}</p>
          )}
        </div>
        <div class="form-group">
          <label>Secret Key</label>
          <div className="input-group regGroupInput mt-2">
            <input
              type={showsecretKey ? "text" : "password"}
              class="form-control"
              placeholder="Please enter Secret Key"
              name="secretKey"
              onChange={handleChange}
              value={secretKey}
              // disabled={!isEmpty(keyData) ? true : false}
            />{" "}
            <div className="input-group-text inp_grp_width">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue((el) => {
                    return {
                      ...el,
                      ...{ showsecretKey: !el.showsecretKey },
                    };
                  });
                }}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showsecretKey },
                    { "bi-eye-slash": !showsecretKey }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
          {validateError && validateError.secretKey && (
            <p className="text-danger">{validateError.secretKey}</p>
          )}
        </div>
        <div class="form-group">
          <label>Memo</label>
          <div className="input-group regGroupInput mt-2">
            <input
              type={showmemo ? "text" : "password"}
              class="form-control"
              placeholder="Please enter Memo"
              name="memo"
              onChange={handleChange}
              value={memo}
              // disabled={!isEmpty(keyData) ? true : false}
            />

            <div className="input-group-text inp_grp_width">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue((el) => {
                    return {
                      ...el,
                      ...{ showmemo: !el.showmemo },
                    };
                  });
                }}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showmemo },
                    { "bi-eye-slash": !showmemo }
                  )}
                  aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
          {validateError && validateError.memo && (
            <p className="text-danger">{validateError.memo}</p>
          )}
        </div>
        {/* {isEmpty(keyData) && (
          <> */}
        <div class="form-group">
          <label>Email Verification code</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Please enter Verification Code"
              name="code"
              onChange={handleChange}
              value={code}
            />
            <div className="input-group-text">
              {!resent && (
                <button class="input-group-text" onClick={handleSentOTP}>
                  Send Code
                </button>
              )}
              {resent && <>{Timer} seconds </>}
            </div>
            {/* <button class="input-group-text" onClick={handleSentOTP}>
              Send Code
            </button> */}
          </div>
          {validateError && validateError.code && (
            <p className="text-danger">{validateError.code}</p>
          )}
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="riskStatus"
              name="riskStatus"
              onChange={handleCheckBox}
              checked={riskStatus}
            />
            <label class="form-check-label" for="defaultCheck1">
              I understand the risks associated with using this API binding{" "}
              {/* <a target="_blank" href="/terms">
                terms and conditions carefully
              </a> */}
            </label>
            {validateError && validateError.riskStatus && (
              <p className="text-danger">{validateError.riskStatus}</p>
            )}
          </div>
        </div>

        {isEmpty(keyData) && (
          <div className="form-group text-left">
            <button className="primary_btn w-auto px-5" onClick={handleSubmit}>
              {loader && <div class="loader"></div>}
              {!loader && "Bind"}
            </button>
          </div>
        )}
        {!isEmpty(keyData) && (
          <div className="form-group text-left">
            <button
              className="primary_btn w-auto px-5"
              onClick={handleEdit}
              disabled={loader}
            >
              {loader && <div class="loader"></div>}
              {!loader && "Save"}
            </button>{" "}
            <button
              className="primary_btn btn-danger w-auto px-5"
              onClick={handleDelete}
              disabled={loader}
            >
              {dltLoad && <div class="loader"></div>}

              {!dltLoad && "Delete"}
            </button>
          </div>
        )}
        {/* </>
        )} */}
      </div>
    </>
  );
}
