import { createContext } from "react";

const SocketContext = createContext({
  socket: "",
  socketTickerBinance: "",
  socketTickerBitMart: "",
  socketTickerBybit: "",
});

export default SocketContext;
