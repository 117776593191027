// import constant
import { SET_GRID_SB_OPEN } from "../constant";

const initialValue = {
  type: "close",
};
const openSBGrid = (state = initialValue, action) => {
  // console.log(action, "-----------7", state);
  switch (action.type) {
    case SET_GRID_SB_OPEN:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default openSBGrid;
