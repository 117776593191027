import React, { useEffect, useState, useContext } from "react";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import SocketContext from "../Context/SocketContext";
export default function Balance(props) {
  // state
  const { params, pairsAll, balancefetchAll, TotBal, TotLocked } = props;
  const socketContext = useContext(SocketContext);
  const [BalanceAll, setBalanceAll] = useState([]);
  const [TotBalance, setTotBal] = useState(0);
  const [TotLockedBalance, setTotLocked] = useState(0);
  let total = 0,
    totalLocked = 0;

  useEffect(() => {
    setBalanceAll(balancefetchAll);
    setTotBal(TotBal);
    setTotLocked(TotLocked);
  }, [balancefetchAll, pairsAll]);

  useEffect(() => {
    let exchange = params.exchange;
    let exchangesArr = ["Binance", "BitMart", "Bybit"];
    for (let ex of exchangesArr) {
      if (exchange != ex) {
        let socketTickerL = "socketTicker" + ex;
        socketContext[socketTickerL].off("marketPrice");
      }
    }
    let socketTicker = "socketTicker" + exchange;

    socketContext[socketTicker].on("marketPrice", (result) => {
      setBalanceAll((el) => {
        let dataArr = [];
        let total = 0,
          totallocked = 0;
        el &&
          el.map((item) => {
            if (item.pairId == result.pairId) {
              dataArr.push({
                ...item,
                markPrice: result.data.markPrice,
                usdprice: result.data.markPrice * item.free,
              });
              total +=
                parseFloat(result.data.markPrice) * parseFloat(item.free);
              totallocked +=
                parseFloat(result.data.markPrice) * parseFloat(item.locked);
            } else {
              total += parseFloat(item.markPrice) * parseFloat(item.free);
              totallocked +=
                parseFloat(item.markPrice) * parseFloat(item.locked);
              dataArr.push(item);
            }
          });
        setTotBal(total);
        setTotLocked(totallocked);
        return dataArr;
      });
    });
  }, [params.exchange]);

  return (
    <div
      class="tab-pane fade"
      id="balance-tab-pane"
      role="tabpanel"
      aria-labelledby="balance-tab"
      tabindex="0"
    >
      <div className="primary_table primary_table_normal zindex">
        <table className="table">
          <thead>
            <tr>
              <th>Currency</th>
              <th>In trades</th>
              <th>Available</th>
            </tr>
          </thead>
          <tbody>
            {BalanceAll &&
              BalanceAll.length > 0 &&
              BalanceAll.map((item, key) => {
                let excN = params.exchange.toLowerCase();
                return (
                  <tr>
                    <td>
                      <div className="bal_pair">
                        <img
                          src={require("../../assets/images/" + excN + ".png")}
                          alt="Icon"
                          className="img-fluid"
                          width="15px"
                        />
                        <span>{item.asset}</span>
                      </div>
                    </td>
                    <td>
                      <p className="mb-1">
                        {item?.locked > 0 && toFixedDown(item?.locked, 8)}
                      </p>
                      <p className="mb-0 usd_eqiv">
                        {item?.locked > 0
                          ? toFixedDown(item?.lockedusdprice, 2) + " USD"
                          : " - "}
                      </p>
                    </td>
                    <td>
                      <p className="mb-1">{toFixedDown(item?.free, 4)}</p>
                      <p className="mb-0 usd_eqiv">
                        {item?.free > 0
                          ? toFixedDown(item?.usdprice, 2) + " USD"
                          : " - "}
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot className="border_top">
            <tr>
              <td>Total</td>
              <td>
                {toFixedDown(TotLockedBalance, 2)}{" "}
                <span className="greyTxt">USD</span>
              </td>
              <td>
                {toFixedDown(TotBalance, 4)}{" "}
                <span className="greyTxt">USD</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
