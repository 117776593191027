import { getFeeAndDecimal } from "../../actions/commonAction";
// import isEmpty from "../../lib/isEmpty";
import { toFixedDown } from "../../lib/roundOf";

export const calPrice = (priceData, type, takerCommission) => {
  try {
    let total = 0,
      totalQty = 0,
      totalPrice = 0;
    if (type == "price") {
      for (let data of priceData) {
        total = total + data.price;
      }
      if (total > 0) {
        return total;
      }
    }
    if (type == "checksell") {
      for (let data of priceData) {
        total = total + data.quantity * data.price;
        totalQty = totalQty + data.quantity;
      }
      if (total > 0) {
        return total / totalQty;
      }
    }

    if (type == "quantity") {
      for (let data of priceData) {
        total = total + data.quantity;
      }
      if (total > 0) {
        return total;
      }
    }
    if (type == "Totalprice") {
      for (let data of priceData) {
        totalPrice = totalPrice + data.orgquantity * data.price;
      }
      return totalPrice;
    }

    if (type == "TotalpriceBitMart") {
      for (let data of priceData) {
        console.log(
          takerCommission,
          data.orgquantity,
          data.price,
          "--takerCommission"
        );
        totalPrice =
          totalPrice + data.orgquantity * data.price + takerCommission;
      }
      return totalPrice;
    }

    if (type == "orgquantity") {
      for (let data of priceData) {
        total = total + data.orgquantity;
      }
      if (total > 0) {
        return parseFloat(total).toFixed(6);
      }
    }
    if (type == "avgorgqty") {
      for (let data of priceData) {
        total = total + data.orgquantity * data.price;
        totalQty = totalQty + data.orgquantity;
      }
      if (total > 0) {
        return total / totalQty;
      }
    }

    if (type == "checksellavg") {
      for (let data of priceData) {
        total = total + data.orgquantity * data.price;
        totalQty = totalQty + data.quantity;
      }
      if (total > 0) {
        return total / totalQty;
      }
    }

    return 0;
  } catch (err) {
    console.log(err, "----------1197");
    return 0;
  }
};

export const takeStopFee = async (positionItem, type, FeeandDecimal) => {
  try {
    let takerCommission = FeeandDecimal.takerCommission;
    let priceDecimal = FeeandDecimal.priceDecimal;
    let quantityDecimal = FeeandDecimal.quantityDecimal;
    let investmentPrice;
    if (positionItem.exchange == "BitMart") {
      investmentPrice = calPrice(
        positionItem.filled,
        "TotalpriceBitMart",
        takerCommission
      );
    } else {
      investmentPrice = calPrice(positionItem.filled, "Totalprice");
    }
    console.log(investmentPrice, "---investmentPrice");
    //investmentPrice = toFixedDown(investmentPrice, priceDecimal);
    let finalQty = calPrice(positionItem.filled, "quantity");

    // Profit Calculation
    let finalOrderValue = 0;
    if (type == "takeProfit") {
      finalOrderValue =
        investmentPrice + (investmentPrice * positionItem.takeProfit) / 100;

      if (positionItem.exchange == "BitMart") {
        finalOrderValue = finalOrderValue + finalOrderValue * takerCommission;
      }
    } else if (type == "stopLoss") {
      let avgpriceOrg = calPrice(positionItem.filled, "avgorgqty");
      console.log(avgpriceOrg, "avgpriceOrg125");
      finalOrderValue =
        avgpriceOrg - (avgpriceOrg * positionItem.stopLoss) / 100;
      finalOrderValue = toFixedDown(finalOrderValue, priceDecimal);

      return {
        status: true,
        price: finalOrderValue,
        priceDecimal: priceDecimal,
        takerCommission: takerCommission,
        quantityDecimal: quantityDecimal,
      };
    }
    // finalOrderValue = toFixedDown(finalOrderValue, priceDecimal);
    console.log(finalOrderValue, "---finalOrderValue");
    let price = finalOrderValue / (finalQty - finalQty * takerCommission);

    price = toFixedDown(price, priceDecimal);
    return {
      status: true,
      price: price,
      priceDecimal: priceDecimal,
      takerCommission: takerCommission,
      quantityDecimal: quantityDecimal,
    };
  } catch (err) {
    console.log(err, "--------507");
    return { status: false, price: 0 };
  }
};

// export const FeeandDecimal = async (exchange, pairName) => {
//     try {
//         let takerCommission, priceDecimal, quantityDecimal, TfeeEx;
//         if (exchange == "Binance") {
//             // let TRADEFEE_Binance = await hget("Tradefee", "Binance");
//             // TfeeEx = JSON.parse(TRADEFEE_Binance);
//             TfeeEx = { priceDecimal: 2, quantityDecimal: 5, maxMarketQty: 120.72594254, minOrderSize: 5 }
//         }

//         if (exchange == "BitMart") {
//             // let TRADEFEE_BitMart = await hget("Tradefee", "BitMart");
//             // TfeeEx = JSON.parse(TRADEFEE_BitMart);
//         }

//         if (exchange == "Kucoin") {
//             // let TRADEFEE_Kucoin = await hget("Tradefee", "Kucoin");
//             // TfeeEx = JSON.parse(TRADEFEE_Kucoin);
//         }

//         let tradefeeDetails = TfeeEx.find((o) => o.symbol === pairName);
//         if (isEmpty(tradefeeDetails)) {
//             return;
//         }
//         if (!isEmpty(tradefeeDetails)) {
//             takerCommission = parseFloat(tradefeeDetails.takerCommission);
//         }

//         //   let Exchangeinfo = await hget("ExchangeInfo" + exchange, pairName);
//         let Exchangeinfo = { priceDecimal: 2, quantityDecimal: 5, maxMarketQty: 120.72594254, minOrderSize: 5 }
//         //   Exchangeinfo = JSON.parse(Exchangeinfo);
//         if (!isEmpty(Exchangeinfo)) {
//             priceDecimal = Exchangeinfo.priceDecimal;
//             quantityDecimal = Exchangeinfo.quantityDecimal;
//         }
//         // console.log(takerCommission, priceDecimal, quantityDecimal, "----6260");

//         return {
//             takerCommission: parseFloat(takerCommission),
//             priceDecimal: parseFloat(priceDecimal),
//             quantityDecimal: parseFloat(quantityDecimal),
//         };
//     } catch (err) {
//         console.log(err, "----6249");
//     }
// };
