import React, { useEffect, useState, useContext } from "react";

import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.png";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";
import { firstCapital } from "../../lib/String";
import { getOpenOrdersAll } from "../../actions/Bottrade";
import SocketContext from "../Context/SocketContext";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Positions() {
  const [data, setData] = useState();
  const [oData, setOData] = useState();
  const [exchange, setexchange] = useState("Binance");

  const socketContext = useContext(SocketContext);

  const columns = [
    {
      name: "Exchange",
      selector: (row) => row.exchange,
      width: "130px",
    },

    {
      name: "Assets",
      selector: (row) => `${row.firstCurrency} / ${row.secondCurrency}`,
      width: "130px",
    },

    {
      name: "Unrealized PNL",
      selector: (row) => (
        <div>
          <span className={row && row.pl < 0 ? "redText" : "greenTxt"}>
            {toFixedDown(row.pl, 2)} %
          </span>
        </div>
      ),
    },

    // {
    //   name: "No.of Avg Calls",
    //   selector: (row) => {
    //     if (row.BotType == "ZAP") {
    //       return <>-</>;
    //     } else if (!isEmpty(row.noOfCount)) {
    //       return row.noOfCount;
    //     } else {
    //       return 0;
    //     }
    //   },
    // },
    // {
    //   name: "Avg Call",
    //   selector: (row) => {
    //     if (row.BotType == "ZAP") {
    //       return <>-</>;
    //     } else {
    //       return row.tradeSetting.AveragingCall == "Start" ? "ON" : "OFF";
    //     }
    //   },
    // },
    {
      name: "Investment",
      selector: (row) => toFixed(row.investment, 4),
    },
    {
      name: "CMP",
      selector: (row) => row.cmp,
    },
    // {
    //   name: "Entry Price",
    //   selector: (row) =>
    //     row.entryPrice[0] && toFixed(row.entryPrice[0].price, row.priceDecimal),
    // },

    {
      name: "Current Value (USDT)",
      selector: (row) => toFixed(row.currentvalue, 4),
    },

    {
      name: "Txns/Count",
      selector: (row) => row?.filled?.length,
    },

    {
      name: "Current Status",
      selector: (row) => (
        <span className="greenText">{firstCapital("open")}</span>
      ),
    },

    {
      name: "Created Date",
      width: "180px",
      selector: (row) => dateTimeFormat(row.createdAt),
    },
  ];

  const calPrice = (priceData, type) => {
    try {
      let total = 0,
        totalQty = 0,
        totalPrice = 0;
      if (type == "price") {
        for (let data of priceData) {
          total = total + data.price;
        }
        if (total > 0) {
          return total;
        }
      }
      if (type == "quantity") {
        for (let data of priceData) {
          total = total + data.quantity;
        }
        if (total > 0) {
          return parseFloat(total).toFixed(6);
        }
      }
      if (type == "checksell") {
        for (let data of priceData) {
          total = total + data.quantity * data.price;
          totalQty = totalQty + data.quantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      if (type == "Totalprice") {
        for (let data of priceData) {
          totalPrice = totalPrice + data.orgquantity * data.price;
        }
        return totalPrice;
      }

      if (type == "TotalpriceBM") {
        for (let data of priceData) {
          totalPrice =
            totalPrice + data.orgquantity * data.price + data.comissionFee;
        }
        return totalPrice;
      }

      if (type == "orgquantity") {
        for (let data of priceData) {
          total = total + data.orgquantity;
        }
        if (total > 0) {
          return parseFloat(total).toFixed(6);
        }
      }

      if (type == "avgorgqty") {
        for (let data of priceData) {
          total = total + data.orgquantity * data.price;
          totalQty = totalQty + data.orgquantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      return 0;
    } catch (err) {
      console.log(err, "----3333");

      return 0;
    }
  };
  const [selectedValue, setSelectedValue] = useState("Binance"); // Initialize with a default value

  const [pair, setPair] = useState(); // Initialize with a default value

  const handleSelect = (eventKey, event) => {
    if (eventKey != "All") {
      setSelectedValue(eventKey);

      fetchDetail(pair, eventKey);
    }
  };

  const fetchDetail = async (pair = "", exchange = "") => {
    if (exchange == "All") {
      exchange = "";
    }

    try {
      let reqData = {
        botType: "Genie",
        exchange: exchange,
        pair: !isEmpty(pair) ? pair.toUpperCase() : pair,
      };

      const { status, result, pairDetails } = await getOpenOrdersAll(reqData);
      if (status == "success") {
        let pairList = [];

        for (let [index, item] of result.entries()) {
          let avgPrice = item.avgPrice / item.quantity;
          let priceDecimal = item.priceDecimal;
          let finalQty = calPrice(item.filled, "quantity");
          let totalOrgQty = calPrice(item.filled, "orgquantity");
          let combuybnbUsdt = 0;
          finalQty = toFixedDown(finalQty, item?.quantityDecimal);

          // console.log(finalQty, "------finalQty");
          let investmentPrice = calPrice(item.filled, "Totalprice");
          if (item.exchange == "BitMart") {
            investmentPrice = calPrice(item.filled, "TotalpriceBM");
          }

          for (let itemfill of item?.filled) {
            if (
              !isEmpty(itemfill?.feeFilled) &&
              itemfill?.feeFilled.length > 0
            ) {
              for (let buyfeeItem of itemfill?.feeFilled) {
                if (
                  buyfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                }
              }
            }
          }

          let pairData = pairDetails.find(
            (el) => el._id.toString() == item.pairId.toString()
          );
          let exovaluewfee;
          if (combuybnbUsdt > 0) {
            exovaluewfee = pairData.markPrice * totalOrgQty;
          } else {
            exovaluewfee = pairData.markPrice * finalQty;
          }
          exovaluewfee = exovaluewfee - combuybnbUsdt;
          let finalProfit = exovaluewfee - investmentPrice;
          finalProfit = toFixedDown(finalProfit, priceDecimal);
          let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

          console.log(finalQty, "his1");
          console.log(totalOrgQty, "his3");
          console.log(exovaluewfee, "his3");
          console.log(pairData.markPrice, "his4");
          console.log(finalProfit, "his5");
          console.log(investmentPrice, "his6");
          console.log(combuybnbUsdt, "his7");

          let investment = 0;
          for (let data of item.entryPrice) {
            if (item.exchange == "BitMart") {
              investment =
                investment + data.orgquantity * data.price + data.comissionFee;
            } else {
              investment = investment + data.orgquantity * data.price;
            }
          }

          let cmp1 = pairData.markPrice * item.quantity;

          let cmp =
            pairData.markPrice * item.quantity - avgPrice * item.quantity;

          // console.log(investment, "investment----");
          // console.log(finalProfit, "finalProfit----");
          let curVal = investment + finalProfit;
          // console.log(curVal, "-------641");
          pairList.push({
            ...item,
            currentvalue: curVal,
            cmp: pairData.markPrice,
            firstCurrency: pairData.firstCurrencySymbol,
            secondCurrency: pairData.secondCurrencySymbol,
            cmp1: cmp1,
            // investment: investment ? toFixed(investment, 4) : 0.0,
            investment: investment ? investment : 0.0,
            pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
            profitinprice: finalProfit,
            // profitinprice: profitinprice,
            index: index,
          });
        }

        setData(pairList);
        setOData(pairList);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setPair(value);
    // fetchDetail(value, selectedValue);
    var searcharr = [];
    if (oData && oData.length > 0) {
      for (var i = 0; i < oData.length; i++) {
        var temppair = oData[i].pairName.toLowerCase();
        if (temppair.startsWith(value.toLowerCase())) {
          searcharr.push(oData[i]);
        }
        if (i == oData.length - 1) {
          setData(searcharr);
        }
      }
    }
  };

  useEffect(() => {
    fetchDetail();

    let socketTicker = "socketTicker" + exchange;

    socketContext[socketTicker].on("marketPrice", (result) => {
      //   console.log(result, "socket result positions");
      setData((el) => {
        let pairList = [];
        el &&
          el.map((item) => {
            if (item.pairId == result.pairId) {
              let avgPrice = item.avgPrice / item.quantity;

              let priceDecimal = item.priceDecimal;
              let combuybnbUsdt = 0;
              let finalQty = calPrice(item.filled, "quantity");
              finalQty = toFixedDown(finalQty, item?.quantityDecimal);
              let totalOrgQty = calPrice(item.filled, "orgquantity");

              let investmentPrice = calPrice(item.filled, "Totalprice");
              if (item.exchange == "BitMart") {
                investmentPrice = calPrice(item.filled, "TotalpriceBM");
              }
              for (let itemfill of item?.filled) {
                if (
                  !isEmpty(itemfill?.feeFilled) &&
                  itemfill?.feeFilled.length > 0
                ) {
                  for (let buyfeeItem of itemfill?.feeFilled) {
                    if (
                      buyfeeItem?.commissionAsset == "BNB" &&
                      item.exchange == "Binance"
                    ) {
                      combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                    }
                  }
                }
              }

              let exitOrdervalue = 0;
              if (combuybnbUsdt > 0) {
                exitOrdervalue = result.data.markPrice * totalOrgQty;
              } else {
                exitOrdervalue = result.data.markPrice * finalQty;
              }
              exitOrdervalue = exitOrdervalue - combuybnbUsdt;
              let finalProfit = exitOrdervalue - investmentPrice;
              finalProfit = toFixedDown(finalProfit, priceDecimal);
              let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

              console.log(finalQty, "hissoc1");
              console.log(exitOrdervalue, "hissoc3");
              console.log(result.data.markPrice, "hissoc4");
              console.log(finalProfit, "hissoc5");
              console.log(investmentPrice, "hissoc6");
              console.log(combuybnbUsdt, "hissoc7");
              console.log(finalProfitPercentage, "hissoc799");

              let investment = 0;
              for (let data of item.entryPrice) {
                if (item.exchange == "BitMart") {
                  investment =
                    investment +
                    data.orgquantity * data.price +
                    data.comissionFee;
                } else {
                  investment = investment + data.orgquantity * data.price;
                }
              }
              let curVal = investmentPrice + finalProfit;
              pairList.push({
                ...item,
                cmp: result.data.markPrice,
                firstCurrency: item.firstCurrency,
                secondCurrency: item.secondCurrency,
                pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
                currentvalue: curVal,
                investment: toFixed(investment, 4),
                // profitinprice: profitinprice,
              });
            } else {
              pairList.push(item);
            }
          });

        return pairList;
      });
    });
  }, []);

  return (
    <>
      <div className="abv_flx">
        <div className="drpdwn_box">
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle id="dropdown-basic">
              <span className="exchange">Exchange</span>
              {selectedValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="All">
                {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
                Select Exchange
              </Dropdown.Item>
              <Dropdown.Item eventKey="Binance">
                <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                Binance
              </Dropdown.Item>

              <Dropdown.Item eventKey="Bybit">
                <img src={bybit} class="img-fluid crypto me-2" alt="icon" />
                Bybit
              </Dropdown.Item>

              <Dropdown.Item eventKey="BitMart">
                <img src={bitmart} class="img-fluid crypto me-2" alt="icon" />
                BitMart
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="inputgrp_box">
          <div class="input-group">
            <span class="input-group-text pe-0" id="basic-addon1">
              <img
                src={require("../../assets/images/search.png")}
                alt="Icon"
                className="img-fluid search"
              />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search assets"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={handleChange}
              name="pair"
              value={pair}
            />
          </div>
        </div>
      </div>
      <div className="table_sec table_sec_chg">
        <DataTable columns={columns} data={data} pagination />
      </div>
      {/* 
      <div className="no_data py-5">
        <img
          src={require("../../assets/images/nodata.png")}
          alt="Icon"
          className="img-fluid mb-3"
        />
        <p>No data to show</p>
      </div> */}
    </>
  );
}
