import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import lib
import config from "../../../config";
import { widget } from "../../charting_library/";
import isEmpty from "../../../lib/isEmpty";
import { useContext } from "react";
import SocketContext from "../../Context/SocketContext";
import "../../chart_css/index.css";

const chartUrl = config.API_URL;
// console.log(chartUrl, "chartUrlchartUrl");
let tvWidget = null;

const Chart = (props) => {
  console.log("Dca backtest chart-----");

  const chartContainerRef = useRef();
  const socketContext = useContext(SocketContext);

  const { params, data } = props;
  // const tpair = params.pair;
  const exchange = params.exchange;

  console.log(exchange, "----exchangeexchangeexchange");

  // const tradePair = tpair.replace(/-/g, "");
  const tradePair = params.pair;
  console.log(tradePair, "--tradePair");

  // state
  const themeData = useSelector((state) => state.tradeThemeReducers);
  let flagsLine = useRef([]);

  // function
  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair, chartType) => {
    if (!isEmpty(chartType)) {
      chartType = parseInt(chartType);
    } else {
      chartType = 2;
    }
    const widgetOptions = {
      symbol: pair,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        props.datafeedUrl + "/" + exchange
      ),
      interval: "1D",
      container: chartContainerRef.current,
      container_id: props.containerId,
      library_path: props.libraryPath,

      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "apply-overflow-tooltip",
        "header_compare",
        "study_templates",
        "header_undo_redo",
        "display_legend_on_all_charts",
        //"create_volume_indicator_by_default",
        "legend_context_menu",
        "format_button_in_legend",
        "source_selection_markers",
        // "volume_force_overlay",
        "hide_last_na_study_output",
        "header_screenshot",
        "border_around_the_chart",
        "display_market_status",
        "header_symbol_search",
        "header_resolutions",
        "header_widget",
      ],
      enabled_features: [],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      loading_screen: { backgroundColor: "#2c273d" },
      theme: theme,
      style: props.style,
      indicators: props.indicators,
      has_no_volume: props.has_no_volume,
      time_frames: props.time_frames,
      //toolbar_bg: "#1d2146",

      toolbar_bg: "#2c273d",

      overrides: {
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#2c273d",
        "paneProperties.vertGridProperties.color": "#363c4e",
        "paneProperties.horzGridProperties.color": "#363c4e",
        "mainSeriesProperties.style": chartType,
        "scalesProperties.lineColor": "#2c273d",
        "mainSeriesProperties.lineStyle.linewidth": 3,
      },
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      // tvWidget.headerReady().then(() => {
      const button = tvWidget.createButton();
      button.setAttribute("title", "Click to show a notification popup");
      button.classList.add("apply-common-tooltip");
      button.addEventListener("click", () =>
        tvWidget.showNoticeDialog({
          title: "Notification",
          body: "TradingView Charting Library API works correctly",
          callback: () => {
            console.log("Noticed!");
          },
        })
      );
      //});
    });
  };
  useEffect(() => {
    if (!isEmpty(tradePair)) {
      let symbol = tradePair;
      let themeValue = "White";
      if (themeData == "light") {
        themeValue = "Dark";
      } else if (themeData == "dark") {
        themeValue = "White";
      }
      buildchart(
        themeValue,
        symbol,
        localStorage.getItem("tradingview.chart.lastUsedStyle")
      );
    }
  }, [themeData, tradePair]);

  useEffect(() => {
    console.log(data, "--------------182");
    if (tvWidget !== null) {
      tvWidget.onChartReady(() => {
        //tvWidget.headerReady().then(() => {
        if (data && data.length > 0) {
          if (tvWidget !== null) {
            tvWidget.onChartReady(() => {
              //tvWidget.headerReady().then(() => {
              //  flagsLine.current.forEach((line) => line.remove());
              flagsLine.current.forEach((line) =>
                tvWidget.chart().removeEntity(line)
              );
              flagsLine.current = [];
              // flagsLine.current = [];
              data.forEach((item) => {
                let circleCoordinates = {
                  // time: new Date(item.createdAt).getTime() / 1000,
                  time: item.tb / 1000,
                  price: item.price, // Set the y-coordinate based on the price where you want the circle
                };

                // console.log(item.tb, circleCoordinates, "168circleCoordinates");
                const circleProperties = {
                  shape: "icon",
                  overrides: {
                    color: item.side == "buy" ? "#039345" : "red",
                    size: 20,
                  },
                  lock: true,
                  disableSelection: true,
                  disableSave: true,
                  disableUndo: true,
                  icon: 0xf192,
                };
                const flag = tvWidget
                  .chart()
                  .createShape(circleCoordinates, circleProperties);

                flagsLine.current.push(flag);
              });
              //});
            });
          }
        } else {
          if (flagsLine.current) {
            // tvWidget.activeChart().removeAllShapes();
            flagsLine.current.forEach((line) =>
              tvWidget.chart().removeEntity(line)
            );
            // tvWidget.chart().clearMarks();
            flagsLine.current = []; // Reset the reference
          }
        }
        //});
      });
    }
  }, [data, tvWidget]);

  return (
    // <div
    //   id={props.containerId}
    //   className={"chartcontainer"}
    //   style={{ fontSize: "2px", height: "420px" }}
    // />
    <div
      style={{ fontSize: "2px", height: "420px" }}
      ref={chartContainerRef}
      className={"TVChartContainer"}
    />
  );
};

Chart.propTypes = {
  symbol: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  datafeedUrl: PropTypes.string.isRequired,
  libraryPath: PropTypes.string.isRequired,
  chartsStorageUrl: PropTypes.string.isRequired,
  chartsStorageApiVersion: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  fullscreen: PropTypes.string.isRequired,
  autosize: PropTypes.string.isRequired,
  studiesOverrides: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  symbol: "BTCUSDT",
  interval: "1D",
  containerId: "tv_dca_chart_container",
  datafeedUrl: chartUrl + "/api/chart",
  libraryPath: "/charting_library/", //live
  chartsStorageUrl: "",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
  style: 2,
  has_no_volume: false,
  indicators: "volume",

  time_frames: [
    {
      text: "3M",
      resolution: "720",
      description: "3 months in 12 hours intervals",
      title: "3m",
    },

    {
      text: "1M",
      resolution: "360",
      description: "1 month in 6 hours intervals",
      title: "1m",
    },

    {
      text: "7d",
      resolution: "60",
      description: "7 days in 1 hour intervals",
      title: "7d",
    },

    {
      text: "3d",
      resolution: "30",
      description: "3 days in 30 minutes intervals",
      title: "3d",
    },

    {
      text: "1d",
      resolution: "15",
      description: "1 day in 15 minutes intervals",
      title: "1d",
    },

    {
      text: "6h",
      resolution: "5",
      description: "6 hours in 5 minutes intervals",
      title: "6h",
    },

    {
      text: "1h",
      resolution: "1",
      description: "1 hour in 1 minute intervals",
      title: "1h",
    },
  ],
};

export default Chart;
