// import constant
import { SET_GRID_BOT_DETAIL, RESET } from "../constant";

const initialValue = {
  action: "create",
  // bot: {}
};
const curGridBot = (state = initialValue, action) => {
  switch (action.type) {
    case SET_GRID_BOT_DETAIL:
      // return {
      //   ...action.data,
      // };
      return {
        ...state,
        ...action.data,
      };
    case RESET:
      return initialValue;
    default:
      return state;
  }
};

export default curGridBot;
