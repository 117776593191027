import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";
import { getOpenPosition } from "../../actions/gridbot";
import binance from "../../assets/images/binance.svg";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.png";

import { getTradenotifi, setNotiStatus } from "../../actions/users";

// import lib
import { dateTimeFormat, MonthDateFormat } from "../../lib/dateFilter";
import { useNavigate } from "react-router-dom";

export default function TradeRelate() {
  // state
  const [allowNot, setAllowNot] = useState();
  const [notData, setNotData] = useState([]);
  const [autoLoader, setAutoLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Binance");
  const [pair, setPair] = useState();
  const [oData, setOData] = useState();
  const [page, setpagecount] = useState(1);
  const [count, setCount] = useState(0);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const noOfPages = Math.ceil(count / 10);
  const pagesArr = [...new Array(noOfPages)];
  let pagedata = 1;
  const onPrevPage = () => {
    setpagecount(page - 1);
  };

  const gotoPage = (data) => {
    setpagecount(data);
  };

  const onNextPage = () => {
    setpagecount(page + 1);
  };
  const onPageSelect = (pageNo) => {
    setpagecount(pageNo);
  };

  useEffect(() => {
    fetchNotification();
    setNotification();
  }, []);
  const setNotification = async () => {
    try {
      let reqData = { type: "tradeRelated" };
      const { status, result, loading, message, error } = await setNotiStatus(
        reqData
      );
    } catch (err) {}
  };

  useEffect(() => {
    let reqdata = {
      page,
      limit: 10,
      exchange: selectedValue,
    };
    fetchNotification(reqdata);
  }, [page, selectedValue]);

  const fetchNotification = async (reqdata) => {
    try {
      const { status, result, loading, message, error } = await getTradenotifi(
        reqdata
      );
      setAutoLoader(false);
      if (status == "success") {
        setNotData(result.TradenotifiData);
        setCount(result.count);
      } else {
        toastAlert("error", message, "autoSelect");
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (noOfPages === page) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (page === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, page]);

  const handleSelect = (eventKey, event) => {
    setSelectedValue(eventKey);
  };

  const handleChange = async (e) => {
    const { value } = e.target;
    setPair(value);
  };

  return (
    <>
      <div className="abv_flx justify-content-between">
        <div className="d-lg-flex gap-2 align-items-center">
          <div className="drpdwn_box">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="exchange">Select Exchange</span>
                {selectedValue}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="All">
                  {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
                  Select Exchange
                </Dropdown.Item>
                <Dropdown.Item eventKey="Binance">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  Binance
                </Dropdown.Item>

                <Dropdown.Item eventKey="Bybit">
                  <img src={bybit} class="img-fluid crypto me-2" alt="icon" />
                  Bybit
                </Dropdown.Item>

                <Dropdown.Item eventKey="BitMart">
                  <img src={bitmart} class="img-fluid crypto me-2" alt="icon" />
                  BitMart
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <div className="inputgrp_box">
            <div class="input-group">
              <span class="input-group-text pe-0" id="basic-addon1">
                <img
                  src={require("../../assets/images/search.png")}
                  alt="Icon"
                  className="img-fluid search"
                />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search assets"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                name="pair"
                value={pair}
              />
            </div>
          </div> */}
        </div>
        {/* <div>
          <button className="primary_btn export_btn locked">
            <img
              src={require("../../assets/images/lock.png")}
              class="img-fluid me-2"
              alt="icon"
            />
            <span>Export CSV</span>
          </button>
        </div> */}
      </div>

      <div className="notification_panel">
        {notData && notData.length == 0 && (
          <div className="sc-feUZmu jzrolw">
            <div className="sc-hzhJZQ gmlwXk">
              <div className="sc-aXZVg jNIkof rdt_Table" role="table">
                <div className="sc-hmdomO cTsUPQ">
                  <div style={{ padding: "24px", "text-align": "center" }}>
                    There are no records to display
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {notData &&
          notData.length > 0 &&
          notData.map((item, i) => {
            return (
              <div>
                <h2>{MonthDateFormat(item._id)}</h2>
                {item.data &&
                  item.data.length > 0 &&
                  item.data.map((el, key) => {
                    let orderDet = "Order Filled. ";
                    let execDet = ` QTY ${toFixed(
                      el.orgquantity,
                      6
                    )} PRICE ${toFixed(el.price, 6)} AMOUNT ${toFixed(
                      el.orderValue,
                      6
                    )}`;

                    let description = `${el.exchange} ${el.firstCurrency}/${
                      el.secondCurrency
                    } ${el.buyorsell.toUpperCase()} ${orderDet}${execDet}`;
                    return (
                      <div className="notification_panel_single">
                        <div>
                          <img
                            src={require("../../assets/images/" +
                              selectedValue.toLowerCase() +
                              ".png")}
                          />
                        </div>
                        <h3>{el.exchange}</h3>
                        <h4>{description}</h4>
                        <h5>{dateTimeFormat(el.orderDate)}</h5>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      <br />
      {noOfPages > 1 ? (
        <ul class="pagination">
          {/* <li class="page-item">
            <button
              class="page-link"
              onClick={onPrevPage}
              disabled={!canGoBack}
            >
              {"<<"}
            </button>
          </li> */}
          <span>
            Page{" "}
            <strong>
              {page} of {noOfPages}
            </strong>{" "}
          </span>
          &nbsp;&nbsp;
          <button
            class="page-link"
            onClick={() => gotoPage(1)}
            disabled={!canGoBack}
          >
            {"<<"}
          </button>{" "}
          <button class="page-link" onClick={onPrevPage} disabled={!canGoBack}>
            {"<"}
          </button>{" "}
          <button class="page-link" onClick={onNextPage} disabled={!canGoNext}>
            {">"}
          </button>{" "}
          <button
            class="page-link"
            onClick={() => gotoPage(noOfPages)}
            disabled={!canGoNext}
          >
            {">>"}
          </button>{" "}
          {/* {pagesArr.map((num, index) => (
            <>
              <li class="page-item">
                <button
                  class="page-link"
                  onClick={() => onPageSelect(index + 1)}
                >
                  {"<"}
                </button>
              </li>
            </>

          ))} */}
          {/* <li class="page-item">
            <button
              class="page-link"
              onClick={onNextPage}
              disabled={!canGoNext}
            >
              {">>"}
            </button>
          </li> */}
        </ul>
      ) : null}
    </>
  );
}
