import Scrollbars from "react-custom-scrollbars";
import React, { useState } from "react";

export default function PreviewCreateBot(props) {
  // props
  const { params, handleBack, handleClose, data, handleStartBot, loader } =
    props;
  const { pair } = params;

  return (
    <div className="card trade_card">
      <div className="card-header">
        <h2>Preview GRID Bot</h2>
        <p>
          <i onClick={() => handleClose()} className="bi bi-x-circle-fill"></i>
        </p>
      </div>

      <div className="card-body card-body-pb">
        <div className="card_body_padding">
          <div className="bot_preview_box_wrapper">
            {/* <Scrollbars
              style={{ height: 400 }}
              renderTrackVertical={({ style, ...props }) => (
                <div
                  className="track_vertical"
                  {...props}
                  style={{
                    ...style,
                    right: "2px",
                    bottom: "2px",
                    top: "2px",
                    borderRadius: "10px",
                    width: "3px",
                  }}
                />
              )}
              renderThumbVertical={({ style, ...props }) => (
                <div
                  className="thumb_vertical"
                  {...props}
                  style={{
                    ...style,
                    width: "3px",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                  }}
                />
              )}
            > */}
              <div class="mb-2">
                <div className="bot_preview_box">
                  <div>
                    <span>Exchange</span>
                    <span className="border_line"></span>
                    <span>{params.exchange}</span>
                  </div>
                  <div>
                    <span>Pair</span>
                    <span className="border_line"></span>
                    <span>{pair && pair.replace("-", " / ")}</span>
                  </div>
                  <div>
                    <span>Investment</span>
                    <span className="border_line"></span>
                    <span>
                      {data?.investamount + " " + pair?.split("-")[1]}
                    </span>
                  </div>
                  <div>
                    <span>Profit currency</span>
                    <span className="border_line"></span>
                    <span>USDT</span>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <div className="bot_preview_box">
                  <div>
                    <span>Sell orders</span>
                    <span className="border_line"></span>{" "}
                    <span>
                      {data?.totalSellInvst} {pair?.split("-")[0]}
                    </span>
                  </div>
                  <div>
                    <span>Buy orders</span>
                    <span className="border_line"></span>{" "}
                    <span>
                      {data?.totalBuyInvst} {pair?.split("-")[1]}
                    </span>
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <div className="bot_preview_box">
                  <div>
                    <span>High price</span>
                    <span className="border_line"></span>
                    <span>{data?.high}</span>
                  </div>
                  <div>
                    <span>Low price</span>
                    <span className="border_line"></span>
                    <span>{data?.low}</span>
                  </div>
                  <div>
                    <span>Grid levels</span>
                    <span className="border_line"></span>
                    <span>{data?.gridlvl}</span>
                  </div>
                  <div>
                    <span>Grid step</span>
                    <span className="border_line"></span>
                    <span>{data?.gridpercent}%</span>
                  </div>
                </div>
              </div>
              <div class="mb-2">
                <div className="bot_preview_box">
                  <div>
                    <span>Order size currency</span>
                    <span className="border_line"></span>
                    <span>USDT</span>
                  </div>
                </div>
              </div>
            {/* </Scrollbars> */}
          </div>
        </div>
        <div className="bgcolor d-flex align-items-center gap-2">
          <button className="backtest w-25" onClick={() => handleBack()}>
            <img
              src={require("../../assets/images/right_arw.png")}
              alt="Icon"
              className="img-fluid cursor"
            />
          </button>
          <button
            disabled={loader}
            onClick={() => handleStartBot()}
            className="continue"
          >
            {loader && <span>Loading...</span>}
            {!loader && <span>Start Bot</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
