// import config
import axios, { handleResp } from "../config/axios";
import { SET_PRICE_CONVERSION } from "../constant";

export const getPriceConversion = async (dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/priceConversion`,
        });
        dispatch({
            type: SET_PRICE_CONVERSION,
            data: respData.data.result,
        });
        return true;
    } catch (err) {
        handleResp(err, "error");
        return false;
    }
};

export const getFeeAndDecimal = async (exchange, pair) => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/feeAndDecimal/${exchange}/${pair}`,
        });
        return {
            status: "success",
            result: respData.data.result
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};