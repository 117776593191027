import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../components/Logo";

// import component
import ForgotPassword from "../components/ForgotPassword";
import { getCms } from "../actions/users";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Terms() {
  const [cmsContent, setContent] = useState();

  const fetchcms = async () => {
    try {
      const { success, result } = await getCms({ identifier: "terms" });
      if (success) {
        setContent(result);
      }
    } catch (err) {
      console.log(err, "-----------------------05");
    }
  };

  useEffect(() => {
    fetchcms();
  }, []);

  return (
    <section className="crediantial-layout">
      <ScrollToTopOnMount />
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-5 mx-auto logo_beforeauth">
            <Logo />
            <h2>{cmsContent?.subject}</h2>
            <div dangerouslySetInnerHTML={{ __html: cmsContent?.content }} />
          </div>
        </div>
      </div>
    </section>
  );
}
