import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DataTable from "react-data-table-component";
import { Form, Modal } from "react-bootstrap";
import ExchangeBalance from "../components/BotTrade/ExchangeBalance";
import Dropdown from "react-bootstrap/Dropdown";
import TradeManual from "../components/BotTrade/TradeManual";
import { useParams } from "react-router-dom";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function TradeMan() {
  const params = useParams();
  return (
    <main>
      <ScrollToTopOnMount />
      <Header />
      <TradeManual params={params} />
      <Footer />
    </main>
  );
}
