import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import context
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import kucoin from "../../assets/images/kucoin.png";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.svg";
import { getExchangeData } from "../../actions/users";
import { setBotStatus } from "../../actions/Bottrade";

import isEmpty from "../../lib/isEmpty";

export default function ExchangeBalance(props) {
  console.log("ExchangeBalance-----");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Initialize with a default value
  const { params, updatePair } = props;
  const [selectedValue, setSelectedValue] = useState(params.exchange);
  const [exchangeData, setExchangeData] = useState();
  const [exchangeSearch, setExchangeSearch] = useState();
  const [sbExc, setSBExc] = useState("");

  const handleSelect = (eventKey, event) => {
    setSelectedValue(eventKey);
    let pair = "BTC-USDT";

    if (eventKey != "All" && params.exchange != eventKey) {
      dispatch(
        setBotStatus({
          status: false,
          crtBot: true,
          openStatus: true,
          pair: pair,
        })
      );
      updatePair({
        exchange: eventKey,
        bot: params.bot,
        pair: pair,
      });
      navigate(`/trade/${pair}/${eventKey}/${params.bot}`);

      // window.location.reload();
    }
  };

  const fetchExchange = async () => {
    try {
      const { status, result } = await getExchangeData();
      if (status == "success") {
        setExchangeData(result);
        setExchangeSearch(result);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchExchange();
  }, []);

  useEffect(() => {
    setSelectedValue(params.exchange);
  }, [params.exchange]);

  const searchExc = (e) => {
    e.preventDefault();
    setSBExc(e.target.value);
    var searcharr = [];
    if (exchangeSearch && exchangeSearch.length > 0) {
      for (var i = 0; i < exchangeSearch.length; i++) {
        var temppair = exchangeSearch[i].exchange.toLowerCase();
        if (temppair.indexOf(e.target.value.toLowerCase()) !== -1) {
          searcharr.push(exchangeSearch[i]);
        }
        if (i == exchangeSearch.length - 1) {
          setExchangeData(searcharr);
        }
      }
    }
  };
  return (
    <div className="drpdwn_box">
      <Dropdown onSelect={handleSelect} className="w-100">
        <Dropdown.Toggle id="dropdown-basic">
          <span className="exchange">Exchange</span>
          {/* <img
            width="10%"
            src={
              params.exchange == "Binance"
                ? binance
                : params.exchange == "Kucoin"
                ? kucoin
                : params.exchange == "BitMart"
                ? bitmart
                : ""
            }
            class="img-fluid crypto me-2"
            alt="icon"
          /> */}
          {selectedValue}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search_inp_div">
            <input
              type="text"
              placeholder="Search Exchange"
              className="form-control search_inp"
              name="symbol"
              value={sbExc}
              onChange={searchExc}
            />
            <i className="bi bi-search"></i>
          </div>
          <Dropdown.Item eventKey="All">
            {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
            All Exchange
          </Dropdown.Item>

          {/* {isEmpty(exchangeData) && (
            <Dropdown.Item eventKey="Binance">
              <img src={binance} class="img-fluid crypto me-2" alt="icon" />
              Binance
            </Dropdown.Item>
          )} */}

          {exchangeData &&
            exchangeData.length > 0 &&
            exchangeData.map((item, index) => {
              let imgExchange =
                item.exchange == "Binance"
                  ? binance
                  : item.exchange == "Bybit"
                  ? bybit
                  : bitmart;
              return (
                <Dropdown.Item eventKey={item.exchange}>
                  <img
                    src={imgExchange}
                    class="img-fluid crypto me-2"
                    alt="icon"
                  />
                  {item.exchange}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
