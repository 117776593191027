// import package
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import browser from "browser-detect";
import Checkbox from "rc-checkbox";
import { useNavigate } from "react-router-dom";
import clsx from "classnames";

// import action
import { getGeoInfoData, login, ResentOTP } from "../../actions/users";

// import lib
import validation from "./validation";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { getLang } from "../../lib/localStorage";

const initialFormValue = {
  email: "",
  formType: "",
  password: "",
  otp: "",
  twoFACode: "",
  remember: false,
  is2FA: false,
  showPassword: "",
};
var setInt;

const EmailForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tab } = props;
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const [loader, setLoader] = useState();
  const [loginHistory, setLoginHistory] = useState({});
  const [showTwoFA, setShowTowFA] = useState(false);
  const [mailOtp, setOTP] = useState(false);
  const [Timer, setTimer] = useState(300);
  const [resend, setResend] = useState(false);
  const [count, setCount] = useState("send");

  // console.log(tab, "__________tab");
  const {
    email,
    password,
    showPassword,
    otp,
    formType,
    remember,
    is2FA,
    twoFACode,
  } = formValue;

  function callinterval() {
    var ts = 300;
    setTimer(ts);
    setInt = setInterval(() => {
      // console.log(setInt,"_____________-01111")
      ts = ts - 1;
      if (ts == 0) {
        setTimer(ts);
        clearInterval(setInt);
        setResend(false);
      } else {
        setTimer(ts);
      }
    }, 1000);
  }

  function myStopFunction() {
    clearInterval(setInt);
    setResend(false);
  }

  const handleChange = (e) => {
    try {
      const { id, name, value } = e.target;
      if (name == "email" || name == "password") {
        myStopFunction();
      }

      if (name == "twoFACode") {
        if (!(value == "" || (/^[0-9\b]+$/.test(value) && value.length <= 6))) {
          return;
        }
        // if(!is2FA){
        //     return
        // }
      }

      let formData = { ...formValue, ...{ [name]: value } };
      setFormValue(formData);
      setValidateError(validation(formData));
      if (value) {
        setValidateError({});
      }
    } catch (err) {
      console.log(err, "_______-erorr");
    }
  };

  // const handleBlur = (e) => {
  //   const { name, id } = e.target;
  //   alert(name);
  //   setToched({ ...toched, ...{ [id]: true } });
  // };

  const handle2FA = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, ...{ [name]: checked } };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    let formData = { ...formValue, [name]: checked };
    setFormValue(formData);
    setValidateError(validation(formData));
  };

  const getGeoInfo = async () => {
    try {
      let { result } = await getGeoInfoData();
      const browserResult = browser();
      setLoginHistory({
        countryName: result.country_name,
        countryCode: result.country_calling_code,
        ipaddress: result.ip,
        region: result.region,
        broswername: browserResult.name,
        ismobile: browserResult.mobile,
        os: browserResult.os,
      });
    } catch (err) {}
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (Timer == 0) {
      if (isEmpty(otp)) {
        toastAlert(
          "success",
          "Please click 'Resend mail OTP' to receive the updated OTP in your registered mail id",
          "login"
        );
      }
    }

    let reqData = {
      email,
      otp,
      password,
      is2FA,
      count,
      Timer,
      remember,
      twoFACode,
      loginHistory,
      langCode: getLang(),
      formType,
    };

    let { status, loading, message, userSetting, error, authToken } =
      await login(reqData, dispatch);
    setLoader(loading);
    if (status == "success") {
      setFormValue(initialFormValue);
      if (remember) {
        localStorage.setItem("remember", true);
        localStorage.setItem("email_remember", email);
        localStorage.setItem("password_remember", password);
        localStorage.setItem("formType", formType);
      } else {
        localStorage.removeItem("remember");
        localStorage.removeItem("email_remember");
        localStorage.removeItem("password_remember");
      }
      // console.log(message,"_______message")
      toastAlert("success", message, "login");
      if (
        userSetting &&
        userSetting.afterLogin &&
        userSetting.afterLogin != " "
      ) {
        // navigate(userSetting.afterLogin.url);
        navigate("/trade/BTC-USDT/Binance/dca");
      } else {
        navigate("/trade/BTC-USDT/Binance/dca");
      }
    } else if (status == "TWO_FA") {
      setShowTowFA(true);
      toastAlert("error", message, "login");
    } else if (status == "OTP") {
      setOTP(true);

      toastAlert("success", message, "login");
    } else {
      if (error) {
        setValidateError(error);
      }
      if (message == "Your Password is Old Please Reset Your Password") {
        toastAlert("error", message, "login");
        navigate("/reset-password/" + authToken);
      }
      toastAlert("error", message, "login");
    }
  };

  const handleSentOTP = async (e) => {
    e.preventDefault();
    let reqData = {
      formType,
      email,
    };
    try {
      let { status, loading, error, message } = await ResentOTP(reqData);
      if (status == "success") {
        setResend(true);
        callinterval();
        setCount("re" + count);
        toastAlert("success", message, "mobileForm");
      } else {
        if (error) {
          setValidateError(error);
          return;
        }
        toastAlert("error", message, "mobileForm");
      }
    } catch (err) {}
  };

  useEffect(() => {
    getGeoInfo();
    let formData = {};
    if (localStorage.getItem("remember") == "true") {
      formData = formValue;
      formData["email"] = localStorage.getItem("email_remember");
      formData["password"] = localStorage.getItem("password_remember");
      formData["remember"] = true;
      formData["formType"] = localStorage.getItem("formType");
      setFormValue(formData);
    } else {
      formData = {
        email: "",
        password: "",
        twoFACode: "",
        otp: "",
        remember: false,
        formType: "email",
      };
      setFormValue(formData);
    }
    // setValidateError(validation(formData))
  }, []);

  useEffect(() => {
    if (tab != "emailform") {
      setFormValue(initialFormValue);
      setValidateError({});
      // setToched({});
    }
  }, [tab]);

  return (
    <Fragment>
      <form>
        <div className="form-group">
          <label>Email Address</label>
          <input
            type="text"
            className="form-control"
            placeholder="Email"
            name="email"
            // id="email"
            autoComplete="off"
            value={email}
            onChange={handleChange}
            // onBlur={handleBlur}
          />
          {/* {validateError.email && ( */}
          <p className="text-danger">{validateError && validateError.email}</p>
          {/* )} */}
        </div>
        <div className="form-group">
          <div className="label-flex">
            <label className="mb-0">Password</label>
            <Link to="/forgot-password" state={{ type: "email" }}>
              Forgot Password
            </Link>
          </div>
          <div className="input-group regGroupInput mt-2">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              name="password"
              id="password"
              value={password}
              onChange={handleChange}
              // onBlur={handleBlur}
            />{" "}
            <div className="input-group-text inp_grp_width">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  setFormValue((el) => {
                    return { ...el, ...{ showPassword: !el.showPassword } };
                  });
                }}
              >
                <i
                  className={clsx(
                    "bi",
                    { "bi-eye": showPassword },
                    { "bi-eye-slash": !showPassword }
                  )}
                  // aria-hidden="true"
                ></i>
              </Link>
            </div>
          </div>
          {/* {validateError.password && ( */}
          <p className="text-danger">
            {validateError && validateError.password}
          </p>
          {/* )} */}
        </div>
        {mailOtp && (
          <div className="form-group">
            <label>OTP</label>
            <div className="input-group regGroupInput mt-2">
              <input
                type="text"
                className="form-control"
                placeholder="OTP"
                name="otp"
                value={otp}
                onChange={handleChange}
                // onBlur={handleBlur}
              />

              <div className="input-group-text">
                {!resend && (
                  <button class="input-group-text" onClick={handleSentOTP}>
                    {Timer == 0 ? "Resend Mail OTP" : "Send Mail OTP"}
                  </button>
                )}
                {resend && <>{Timer} seconds </>}
              </div>
            </div>
            {/* {validateError.otp && ( */}
            <p className="text-danger">{validateError && validateError.otp}</p>
            {/* )} */}
          </div>
        )}
        {showTwoFA && (
          <div className="form-group">
            <div className="switch-group">
              <label htmlFor="flexSwitchCheckChecked">2FA Code</label>
              <div className="form-check form-switch">
                {/* <input 
                            className="form-check-input" 
                            type="checkbox" 
                            id="flexSwitchCheckChecked" 
                            name="is2FA" 
                            onClick={handle2FA} 
                            checked={is2FA}
                        /> */}
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Enter 2FA"
              name="twoFACode"
              value={twoFACode}
              onChange={handleChange}
            />
            {/* {validateError.twoFACode && ( */}
            <p className="text-danger">
              {validateError && validateError.twoFACode}
            </p>
            {/* )} */}
          </div>
        )}
        <div className="form-group mb-0">
          <button
            className="btn btn-green-big"
            onClick={handleFormSubmit}
            disabled={!isEmpty(validateError) || loader}
          >
            {loader && <i class="fas fa-spinner fa-spin"></i>} Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default EmailForm;
