import React, { useContext, useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import kucoin from "../../assets/images/kucoin.png";
import bybit from "../../assets/images/bybit.png";
import bitmart from "../../assets/images/bitmart.png";

// import action
import { portfolioBal } from "../../actions/users";
import isEmpty from "../../lib/isEmpty";
import SocketContext from "../Context/SocketContext";
import { toFixed, toFixedDown } from "../../lib/roundOf";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Overview() {
  const socketContext = useContext(SocketContext);
  const [selectedValue, setSelectedValue] = useState("Binance"); // Initialize with a default value
  const [BalanceDataOrg, setBalanceOrg] = useState({});
  const [BalanceData, setBalance] = useState([]);
  const [totBal, setTotBal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [pair, setPair] = useState(); // Initialize with a default value
  const [Bindmsg, setBindmsg] = useState("");

  const handleSelect = (eventKey, event) => {
    if (eventKey != "All") {
      setSelectedValue(eventKey);
      let exchange = eventKey == "Select Exchange" ? "Binance" : eventKey;
      ApiBalanceBinance(exchange);
    }
  };

  const columns = [
    {
      name: "Coin",
      selector: (row) => {
        return (
          <div className="coin">
            {/* <img
              src={require("../../assets/images/btc.png")}
              class="img-fluid crypto"
              alt="icon"
            /> */}
            <div>
              <p>{row.currency}</p>
              <span>{row.asset}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Amount/Value",
      selector: (row) => {
        return (
          <div>
            <p>{toFixedDown(row.free, 8)}</p>
            <span>{toFixedDown(row.usdprice, 4)} USD</span>
          </div>
        );
      },
    },
    {
      name: "Current price",
      selector: (row) => {
        return `$${row.markPrice}`;
      },
    },
    {
      name: "% of portfolio",
      selector: (row) => {
        let portfolio = (parseFloat(row.usdprice) / totBal) * 100;
        return toFixedDown(portfolio, 2);
      },
    },
    // {
    //   name: "24h ROI",
    //   selector: (row) => row.roi,
    // },
    // {
    //   name: "Total ROI",
    //   selector: (row) => row.totroi,
    // },
  ];

  const ApiBalanceBinance = async (exchange) => {
    setLoader(true);
    let reqData = {
      exchange: exchange,
    };
    const { status, result, total, message } = await portfolioBal(reqData);
    if (status == "success") {
      // console.log(result, "-----211");
      setTotBal(total);
      setBalance(result);
      setBalanceOrg(result);
      setBindmsg(message);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedValue != "All") {
      let exchange =
        selectedValue == "Select Exchange" ? "Binance" : selectedValue;
      let exchangesArr = ["Binance", "BitMart", "Bybit"];
      for (let ex of exchangesArr) {
        if (exchange != ex) {
          let socketTickerL = "socketTicker" + ex;
          socketContext[socketTickerL].off("marketPrice");
        }
      }
      let socketTicker = "socketTicker" + exchange;
      console.log(socketTicker, "-------117");

      socketContext[socketTicker].on("marketPrice", (result) => {
        setBalance((el) => {
          let dataArr = [],
            total = 0;
          el &&
            el.map((item) => {
              if (item.pairId == result.pairId) {
                dataArr.push({
                  ...item,
                  markPrice: result.data.markPrice,
                  usdprice: result.data.markPrice * item.free,
                });
                total +=
                  parseFloat(result.data.markPrice) * parseFloat(item.free);
              } else {
                dataArr.push(item);
                total += parseFloat(item.markPrice) * parseFloat(item.free);
              }
            });

          setTotBal(total);

          return dataArr;
        });
      });
    }
  }, [selectedValue]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setPair(value);
    var searcharr = [];
    if (BalanceDataOrg && BalanceDataOrg.length > 0) {
      for (let i = 0; i < BalanceDataOrg.length; i++) {
        var temppair = BalanceDataOrg[i].currency.toLowerCase();

        if (temppair.startsWith(e.target.value.toLowerCase())) {
          searcharr.push(BalanceDataOrg[i]);
        }
        if (i == BalanceDataOrg.length - 1) {
          setBalance(searcharr);
        }
      }
    }
    // if (!isEmpty(value)) {
    //   let balanceset = BalanceDataOrg.filter(
    //     (o) => o.currency === value.toUpperCase()
    //   );
    //   setBalance(balanceset);
    // } else {
    //   setBalance(BalanceDataOrg);
    // }
  };

  useEffect(() => {
    ApiBalanceBinance("Binance");
  }, []);

  const handleRerfesh = async () => {
    setLoader(true);
    ApiBalanceBinance(selectedValue);
  };

  return (
    <>
      <div className="abv_flx">
        <div className="drpdwn_box">
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle id="dropdown-basic">
              <span className="exchange">Exchange</span>
              {selectedValue}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="All">
                {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
                Select Exchange
              </Dropdown.Item>
              <Dropdown.Item eventKey="Binance">
                <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                Binance
              </Dropdown.Item>

              <Dropdown.Item eventKey="Bybit">
                <img src={bybit} class="img-fluid crypto me-2" alt="icon" />
                Bybit
              </Dropdown.Item>

              <Dropdown.Item eventKey="BitMart">
                <img src={bitmart} class="img-fluid crypto me-2" alt="icon" />
                BitMart
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="inputgrp_box">
          <div class="input-group">
            <span class="input-group-text pe-0" id="basic-addon1">
              <img
                src={require("../../assets/images/search.png")}
                alt="Icon"
                className="img-fluid search"
              />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search assets"
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={handleChange}
              name="pair"
              autocomplete="off"
              value={pair}
            />
          </div>
        </div>
        <div className=" mt-3">
          <button
            disabled={loader}
            onClick={() => handleRerfesh()}
            className="backtest mb-2"
          >
            {loader ? "Loading..." : "Refresh Balance"}
          </button>
        </div>
      </div>

      {totBal > 0 && (
        <div className="info_box">
          <div>
            <span>Total value</span>
            <h6>$ {toFixedDown(totBal, 4)}</h6>
          </div>

          {/* <div>
          <span>24h ROI</span>
          <p className="red">-0.09%</p>
          <div className="money">-$ 368.98</div>
        </div>
        <div>
          <span> 7 days ROI </span>
          <p className="green">+4.38%</p>
          <div className="money">+$ 25 602.88</div>
        </div>
        <div>
          <span>30 days ROI </span>
          <p className="green">+4.04%</p>
          <div className="money">+$ 23 704.35</div>
        </div>
        <div>
          <span> Total ROI</span>
          <p className="green">+4.95%</p>
          <div className="money">+$ 28 711.52</div>
        </div> */}
        </div>
      )}
      <div className="table_sec">
        {BalanceData.length > 0 && (
          <DataTable columns={columns} data={BalanceData} />
        )}
        {Bindmsg && Bindmsg != "Success" && (
          <div className="sc-feUZmu jzrolw">
            <div className="sc-hzhJZQ gmlwXk">
              <div className="sc-aXZVg jNIkof rdt_Table" role="table">
                <div className="sc-hmdomO cTsUPQ">
                  <div style={{ padding: "24px", "text-align": "center" }}>
                    {Bindmsg}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
