import React, { useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import $ from "jquery";
import isEmpty from "../lib/isEmpty";
export default function NewBotModal() {
  const params = useParams();

  const gotoTrade = async (botType) => {
    let exchange = params.exchange;
    if (isEmpty(exchange)) {
      exchange = "Binance";
    }

    window.$("#new_bot_modal").modal("hide");
    if (botType == "manual") {
      window.location.href = `/trade-signal/Binance`;
    } else {
      window.location.href = `/trade/BTC-USDT/${exchange}/${botType}`;
    }
  };

  return (
    <>
      {/* New Bot Modal */}
      <div
        class="modal fade primary_modal"
        id="new_bot_modal"
        // data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="new_bot_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="new_bot_modalLabel">
                Start new bot
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="new_bot_panel">
                <a href="javascript:void(0)" onClick={() => gotoTrade("dca")}>
                  <div className="new_bot_panel_content">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="10"
                        fill="#28BA74"
                      ></rect>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24 28.8c-.66 0-1.2-.54-1.2-1.2V8.4c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v19.2c0 .66-.54 1.2-1.2 1.2Zm-13.2-1.2v-4.8c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v4.8c0 .66-.54 1.2-1.2 1.2-.66 0-1.2-.54-1.2-1.2Zm6-12v12c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2v-12c0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2Z"
                        fill="#fff"
                      ></path>
                    </svg>
                    <h4>DCA Bot</h4>
                  </div>
                  <p>
                    The Dollar-cost Averaging bot multiplies your gains and
                    reduces risks in volatile markets.
                  </p>
                </a>
                {/* <a href="javascript:void(0)" onClick={() => gotoTrade("grid")}>
                  <div className="new_bot_panel_content">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="10"
                        fill="#6552EC"
                      ></rect>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M27.6 8.1H8.4c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2h19.2c.66 0 1.2-.54 1.2-1.2V9.3c0-.66-.54-1.2-1.2-1.2ZM8.4 15.3h19.2c.66 0 1.2.54 1.2 1.2v1.2c0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2v-1.2c0-.66.54-1.2 1.2-1.2Zm0 6h19.2c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2H8.4c-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2Zm-.6 4.8h20.4c.336 0 .6.264.6.6 0 .336-.264.6-.6.6H7.8a.594.594 0 0 1-.6-.6c0-.336.264-.6.6-.6Z"
                        fill="#fff"
                      ></path>
                    </svg>
                    <h4>GRID Bot</h4>
                  </div>
                  <p>
                    The proven grid strategy bot maximizes your returns on the
                    slightest price changes.
                  </p>
                </a> */}

                <a
                  href="javascript:void(0)"
                  onClick={() => gotoTrade("manual")}
                >
                  <div className="new_bot_panel_content">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium qKdO78InOm9rrEHhxNsG css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="36"
                        height="36"
                        rx="10"
                        fill="#B15879"
                      ></rect>
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M27.195 23.59a14.115 14.115 0 0 1-9.2 3.41c-3.51 0-6.72-1.28-9.18-3.4-.45-.38-.49-1.06-.07-1.48l3.6-3.6a.98.98 0 0 1 1.31-.08c1.2.93 2.7 1.49 4.34 1.49 1.63 0 3.14-.56 4.34-1.5.4-.31.96-.28 1.32.08l3.6 3.6c.42.42.38 1.1-.06 1.48ZM21.995 13a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                        fill="#fff"
                      ></path>
                    </svg>
                    <h4>SIGNAL Bot</h4>
                  </div>
                  <p>
                    The Buy the Dip bot follows the falling price and allows you
                    to earn on the downtrend.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
