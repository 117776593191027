import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import lib
import config from "../../config";
// import { widget } from "../Chart/charting_library/charting_library.min";
import { widget } from "../charting_library";

import isEmpty from "../../lib/isEmpty";
import { useContext } from "react";
import SocketContext from "../Context/SocketContext";
import { toFixedDown } from "../../lib/roundOf";
import "../chart_css/index.css";
import { momentFormat } from "../../lib/dateFilter";
const chartUrl = config.API_URL;
let tvWidget = null;

const Chart = (props) => {
  const chartContainerRef = useRef();

  const socketContext = useContext(SocketContext);

  const { params, data, Botstloader } = props;

  const tpair = params.pair;
  const exchange = params.exchange;
  let orderLines = useRef([]),
    flagsLine = useRef([]),
    tpOrderLine = useRef(null),
    slOrderLine = useRef(null),
    dcaOrderLine = useRef(null);

  const tradePair = tpair.replace(/-/g, "");
  // state

  const themeData = useSelector((state) => state.tradeThemeReducers);

  const [theme, setTheme] = useState(themeData == "dark" ? "White" : "Dark");

  // redux state
  const botData = useSelector((state) => state.botStatus);
  const botId = useSelector((state) => state.botId);

  // const themeData = useSelector((state) => state.theme);

  // function
  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair, chartType) => {
    // console.log(pair, "---pair");
    if (!isEmpty(chartType)) {
      chartType = parseInt(chartType);
    } else {
      chartType = 2;
    }

    const widgetOptions = {
      symbol: pair,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        props.datafeedUrl + "/" + exchange
      ),
      interval: props.interval,
      container: chartContainerRef.current,

      container_id: props.containerId,
      library_path: props.libraryPath,
      timezone: "Asia/Kolkata",

      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "apply-overflow-tooltip",
        "header_compare",
        "study_templates",
        "header_undo_redo",
        "display_legend_on_all_charts",
        //"create_volume_indicator_by_default",
        "legend_context_menu",
        "format_button_in_legend",
        "source_selection_markers",
        // "volume_force_overlay",
        "hide_last_na_study_output",
        "header_screenshot",
        "border_around_the_chart",
        "display_market_status",
        "header_symbol_search",
        "hide_resolution_in_legend",
      ],
      enabled_features: ["header_settings", "border_around_the_chart"],
      charts_storage_url: props.chartsStorageUrl,
      charts_storage_api_version: props.chartsStorageApiVersion,
      client_id: props.clientId,
      user_id: props.userId,
      fullscreen: props.fullscreen,
      autosize: props.autosize,
      studies_overrides: props.studiesOverrides,
      loading_screen: { backgroundColor: "#2c273d" },
      theme: theme,
      style: props.style,
      indicators: props.indicators,
      has_no_volume: props.has_no_volume,
      time_frames: props.time_frames,
      //toolbar_bg: "#1d2146",

      toolbar_bg: "#2c273d",

      // timezone:
      //   timeZoneGet() == "Asia/Calcutta" ? "Asia/Kolkata" : timeZoneGet(),

      overrides: {
        // "symbolWatermarkProperties.color": "#000657",
        "paneProperties.backgroundType": "#231f30",
        "paneProperties.background": "#231f30",
        "paneProperties.vertGridProperties.color": "#363c4e",
        "paneProperties.horzGridProperties.color": "#363c4e",
        "scalesProperties.lineColor": "#231f30",
        "mainSeriesProperties.style": chartType,
        "mainSeriesProperties.lineStyle.linewidth": 3,
        // "mainSeriesProperties.priceLineWidth": 7,
      },
      custom_css_url: "./themed.css",
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      // let decimalvalue;
      // if (FeeandDecimal.priceDecimal) {
      //   decimalvalue =
      //     (10 ** FeeandDecimal.priceDecimal).toString() + ",2,false";
      // }
      // tvWidget.applyOverrides({
      //   "mainSeriesProperties.minTick": decimalvalue,
      // });

      // const newTimeZone = "Asia/Kolkata"; // Set your custom time zone
      // tvWidget.chart().setTimezone(newTimeZone);

      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );
      });
      tvWidget
        .chart()
        .onIntervalChanged()
        .subscribe(null, function (interval, obj) {
          flagsLine.current.forEach((line) =>
            tvWidget.chart().removeEntity(line)
          );
          tvWidget.activeChart().removeEntity();
          // buildchart(
          //   theme,
          //   pair,
          //   localStorage.getItem("tradingview.chart.lastUsedStyle")
          // );
        });
    });
  };
  useEffect(() => {
    if (!isEmpty(tradePair)) {
      // let symbol = tradePair.firstCurrencySymbol + tradePair.secondCurrencySymbol;
      let symbol = tradePair;

      let themeValue = "White";
      if (themeData == "light") {
        themeValue = "Dark";
      } else if (themeData == "dark") {
        themeValue = "White";
      }

      buildchart(
        themeValue,
        symbol,
        localStorage.getItem("tradingview.chart.lastUsedStyle")
      );

      // if (localStorage.getItem("tradingview.chart.lastUsedStyle") > 0) {
      //   setTimeout(function () {
      //     buildchart(themeValue, symbol);
      //   }, 2000);
      // }
    }
  }, [themeData, tradePair, exchange]);

  const converttime = (time) => {
    let intervalDuration = props.interval == "D" ? 86400000 : 60000;
    let rem = time % intervalDuration;
    return (time - rem) / 1000; // Aligned timestamp in seconds
  };

  useEffect(() => {
    console.log(data, "-------211");
    if (tvWidget !== null) {
      tvWidget.onChartReady(() => {
        if (
          data.socketrun == "no" &&
          !isEmpty(data) &&
          data?.takeprofit?.price > 0
        ) {
          console.log(data?.takeprofit?.takeprofitRatio, "---223");
          const priceScale_Reset = tvWidget
            .activeChart()
            .getPanes()[0]
            .getMainSourcePriceScale();
          priceScale_Reset.setAutoScale(true);
          console.log(data, "-----214");
          let avgprice = data?.avgPrice.avgPrice;
          let lp;
          if (
            data &&
            data.stoploss &&
            data.stoploss?.percentage > 0 &&
            data.stoploss?.price > 0
          ) {
            lp = data.stoploss.price;
          } else {
            if (
              data &&
              data?.avgCall &&
              data?.avgCall.length > 0 &&
              data?.avgCall[data.avgCall?.length - 1].price
            ) {
              lp = data.avgCall[data.avgCall.length - 1].price;
            } else {
              lp = data?.markPrice;
            }
          }
          let hp;
          if (parseFloat(data.takeprofit.price) < parseFloat(data?.markPrice)) {
            hp = data?.markPrice;
          } else {
            hp = data.takeprofit && data.takeprofit.price;
          }
          hp = parseFloat(hp);
          lp = parseFloat(lp);
          console.log(lp, "-----lp");
          console.log(hp, "-----hp");
          if (lp > 0 && hp > 0) {
            let priceScale = tvWidget
              .activeChart()
              .getPanes()[0]
              .getRightPriceScales()[0];

            let a = priceScale.setVisiblePriceRange({
              from: toFixedDown(lp - (lp * 2) / 100, data?.priceDecimal),
              to: toFixedDown(hp + (hp * 2) / 100, data?.priceDecimal),
              // to:
              //   avgprice +
              //   (avgprice *
              //     (parseFloat(data?.takeprofit?.takeprofitRatio) + 2)) /
              //     100,
            });
          }
        }
        tvWidget.headerReady().then(() => {
          if (data && data.takeprofit && data.takeprofit.price > 0) {
            // console.log("250----", data);

            // tvWidget.activeChart().createOrderLine().remove()
            if (tpOrderLine.current) {
              tpOrderLine.current.remove();
            }
            tpOrderLine.current = tvWidget
              .chart()
              .createOrderLine()
              .setLineLength(2)
              .setText("Take Profit")
              .setQuantity(toFixedDown(data.takeprofit.amount, 2))
              .setPrice(data.takeprofit.price)
              .setLineWidth(2);
            // tpOrderLine.setPrice(data.takeprofit)
          } else {
            // console.log("265----");
            // Remove the Take Profit line when the condition is not met
            if (tpOrderLine.current) {
              tpOrderLine.current.remove();
              tpOrderLine.current = null; // Reset the reference
            }
          }

          if (data && data.avgCall && data.avgCall.length > 0) {
            console.log("313---", data);

            if (tvWidget !== null) {
              tvWidget.onChartReady(() => {
                tvWidget.headerReady().then(() => {
                  // tvWidget.activeChart().createOrderLine().remove()
                  // Remove existing order lines before creating new ones

                  orderLines.current.forEach((line) =>
                    tvWidget.chart().removeEntity(line)
                  );

                  tvWidget.activeChart().removeEntity();

                  orderLines.current = [];

                  // Check if data and data.avgCall are defined
                  data.avgCall.forEach((item) => {
                    console.log(item.quantity, "-----321");
                    const lineColor = "rgb(22, 112, 72)";
                    let hlprop = {
                      shape: "horizontal_line",
                      Text: true,
                      overrides: {
                        linecolor: lineColor,
                        fillBackground: false,
                        linewidth: 2,
                        price_note: false,
                        showPrice: false,
                        showLabel: true,
                        vertLabelsAlign: "middle",
                        horzLabelsAlign: "right",
                        textcolor: "#ffffff",
                      },
                      lock: true,
                      disableSelection: true,
                      disableSave: true,
                      disableUndo: true,
                      text: `${toFixedDown(item.quantity, 2)} ${item.currency}`,
                    };
                    const lineCoordinates = {
                      price: item.price,
                    };
                    const orderLine = tvWidget
                      .activeChart()
                      .createShape(lineCoordinates, hlprop);
                    orderLines.current.push(orderLine);
                  });
                  // old method
                  // Check if data and data.avgCall are defined
                  // data.avgCall.forEach((item) => {
                  //   console.log(item.quantity, "---item.quantit");
                  //   const orderLine = tvWidget
                  //     .chart()
                  //     .createOrderLine()
                  //     .setLineStyle(0)
                  //     .setQuantityBackgroundColor("transparent")
                  //     .setBodyBorderColor("transparent")
                  //     .setQuantityBorderColor("transparent")
                  //     .setBodyTextColor("rgb(0, 106, 78)")
                  //     .setLineColor("rgb(0, 106, 78)")
                  //     .setText("")
                  //     .setLineWidth(2)
                  //     .setQuantity(
                  //       `${toFixedDown(item.quantity, 2)} ${item.currency}`
                  //     )
                  //     .setPrice(item.price);
                  //   orderLines.current.push(orderLine);
                  // });

                  console.log("308---", orderLines.current);
                });
              });
            }
          } else {
            //orderLines.current.forEach((line) => line.remove());
            orderLines.current.forEach((line) =>
              tvWidget.chart().removeEntity(line)
            );
            orderLines.current = [];
          }

          if (data && data.avgPrice && data.avgPrice?.avgPrice) {
            if (tvWidget !== null) {
              tvWidget.onChartReady(() => {
                tvWidget.headerReady().then(() => {
                  // tvWidget.activeChart().createOrderLine().remove()
                  if (dcaOrderLine.current) {
                    dcaOrderLine.current.remove();
                  }
                  dcaOrderLine.current = tvWidget
                    .chart()
                    .createOrderLine()
                    .setExtendLeft(false)
                    .setLineStyle(0)
                    .setLineLength(100)
                    .setText("DCA")
                    .setLineWidth(2)
                    .setQuantity(toFixedDown(data.avgPrice?.amount, 5))
                    .setPrice(data.avgPrice?.avgPrice);
                });
              });
            }
          } else {
            // Remove the Take Profit line when the condition is not met
            if (dcaOrderLine.current) {
              dcaOrderLine.current.remove();
              dcaOrderLine.current = null; // Reset the reference
            }
          }

          if (
            data &&
            data.stoploss &&
            data.stoploss?.percentage > 0 &&
            data.stoploss?.price > 0
          ) {
            if (tvWidget !== null) {
              tvWidget.onChartReady(() => {
                tvWidget.headerReady().then(() => {
                  if (slOrderLine.current) {
                    slOrderLine.current.remove();
                  }
                  slOrderLine.current = tvWidget
                    .chart()
                    .createOrderLine()
                    .setQuantityBackgroundColor("rgb(200, 7, 7)")
                    .setBodyBorderColor("rgb(200, 7, 7)")
                    .setQuantityBorderColor("rgb(200, 7, 7)")
                    .setBodyTextColor("rgb(200, 7, 7)")
                    .setLineColor("rgb(200, 7, 7)")
                    .setLineLength(3)
                    .setText("Stop Loss")
                    .setLineWidth(2)
                    .setQuantity(toFixedDown(data.stoploss.amount, 2))
                    .setPrice(data.stoploss.price);
                });
              });
            }
          } else {
            // Remove the Take Profit line when the condition is not met
            if (slOrderLine.current) {
              slOrderLine.current.remove();
              slOrderLine.current = null; // Reset the reference
            }
          }

          flagsLine.current.forEach((line) =>
            tvWidget.chart().removeEntity(line)
          );
          if (data && data.flagData && data.flagData.length > 0) {
            if (tvWidget !== null) {
              tvWidget.onChartReady(() => {
                // const newTimeZone = "Asia/Kolkata"; // Set your custom time zone
                // tvWidget.chart().setTimezone(newTimeZone);

                tvWidget.headerReady().then(() => {
                  flagsLine.current.forEach((line) =>
                    tvWidget.chart().removeEntity(line)
                  );

                  tvWidget.activeChart().removeEntity();

                  flagsLine.current = [];
                  // flagsLine.current = [];

                  let range = tvWidget.activeChart().getVisibleRange();
                  let minVisibleTime = range.from;
                  let maxVisibleTime = range.to;

                  data.flagData.forEach((item) => {
                    // console.log(
                    //   minVisibleTime,
                    //   maxVisibleTime,
                    //   converttime(item.createdAt),
                    //   "minVisibleTimeminVisibleTimeminVisibleTimeminVisibleTime"
                    // );

                    let circleCoordinates = {
                      time: converttime(item.createdAt),
                      price: item.price, // Set the y-coordinate based on the price where you want the circle
                    };

                    const circleProperties = {
                      shape: "icon",
                      overrides: {
                        color: item.buyorsell == "buy" ? "#039345" : "red",
                        size: 20,
                      },
                      lock: true,
                      disableSelection: true,
                      disableSave: true,
                      disableUndo: true,
                      icon: 0xf192,
                    };
                    if (
                      converttime(item.createdAt) >= minVisibleTime &&
                      converttime(item.createdAt) <= maxVisibleTime
                    ) {
                      const flag = tvWidget
                        .chart()
                        .createShape(circleCoordinates, circleProperties);
                      flagsLine.current.push(flag);
                    }
                  });
                });

                tvWidget
                  .chart()
                  .onDataLoaded()
                  .subscribe(null, function (interval, obj) {
                    let range = tvWidget.activeChart().getVisibleRange();
                    let minVisibleTime = range.from;
                    let maxVisibleTime = range.to;
                    data.flagData.forEach((item) => {
                      // console.log("circle1111", momentFormat(item.createdAt));

                      let circleCoordinates = {
                        time: converttime(item.createdAt),
                        price: item.price, // Set the y-coordinate based on the price where you want the circle
                      };

                      const circleProperties = {
                        shape: "icon",
                        overrides: {
                          color: item.buyorsell == "buy" ? "#039345" : "red",
                          size: 20,
                        },
                        lock: true,
                        disableSelection: true,
                        disableSave: true,
                        disableUndo: true,
                        icon: 0xf192,
                      };

                      if (
                        converttime(item.createdAt) >= minVisibleTime &&
                        converttime(item.createdAt) <= maxVisibleTime
                      ) {
                        const flag = tvWidget
                          .chart()
                          .createShape(circleCoordinates, circleProperties);
                        flagsLine.current.push(flag);
                      }
                    });
                  });
              });
            }
          } else {
            // console.log("----t375");

            if (flagsLine.current) {
              // tvWidget.activeChart().removeAllShapes();
              flagsLine.current.forEach((line) =>
                tvWidget.chart().removeEntity(line)
              );
              // tvWidget.chart().clearMarks();
              flagsLine.current = []; // Reset the reference
            } else {
              // console.log("vvvv----t375");
            }
          }

          // console.log(tpOrderLine, "------tpOrderLine");
          // console.log(orderLines, "------orderLines");
          // console.log(dcaOrderLine, "------dcaOrderLine");
        });
      });
    }
  }, [data, tvWidget]);

  useEffect(() => {
    if (isEmpty(botId.positionId)) {
      if (tvWidget !== null) {
        tvWidget.onChartReady(() => {
          // const newTimeZone = "Asia/Kolkata"; // Set your custom time zone
          // tvWidget.chart().setTimezone(newTimeZone);

          tvWidget.headerReady().then(() => {
            flagsLine.current.forEach((line) =>
              tvWidget.chart().removeEntity(line)
            );

            tvWidget.activeChart().removeEntity();

            flagsLine.current = [];
          });

          tvWidget
            .chart()
            .onDataLoaded()
            .subscribe(null, function (interval, obj) {
              flagsLine.current.forEach((line) =>
                tvWidget.chart().removeEntity(line)
              );
              tvWidget.activeChart().removeEntity();
              flagsLine.current = [];
            });
        });
      }
    }
  }, [botId, tvWidget]);
  return (
    // <div
    //   id={props.containerId}
    //   className={"chartcontainer"}
    //   style={{ fontSize: "2px" }}
    // />

    <div
      style={{ height: "500px" }}
      ref={chartContainerRef}
      className={"TVChartContainer"}
    />
  );
};

Chart.propTypes = {
  symbol: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  datafeedUrl: PropTypes.string.isRequired,
  libraryPath: PropTypes.string.isRequired,
  chartsStorageUrl: PropTypes.string.isRequired,
  chartsStorageApiVersion: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  fullscreen: PropTypes.string.isRequired,
  autosize: PropTypes.string.isRequired,
  studiesOverrides: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  pair: PropTypes.string.isRequired,
};

Chart.defaultProps = {
  symbol: "BTCUSDT",
  interval: "1",
  containerId: "tv_chart_container",
  datafeedUrl: chartUrl + "/api/chart",
  libraryPath: "/charting_library/", //live
  chartsStorageUrl: "",
  chartsStorageApiVersion: "1.1",
  clientId: "tradingview.com",
  userId: "public_user_id",
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
  style: 2,
  has_no_volume: false,
  indicators: "volume",

  time_frames: [
    {
      text: "3M",
      resolution: "720",
      description: "3 months in 12 hours intervals",
      title: "3m",
    },

    {
      text: "1M",
      resolution: "360",
      description: "1 month in 6 hours intervals",
      title: "1m",
    },

    {
      text: "7d",
      resolution: "60",
      description: "7 days in 1 hour intervals",
      title: "7d",
    },

    {
      text: "3d",
      resolution: "30",
      description: "3 days in 30 minutes intervals",
      title: "3d",
    },

    {
      text: "1d",
      resolution: "15",
      description: "1 day in 15 minutes intervals",
      title: "1d",
    },

    {
      text: "6h",
      resolution: "5",
      description: "6 hours in 5 minutes intervals",
      title: "6h",
    },

    {
      text: "1h",
      resolution: "1",
      description: "1 hour in 1 minute intervals",
      title: "1h",
    },
  ],
};

const timeZoneGet = (props) => {
  let dtf = new Intl.DateTimeFormat();
  let options = dtf.resolvedOptions();
  let timeZone = options.timeZone;
  return timeZone;
};
export default Chart;
