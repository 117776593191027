import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import Dropdown from "react-bootstrap/Dropdown";
import binance from "../../assets/images/binance.svg";
import bitmart from "../../assets/images/bitmart.png";
import bybit from "../../assets/images/bybit.png";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import { dateTimeFormat } from "../../lib/dateFilter";
import isEmpty from "../../lib/isEmpty";
import { firstCapital } from "../../lib/String";
import { getTradeHistoryAll } from "../../actions/Bottrade";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function History() {
  const historyColumns = [
    {
      name: "Exchange",
      selector: (row) => row.exchange,
      width: "130px",
    },
    {
      name: "Assets",
      selector: (row) => `${row.firstCurrency} / ${row.secondCurrency}`,
    },
    {
      name: "Created Date",
      selector: (row) => dateTimeFormat(row.createdAt),
      width: "170px",
    },
    {
      name: "Side",
      selector: (row) => (
        <span className={row.buyorsell == "buy" ? "greenTxt" : "redText"}>
          {row.orderVia == "clearSell"
            ? "Clear Sell"
            : row.buyorsell.toUpperCase()}
        </span>
      ),
      width: "80px",
    },
    {
      name: "Trade Amount",
      selector: (row) => toFixedDown(row.price * row.quantity, 8),
      width: "150px",
    },
    {
      name: "Price",
      selector: (row) => toFixedDown(row.price, row.priceDecimal),
    },
    {
      name: "Quantity",
      selector: (row) => {
        // let qty = row.quantity;
        // if (row.botCategory == "manual" && row.buyorsell == "buy") {
        //   qty = row.orgquantity;
        // }
        // console.log(qty, "-------66", row);
        return <span className="">{row.orgquantity}</span>;
      },
    },

    {
      name: "Bot",
      selector: (row) => (
        <span className="">
          {row.botCategory == "manual"
            ? "Signal"
            : row?.botCategory?.toUpperCase()}
        </span>
      ),
    },

    {
      name: "Status",
      selector: (row) => (
        <span className="greenTxt">
          {row.status == "cancel" ? firstCapital(row.status) : "Completed"}
        </span>
      ),
    },
  ];

  const [selectedValue, setSelectedValue] = useState("Binance");
  const [historyData, setHistoryData] = useState();
  const [hData, setHData] = useState();
  const [pair, setPair] = useState();

  const handleSelect = (eventKey, event) => {
    if (eventKey != "All") {
      setSelectedValue(eventKey);

      fetchHistoryDetail(pair, eventKey);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setPair(value);
    // fetchHistoryDetail(value, selectedValue);
    var searcharr = [];
    if (hData && hData.length > 0) {
      for (var i = 0; i < hData.length; i++) {
        var temppair = hData[i].pairName.toLowerCase();
        if (temppair.startsWith(value.toLowerCase())) {
          searcharr.push(hData[i]);
        }
        if (i == hData.length - 1) {
          setHistoryData(searcharr);
        }
      }
    }
  };

  const fetchHistoryDetail = async (pair = "", exchange = "") => {
    if (exchange != "All") {
      try {
        let reqData = {
          botType: "Genie",
          exchange: exchange,
          pair: !isEmpty(pair) ? pair.toUpperCase() : pair,
        };

        const { status, loading, result, message, error } =
          await getTradeHistoryAll(reqData);
        if (status == "success") {
          setHistoryData(result);
          setHData(result);
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    fetchHistoryDetail("", selectedValue);
  }, []);

  return (
    <>
      <div className="abv_flx justify-content-between">
        <div className="d-lg-flex gap-2 align-items-center">
          <div className="drpdwn_box">
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle id="dropdown-basic">
                <span className="exchange">Exchange</span>
                {selectedValue}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="All">
                  {/* <img src={binance} class="img-fluid crypto me-2" alt="icon" /> */}
                  Select Exchange
                </Dropdown.Item>
                <Dropdown.Item eventKey="Binance">
                  <img src={binance} class="img-fluid crypto me-2" alt="icon" />
                  Binance
                </Dropdown.Item>

                <Dropdown.Item eventKey="Bybit">
                  <img src={bybit} class="img-fluid crypto me-2" alt="icon" />
                  bybit
                </Dropdown.Item>

                <Dropdown.Item eventKey="BitMart">
                  <img src={bitmart} class="img-fluid crypto me-2" alt="icon" />
                  BitMart
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="inputgrp_box">
            <div class="input-group">
              <span class="input-group-text pe-0" id="basic-addon1">
                <img
                  src={require("../../assets/images/search.png")}
                  alt="Icon"
                  className="img-fluid search"
                />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search assets"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={handleChange}
                name="pair"
                value={pair}
              />
            </div>
          </div>
        </div>
        {/* <div>
          <button className="primary_btn export_btn locked">
            <img
              src={require("../../assets/images/lock.png")}
              class="img-fluid me-2"
              alt="icon"
            />
            <span>Export CSV</span>
          </button>
        </div> */}
      </div>
      <div className="table_sec table_sec_chg">
        <DataTable columns={historyColumns} data={historyData} pagination />
      </div>
    </>
  );
}
