import React, { useEffect, useState, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Dropdown from "react-bootstrap/Dropdown";
// import action
import { getpairs, setBotStatus } from "../../actions/Bottrade";

// import lib
import { toFixed, toFixedDown } from "../../lib/roundOf";

// import context
import SocketContext from "../Context/SocketContext";
import isEmpty from "../../lib/isEmpty";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function TradePairs(props) {
  const socketContext = useContext(SocketContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { params, updatePair, pairsAllTrade } = props;
  const { bot, pair, exchange } = params;
  let botType = bot == "dca" ? "Genie" : "Grid";
  const [PairListDataTr, setPairListDataTr] = useState();
  const [PairListTr, setPairListTr] = useState();
  const [hideBal, setHideBal] = useState(false);
  const [searchby, setsearchby] = useState("USDT");
  let data = params.pair.split("-");
  let pairsl = data[0] + "/" + data[1];
  const [searchbyinput, setsearchbyinput] = useState(pairsl);
  const [sortby, Setsortby] = useState("asc");
  const [hidedrop, Sethidedrop] = useState(false); // redux
  // redux
  const botData = useSelector((state) => state.botStatus);
  useEffect(() => {
    if (!isEmpty(params.pair)) {
      let data = params.pair.split("-");
      let pairsl = data[0] + "/" + data[1];
      setsearchbyinput(pairsl);
    }
  }, [params.pair]);
  const splitData = (value) => {
    let data = value.split("-");
    return data[0] + data[1];
  };
  useEffect(() => {
    console.log(pairsAllTrade, "-------47");
    setPairListDataTr(pairsAllTrade);
    setPairListTr(pairsAllTrade);
  }, [pairsAllTrade]);
  useEffect(() => {
    let socketTicker = "socketTicker" + params.exchange;

    // console.log(hidedrop, "hidedrophidedrop");
    // if (hidedrop) {
    //   let pairData = params.pair.split("-");
    //   setsearchby(pairData[1]);
    //   let socketTicker = "socketTicker" + params.exchange;
    //   socketContext[socketTicker].on("marketPrice", (result) => {
    //     setPairList((el) => {
    //       let pairList = [];
    //       if (el) {
    //         el.map((item) => {
    //           if (item._id == result.pairId) {
    //             let returnrate_per =
    //               ((result.data.markPrice * item.Orders.quantity -
    //                 item.Orders.avgprice * item.Orders.quantity) /
    //                 (item.Orders.avgprice * item.Orders.quantity)) *
    //               100;

    //             let profit =
    //               result.data.markPrice * item.Orders.quantity -
    //               item.Orders.avgprice * item.Orders.quantity;
    //             pairList.push({
    //               ...item,

    //               Orders: {
    //                 ...item.Orders,
    //                 profit: profit ? toFixed(profit, 4) : "-",
    //                 returnrate_per: returnrate_per ? returnrate_per : 0.0,
    //               },
    //               // markPrice: toFixedDown(result.data.markPrice, 8),
    //               markPrice: result.data.markPrice,
    //               change: toFixedDown(result.data.change, 3),
    //             });
    //           } else {
    //             pairList.push(item);
    //           }
    //         });
    //       }
    //       return pairList;
    //     });
    //   });
    // } else {
    //   return () => {
    //     socketContext.socket.off("marketPrice");
    //   };
    // }
    // return () => {
    //   socketContext[socketTicker].off("marketPrice");
    // };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("hideBalance") == "true") {
      setHideBal(true);
    }
  }, [hideBal]);

  const gotoTrade = async (pair) => {
    dispatch(
      setBotStatus({
        status: false,
        crtBot: true,
        openStatus: true,
        pair: pair,
      })
    );

    if (pair != params.pair) {
      Sethidedrop(false);

      const url = `/trade/${pair}/${params.exchange}/${params.bot}`;
      updatePair({
        exchange: params.exchange,
        bot: params.bot,
        pair: pair,
      });

      navigate(url);
    }

    // window.location.href = url;
  };

  const searchpair = (e) => {
    e.preventDefault();
    setsearchbyinput(e.target.value);
    var searcharr = [];
    if (PairListDataTr && PairListDataTr.length > 0) {
      for (var i = 0; i < PairListDataTr.length; i++) {
        var temppair =
          PairListDataTr[i].firstCurrencySymbol +
          "/" +
          PairListDataTr[i].secondCurrencySymbol;
        if (temppair.startsWith(e.target.value.toUpperCase())) {
          searcharr.push(PairListDataTr[i]);
        }
        if (i == PairListDataTr.length - 1) {
          setPairListTr(searcharr);
        }
      }
    }
  };

  const handleDroptoggle = async () => {
    Sethidedrop(!hidedrop);
    let socketTicker = "socketTicker" + params.exchange;

    if (!hidedrop == true) {
      let pairData = params.pair.split("-");
      setsearchby(pairData[1]);
      socketContext[socketTicker].on("marketPrice", (result) => {
        setPairListTr((el) => {
          let pairList = [];
          if (el) {
            el.map((item) => {
              if (item._id == result.pairId) {
                pairList.push({
                  ...item,

                  Orders: {
                    ...item.Orders,
                  },
                  // markPrice: toFixedDown(result.data.markPrice, 8),
                  markPrice: result.data.markPrice,
                  change: toFixed(result.data.change, 4),
                });
              } else {
                pairList.push(item);
              }
            });
          }
          return pairList;
        });
      });
    } else {
      console.log(socketContext, "----socketTicker");

      // socketContext[socketTicker].off("marketPrice");
    }
  };

  return (
    <div className="coin_details">
      {/* <div className="card trade_card"> */}
      <Dropdown className="drp_dn" show={hidedrop}>
        <Dropdown.Toggle
          id="dropdown-basic"
          onClick={() => handleDroptoggle()}
          // onClick={() => Sethidedrop(!hidedrop)}
        >
          <div className="pair_dropdown cursor-pointer">
            <h2>Pairs</h2>
            <p className="mb-0">{searchbyinput}</p>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="make_drpdwn">
            <div className="search_inp_div">
              <input
                type="text"
                placeholder="Search Pair"
                className="form-control search_inp"
                name="symbol"
                value={searchbyinput}
                onChange={searchpair}
              />
              <i className="bi bi-search"></i>
            </div>

            <div
              className="tab-pane fade show active"
              id="coin_one"
              role="tabpanel"
              aria-labelledby="coin_one-tab"
              // onClick={() => toggle()}
            >
              <div className="pair_table">
                <div className="pair_table_head">
                  <div>Pair</div>
                  {/* <div onClick={() => OnclicksortBy("quantity")}>Pair</div> */}

                  {/* <div onClick={() => OnclicksortBy("quantity")}>Pair</div> */}
                  {/* <div onClick={() => OnclicksortBy("markPrice")}>
                    Direction
                  </div> */}
                  {/* <div onClick={() => OnclicksortBy("profit")}>Profit</div> */}
                  <div>Price</div>
                  <div>24H change</div>
                </div>
                <div className="pair_table_body">
                  <Scrollbars
                    style={{ height: 288 }}
                    renderTrackVertical={({ style, ...props }) => (
                      <div
                        className="track_vertical"
                        {...props}
                        style={{
                          ...style,
                          right: "2px",
                          bottom: "2px",
                          top: "2px",
                          borderRadius: "10px",
                          width: "3px",
                        }}
                      />
                    )}
                    renderThumbVertical={({ style, ...props }) => (
                      <div
                        className="thumb_vertical"
                        {...props}
                        style={{
                          ...style,
                          width: "2px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                        }}
                      />
                    )}
                  >
                    {PairListTr &&
                      PairListTr.length > 0 &&
                      PairListTr.map((item, key) => {
                        let cond = item.BotType.find(
                          (el) =>
                            el == botType && item.exchange == params.exchange
                        );

                        if (
                          cond &&
                          searchby == item.secondCurrencySymbol &&
                          !hideBal
                        ) {
                          return (
                            <>
                              <div
                                onClick={() =>
                                  gotoTrade(
                                    item.firstCurrencySymbol +
                                      "-" +
                                      item.secondCurrencySymbol
                                  )
                                }
                                className={
                                  item.firstCurrencySymbol +
                                    "-" +
                                    item.secondCurrencySymbol ==
                                  params.pair
                                    ? "pair_table_bodyrow active"
                                    : "pair_table_bodyrow"
                                }
                              >
                                <div className="pair_table_bodyrow_top">
                                  <div>
                                    <h3>
                                      {item.firstCurrencySymbol}
                                      <span>/ {item.secondCurrencySymbol}</span>
                                    </h3>
                                  </div>
                                </div>
                                <div className="pair_table_bodyrow_top">
                                  <small>
                                    {/* {toFixed(item.markPrice, item.priceDecimal)} */}
                                    {parseFloat(item.markPrice)}
                                  </small>
                                </div>

                                <div className="pair_table_bodyrow_bottom">
                                  <div className="pair_table_bodyrow_bottom_right">
                                    <div>
                                      <span>
                                        <small
                                          className={
                                            item.change > 0
                                              ? "greenTxt d-flex"
                                              : "redText d-flex"
                                          }
                                        >
                                          <span
                                            className={
                                              item.change > 0
                                                ? "pair_uparrow"
                                                : "pair_downarrow"
                                            }
                                          >
                                            <svg viewBox="0 0 24 24">
                                              <g>
                                                <path d="M12 8V4l8 8-8 8v-4H4V8z"></path>
                                              </g>
                                            </svg>
                                          </span>
                                          {toFixedDown(item.change, 3)}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
