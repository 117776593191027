import { useSelector } from "react-redux";
import { toFixed, toFixedDown } from "../../lib/roundOf";

export default function BotDetailSettings(props) {
  // props
  const { params, FeeandDecimal } = props;
  const { pair } = params;

  // redux
  const botData = useSelector((state) => state.curGridBot);

  return (
    <div>
      <div className="mb-2">
        <div className="bot_preview_box">
          <div>
            <span>Exchange</span>
            <span className="border_line"></span>
            <span>{botData.exchange}</span>
          </div>
          <div>
            <span>Pair</span>
            <span className="border_line"></span>
            <span>
              {botData?.firstCurrency} / {botData?.secondCurrency}
            </span>
          </div>
          <div>
            <span>Investment</span>
            <span className="border_line"></span>
            <span>{botData?.invt + " " + botData?.secondCurrency}</span>
          </div>
          <div>
            <span>Profit currency</span>
            <span className="border_line"></span>
            <span>USDT</span>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div className="bot_preview_box">
          <div>
            <span>Sell orders</span>
            <span className="border_line"></span>{" "}
            <span>
              {botData.sellInvt} {botData?.firstCurrency}
            </span>
          </div>
          <div>
            <span>Buy orders</span>
            <span className="border_line"></span>{" "}
            <span>
              {toFixed(botData.buyInvt, FeeandDecimal?.priceDecimal)}{" "}
              {botData?.secondCurrency}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-2">
        <div className="bot_preview_box">
          <div>
            <span>High price</span>
            <span className="border_line"></span>
            <span>{botData.highPrice}</span>
          </div>
          <div>
            <span>Low price</span>
            <span className="border_line"></span>
            <span>{botData.lowPrice}</span>
          </div>
          <div>
            <span>Grid levels</span>
            <span className="border_line"></span>
            <span>{botData.level}</span>
          </div>
          <div>
            <span>Grid step</span>
            <span className="border_line"></span>
            <span>{toFixedDown(botData.prc, 2)}%</span>
          </div>
          {botData.stopLoss > 0 && (
            <div>
              <span>Stop Loss</span>
              <span className="border_line"></span>
              <span>{botData.stopLoss}</span>
            </div>
          )}{" "}
          {botData.tpRatio > 0 && (
            <div>
              <span>Take Profit</span>
              <span className="border_line"></span>
              <span>{botData.tpRatio}%</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
