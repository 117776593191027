// import package
import moment from "moment";

// import lib
import isEmpty from "./isEmpty";

export const dateTimeFormat = (dateTime, format = "YYYY-MM-DD HH:mm") => {
  try {
    if (!isEmpty(dateTime)) {
      let newDateTime = new Date(dateTime);
      if (format.includes("YYYY")) {
        format = format.replace("YYYY", newDateTime.getFullYear());
      }

      if (format.includes("MM")) {
        let month = newDateTime.getMonth() + 1;
        month = month > 9 ? month : `0${month}`;
        format = format.replace("MM", month);
      }

      if (format.includes("DD")) {
        let date = newDateTime.getDate();
        date = date > 9 ? date : `0${date}`;
        format = format.replace("DD", date);
      }

      if (format.includes("HH")) {
        let hour = newDateTime.getHours();
        hour = hour > 9 ? hour : `0${hour}`;
        format = format.replace("HH", hour);
      }

      if (format.includes("mm")) {
        let minute = newDateTime.getMinutes();
        minute = minute > 9 ? minute : `0${minute}`;
        format = format.replace("mm", minute);
      }

      return format;
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

export const MonthDateFormat = (monthDate, format = "DD MM") => {
  try {
    if (!isEmpty(monthDate)) {
      let newMonthDate = new Date(monthDate);

      if (format.includes("MM")) {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        let month = monthNames[newMonthDate.getMonth()];
        format = format.replace("MM", month);
      }

      if (format.includes("DD")) {
        let date = newMonthDate.getDate();
        date = date > 9 ? date : `0${date}`;
        format = format.replace("DD", date);
      }
      return format;
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

// Example usage:
const formattedDate = MonthDateFormat("2023-12-02", "DD MM");
console.log(formattedDate); // Output: "02 December"

export const momentFormat = (dateTime, format = "YYYY-MM-DD HH:mm") => {
  try {
    if (!isEmpty(dateTime)) {
      // let newDateTime = new Date(dateTime);
      // return moment(newDateTime).format(format)
      //   let now = new Date(dateTime);
      const now = moment(dateTime); // Create a Moment object representing the current time
      const desiredUtcOffset = 0; // For example, setting UTC offset to -4 hours (240 minutes)

      now.utcOffset(desiredUtcOffset); // Set the UTC offset on the Moment object
      return now.unix();
      // return moment(now).format(format);
    }
    return "";
  } catch (err) {
    console.log(err, "--------------67");
    return "";
  }
};

export const dateHours = (dateTime) => {
  try {
    const startDate = new Date(dateTime);
    const endDate = new Date();
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(timeDiff / (1000 * 60 * 60));
    const diffMinutes = Math.floor(timeDiff / (1000 * 60));
    let formattedDuration = {};
    if (diffYears > 0) {
      formattedDuration.years = diffYears;
    }
    if (diffMonths >= 12) {
      formattedDuration.years = Math.floor(diffMonths / 12);
      formattedDuration.months = diffMonths % 12;
    } else {
      formattedDuration.months = diffMonths;
    }

    formattedDuration.days = diffDays % 30;
    formattedDuration.hours = diffHours % 24;
    formattedDuration.minutes = diffMinutes % 60;
    return formatDuration(formattedDuration);
  } catch (err) {
    return "";
  }
};

export const dateHoursclose = (dateTime, endTime) => {
  try {
    const startDate = new Date(dateTime);
    const endDate = new Date(endTime);

    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffYears = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30));
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(timeDiff / (1000 * 60 * 60));
    const diffMinutes = Math.floor(timeDiff / (1000 * 60));
    let formattedDuration = {};
    if (diffYears > 0) {
      formattedDuration.years = diffYears;
    }
    if (diffMonths >= 12) {
      formattedDuration.years = Math.floor(diffMonths / 12);
      formattedDuration.months = diffMonths % 12;
    } else {
      formattedDuration.months = diffMonths;
    }

    formattedDuration.days = diffDays % 30;
    formattedDuration.hours = diffHours % 24;
    formattedDuration.minutes = diffMinutes % 60;
    return formatDuration(formattedDuration);
  } catch (err) {
    return "";
  }
};

export const dateHourswithTime = (dateTime) => {
  try {
    const date = new Date(dateTime);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
    }).format(date);

    return formattedDate;
  } catch (err) {
    return "";
  }
};

export const dayOnly = (date1, date2) => {
  try {
    date1 = new Date(date1);
    date2 = new Date(date2);

    // Convert both dates to milliseconds since the Unix epoch
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    // Calculate the difference in milliseconds
    const timeDiff = Math.abs(time2 - time1);

    // Convert the difference to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  } catch (err) {
    return "";
  }
};

export const startDate = (date) => {
  try {
    console.log(date, "--------172");
    let tDate = new Date(date);
    tDate = tDate.toISOString().split("T")[0] + "T00:00:00.000Z";
    return tDate;
  } catch (err) {
    return "";
  }
};

export const endDate = (date) => {
  try {
    let tDate = new Date(date);
    tDate = tDate.toISOString().split("T")[0] + "T23:59:59.000Z";
    return tDate;
  } catch (err) {
    return "";
  }
};

function formatDuration(duration) {
  let formattedString = "";

  if (duration.years) {
    formattedString += `${duration.years}y `;
  }

  if (duration.months) {
    formattedString += `${duration.months}m `;
  }

  if (duration.days) {
    formattedString += `${duration.days}d `;
  }

  if (duration.hours) {
    formattedString += `${duration.hours}h `;
  }

  if (duration.minutes >= 0) {
    formattedString += `${duration.minutes}min `;
  }

  return formattedString.trim();
}
