import React, { Fragment, useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal } from "react-bootstrap";
import ShareModal from "../ShareModal";
import { toFixed, toFixedDown } from "../../lib/roundOf";
import $ from "jquery";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { firstCapital, firstOnCap } from "../../lib/String";
import {
  dateHours,
  dateHourswithTime,
  dateHoursclose,
  dateTimeFormat,
} from "../../lib/dateFilter";
import validation from "./validation";
import { useDispatch, useSelector } from "react-redux";
import {
  comManSellOrder,
  getOpenOrders,
  getTotQuant,
  getTradeHistory,
  setBotStatus,
  setBotType,
  setBotId,
} from "../../actions/Bottrade";
import SocketContext from "../Context/SocketContext";
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from "../../lib/isEmpty";
import { useNavigate } from "react-router-dom";

const buySell = {
  buyAmount: 0,
  sellAmount: 0,
  orgPairName: "",
  firstCoin: "",
};

export default function TradeHistory(props) {
  const { botType, pair, exchange, updateChartData, socketFetch } = props;
  const socketContext = useContext(SocketContext);
  const navigate = useNavigate();

  // state
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [historyData, setHistoryData] = useState();
  const [totalQuant, setTotQuant] = useState(0);
  const [sellLoader, setSellLoader] = useState(false);
  const [historyStatus, setHistoryStatus] = useState(true);
  const [BSForm, setBSForm] = useState(buySell);
  const [posPerc, setPosPerc] = useState(100);
  const [validateError, setValidateError] = useState({});
  const [totCurValue, setTotCurrent] = useState(0);
  const [sumProfit, setSumprofit] = useState({ sumpnl: 0, sumpnlval: 0 });
  const [sumProfitHistory, setSumprofitHistory] = useState({
    sumpnl: 0,
    sumpnlval: 0,
    curVal: 0,
    inv: 0,
  });

  const { buyAmount, sellAmount, orgPairName, firstCoin } = BSForm;
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [popperPlacement, setPopperPlacement] = useState("left-end");

  // redux
  const botData = useSelector((state) => state.botStatus);
  const queryParams = new URLSearchParams(window.location.search);
  const ctab = queryParams.get("tb");
  const customStyles = {
    rows: {
      style: {
        backgroundColor: "rgba(0, 0, 0, .05)",
      },
    },
    headRow: {
      style: {
        backgroundColor: "rgba(0, 0, 0, .1)",
      },
    },
    pagination: {
      style: {
        backgroundColor: "rgba(0, 0, 0, .05)",
      },
    },
  };
  // Spot History Table
  const spotColumns = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type" onClick={() => handleBotRow(row)}>
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      width: "120px",
      selector: (row) => {
        return (
          <div onClick={() => handleBotRow(row)}>
            <p className="mb-2">{`${row.pairName
              .split("USDT")
              .join("/USDT")}`}</p>
            <div className="table_pairtype">
              <p className="mb-0">DCA</p>
              <p className="mb-0">Long</p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Current value",
      width: "140px",

      selector: (row) => {
        return (
          <p className="mb-0" onClick={() => handleBotRow(row)}>
            {toFixed(row.currentvalue, 4)}{" "}
            <span className="greyTxt">{row.secondCurrency}</span>
          </p>
        );
      },
    },
    // {
    //   name: "Bot profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Avg. daily profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Total PNL",
      width: "140px",

      selector: (row) => {
        return (
          <div onClick={() => handleBotRow(row)}>
            <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
              {toFixedDown(row.pl, 2)} %
            </p>
            <p className="mb-0">
              {toFixedDown(row.pnlval, 4)} <span className="greyTxt">USDT</span>
            </p>
          </div>
        );
      },
    },
    {
      name: "Txns / Time",

      selector: (row) => {
        return (
          <div onClick={() => handleBotRow(row)}>
            <p className="mb-2">{row?.filled?.length}</p>
            <p className="mb-0 greyTxt">{dateHours(row.createdAt)}</p>
          </div>
        );
      },
    },
    {
      name: "Investment",

      selector: (row) => {
        return (
          <div>
            <p className="mb-2">{toFixed(row.investment, 4)}</p>
          </div>
        );
      },
    },
    {
      name: (
        <div>
          <p className="mb-0">
            Status{" "}
            {/* <i
              className="bi bi-info-circle-fill"
              data-tooltip-id="app-title"
            ></i>{" "} */}
          </p>
          <ReactTooltip id="app-title" className="custom_tooltip">
            <div>
              <ul>
                <li>
                  <small>TP</small> - Take profit enabled
                </li>
                <li>
                  <small>SL</small> - Stop loss enabled
                </li>
                <li>
                  <small>T</small> - Trailing Stop loss enabled
                </li>
                <li>
                  <small>TU</small> - Trailing up enabled
                </li>
                <li>
                  <small>TD</small> - Trailing down enabled
                </li>
                <li>
                  <small>TTP</small> - Trailing TP enabled
                </li>
              </ul>
            </div>
          </ReactTooltip>
        </div>
      ),

      selector: (row) => {
        return (
          <div onClick={() => handleBotRow(row)}>
            {row.RunStatus == "Start" && (
              <p className="greenTxt mb-2">Active </p>
            )}

            {row.RunStatus == "Pause" && (
              <p className="redText mb-2">Deactive </p>
            )}

            {/* <p className="tablebot_status mb-0">TU</p> */}
          </div>
        );
      },
    },

    {
      name: "Take Profit",
      width: "110px",

      selector: (row) => {
        return (
          <div onClick={() => handleBotRow(row)}>
            <p className="mb-0">{toFixed(row.takeProfit, 2)} %</p>
            <p className="mb-0">
              {toFixedDown(row?.takeprofit?.amount, 2)}{" "}
              <span className="greyTxt">USDT</span>
            </p>
          </div>
        );
      },
    },

    // {
    //   name: "Close",
    //   selector: (row) => {
    //     return (
    //       <Fragment>
    //         <button
    //           className="primary_btn primary_black_btn mt-0"
    //           data-bs-toggle="modal"
    //           data-bs-target="#sell_modal"
    //           onClick={(e) => buysell_modalClick(e, row)}
    //         >
    //           <img
    //             src={require("../../assets/images/ban_icon.png")}
    //             alt="Icon"
    //             className="img-fluid"
    //             width="18px"
    //           />
    //         </button>
    //       </Fragment>
    //     );
    //   },
    //   width: "80px",
    // },
  ];

  // Bot History Table
  const historyColumns = [
    {
      name: "Ex.",
      selector: (row) => {
        let imgSrc = require(`../../assets/images/${row.exchange.toLowerCase()}.png`);
        return (
          <Fragment>
            <div className="ex_type" onClick={() => handleHistoryRow(row)}>
              <img src={imgSrc} alt="Icon" className="img-fluid" width="18px" />
            </div>
          </Fragment>
        );
      },
      width: "70px",
    },
    {
      name: "Pair / Bot type",
      width: "120px",
      selector: (row) => {
        return (
          <div onClick={() => handleHistoryRow(row)}>
            <p className="mb-2">{`${row.firstCurrency} / ${row.secondCurrency}`}</p>
            <div className="table_pairtype">
              <p className="mb-0">DCA</p>
              <p className="mb-0"> Long</p>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: "Trade Amount",
    //   selector: (row) => {
    //     return (
    //       <p className="mb-0">
    //         {toFixed(row.orderValue, 2)}{" "}
    //         <span className="greyTxt">{row.secondCurrency}</span>
    //       </p>
    //     );
    //   },
    // },

    {
      name: "Current value",
      width: "140px",
      selector: (row) => {
        return (
          <p className="mb-0" onClick={() => handleHistoryRow(row)}>
            {toFixed(row.currentvalue, 4)}{" "}
            <span className="greyTxt">{row.secondCurrency}</span>
          </p>
        );
      },
    },
    // {
    //   name: "Bot profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Avg. daily profit",
    //   selector: (row) => {
    //     return (
    //       <div onClick={() => handleBotRow(row)}>
    //         <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
    //           {toFixed(row.pl, 2)} %
    //         </p>
    //         <p className="mb-0">
    //           {toFixed(row.pnlval, 4)} <span className="greyTxt">USDT</span>
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "PNL (With fee)",
      width: "140px",
      selector: (row) => {
        return (
          <div onClick={() => handleHistoryRow(row)}>
            <p className={row && row.pl < 0 ? "redText mb-0" : "greenTxt mb-0"}>
              {toFixedDown(row.pl, 2)} %
            </p>
            <p className="mb-0">
              {toFixedDown(row.pnlval, 2)} <span className="greyTxt">USDT</span>
            </p>
          </div>
        );
      },
    },

    // {
    //   name: "Bot profit",
    //   selector: (row) => row.botprofit,
    // },
    // {
    //   name: "Avg. daily profit",
    //   selector: (row) => row.daily,
    // },
    // {
    //   name: "Total PNL",
    //   selector: (row) => row.total,
    // },
    {
      name: "Txns / Time",

      selector: (row) => {
        return (
          <div onClick={() => handleHistoryRow(row)}>
            <p className="mb-2">{row?.filled?.length}</p>
            <p className="mb-0 greyTxt">
              {dateHoursclose(row.createdAt, row.updatedAt)}
            </p>
          </div>
        );
      },
    },

    {
      name: "Investment",

      selector: (row) => {
        return (
          <div onClick={() => handleHistoryRow(row)}>
            <p className="mb-2">{toFixed(row.investment, 4)}</p>
          </div>
        );
      },
    },

    {
      name: (
        <div>
          <p className="mb-0">Status</p>
        </div>
      ),
      selector: (row) => {
        return (
          <div onClick={() => handleHistoryRow(row)}>
            <p className="greyTxt mb-2">{firstCapital(row.status)}</p>
            <p className="tablebot_status mb-0">
              {dateHourswithTime(row.closeDate)}
            </p>
          </div>
        );
      },
    },
  ];
  const calPrice = (priceData, type) => {
    try {
      let total = 0,
        totalQty = 0,
        totalPrice = 0;
      if (type == "price") {
        for (let data of priceData) {
          total = total + data.price;
        }
        if (total > 0) {
          return total;
        }
      }
      if (type == "quantity") {
        for (let data of priceData) {
          total = total + data.quantity;
        }
        if (total > 0) {
          return parseFloat(total).toFixed(6);
        }
      }
      if (type == "checksell") {
        for (let data of priceData) {
          total = total + data.quantity * data.price;
          totalQty = totalQty + data.quantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      if (type == "Totalprice") {
        for (let data of priceData) {
          totalPrice = totalPrice + data.orgquantity * data.price;
        }
        return totalPrice;
      }

      if (type == "TotalpriceBM") {
        for (let data of priceData) {
          totalPrice =
            totalPrice + data.orgquantity * data.price + data.comissionFee;
        }
        return totalPrice;
      }

      if (type == "orgquantity") {
        for (let data of priceData) {
          total = total + data.orgquantity;
        }
        if (total > 0) {
          return parseFloat(total);
        }
      }

      if (type == "avgorgqty") {
        for (let data of priceData) {
          total = total + data.orgquantity * data.price;
          totalQty = totalQty + data.orgquantity;
        }
        if (total > 0) {
          return total / totalQty;
        }
      }

      return 0;
    } catch (err) {
      console.log(err, "----3333");

      return 0;
    }
  };

  const pairData = (data) => {
    let originalData = data.split("-");
    return originalData[0] + originalData[1];
  };

  const buysell_modalClick = async (e, row) => {
    e.preventDefault();
    fetTotalBal(row);
  };

  const fetTotalBal = async (data) => {
    try {
      let pairData = pair.replace(/\-/g, "");
      let reqData = {
        pair: data.pairName,
        botType: "GENIE",
        exchange: exchange,
      };
      const { status, result } = await getTotQuant(reqData);
      if (status == "success") {
        setTotQuant(result.quantity);

        let quantity = result.quantity,
          value = 100,
          orgValue = (quantity * value) / 100;

        let formData = {
          ...BSForm,
          ...{
            sellAmount: orgValue,
            orgPairName: data.pairName,
            firstCoin: data.firstCurrency,
          },
        };
        setBSForm(formData);
      }
    } catch (err) {
      console.log(err, "-------errror");
    }
  };

  useEffect(() => {
    return () => {
      let socketTicker = "socketTicker" + exchange;
      socketContext.socket.off("runningOrder");
      socketContext.socket.off("tradeHistory");
      socketContext[socketTicker].off("marketPrice");
    };
  }, [exchange, pair]);

  useEffect(() => {
    fetchDetail();
    fetchHistoryDetail(startDate, endDate);
    //fetchPenDetail();

    // socket
    let socketTicker = "socketTicker" + exchange;

    if (ctab == "history" || ctab == "Dhistory") {
      socketContext[socketTicker].off("marketPrice");
    } else {
      socketContext[socketTicker].on("marketPrice", (result) => {
        // console.log(result, "socket result");
        setData((el) => {
          let pairList = [];
          el &&
            el.map((item) => {
              if (item.pairId == result.pairId) {
                let avgPrice = item.avgPrice / item.quantity;

                let priceDecimal = item.priceDecimal;
                let combuybnbUsdt = 0;
                let finalQty = calPrice(item.filled, "quantity");
                finalQty = toFixedDown(finalQty, item?.quantityDecimal);
                let totalOrgQty = calPrice(item.filled, "orgquantity");

                let investmentPrice = calPrice(item.filled, "Totalprice");
                if (item.exchange == "BitMart") {
                  investmentPrice = calPrice(item.filled, "TotalpriceBM");
                }
                for (let itemfill of item?.filled) {
                  if (
                    !isEmpty(itemfill?.feeFilled) &&
                    itemfill?.feeFilled.length > 0
                  ) {
                    for (let buyfeeItem of itemfill?.feeFilled) {
                      if (
                        buyfeeItem?.commissionAsset == "BNB" &&
                        item.exchange == "Binance"
                      ) {
                        combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                      }
                    }
                  }
                }

                let exitOrdervalue = 0;
                // if (combuybnbUsdt > 0) {
                //   exitOrdervalue = result.data.markPrice * totalOrgQty;
                // } else {
                //   exitOrdervalue = result.data.markPrice * finalQty;
                // }

                exitOrdervalue = result.data.markPrice * finalQty;

                exitOrdervalue = exitOrdervalue - combuybnbUsdt;
                let finalProfit = exitOrdervalue - investmentPrice;
                let finalProfitPercentage =
                  (finalProfit / investmentPrice) * 100;
                finalProfit = toFixedDown(finalProfit, priceDecimal);

                console.log(finalQty, "hissoc1");
                console.log(exitOrdervalue, "hissoc3");
                console.log(result.data.markPrice, "hissoc4");
                console.log(finalProfit, "hissoc5");
                console.log(investmentPrice, "hissoc6");
                console.log(combuybnbUsdt, "hissoc7");
                console.log(finalProfitPercentage, "hissoc799");

                let investment = 0;
                for (let data of item.entryPrice) {
                  if (item.exchange == "BitMart") {
                    investment =
                      investment +
                      data.orgquantity * data.price +
                      data.comissionFee;
                  } else {
                    investment = investment + data.orgquantity * data.price;
                  }
                }
                let curVal = investmentPrice + finalProfit;
                pairList.push({
                  ...item,
                  cmp: result.data.markPrice,
                  pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
                  pnlval: finalProfit,
                  currentvalue: curVal,
                  investment: toFixed(investment, 4),
                  // profitinprice: profitinprice,
                  StrategyPeriod: item.tradeSetting.StrategyPeriod,
                  referralCode: item.referralCode,
                });
              } else {
                pairList.push(item);
              }
            });
          const sumOfFields = pairList.reduce((sum, currentField) => {
            return sum + parseFloat(currentField.currentvalue);
          }, 0);

          const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
            return sum + parseFloat(currentField.pl);
          }, 0);

          const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
            return sum + parseFloat(currentField.pnlval);
          }, 0);

          setTotCurrent(sumOfFields);
          setSumprofit({
            sumpnl: sumOfFields_pnl,
            sumpnlval: sumOfFields_pnl_val,
          });

          return pairList;
        });
      });
    }

    $(".nav-item #home-tab").addClass("active");
    $(".tab-content div[class=tab-pane]").addClass("active");

    $(".nav-item #profile-tab").removeClass("active");
    $(".tab-content #profile").removeClass("active");
  }, [botType, pair, exchange, startDate, endDate, ctab]);

  useEffect(() => {
    socketContext.socket.on("runningOrder", (result) => {
      // console.log(result, "Runn 8888");
      // if (result.pairId == tradePair.pairId) {
      let finalPair = pairData(pair);
      let pairList = [];
      if (exchange == result.exchange) {
        for (let [index, item] of result.data.entries()) {
          // console.log(item, "itemitemitem");
          if (exchange == item.exchange) {
            let avgPrice = item.avgPrice / item.quantity;
            let priceDecimal = item.priceDecimal;
            // let priceDecimal = 6;
            let combuybnbUsdt = 0;
            let takerCommission = item.takerCommission;
            let finalQty = calPrice(item.filled, "quantity");

            finalQty = toFixedDown(finalQty, item?.quantityDecimal);
            let totalOrgQty = calPrice(item.filled, "orgquantity");

            let investmentPrice = calPrice(item.filled, "Totalprice");

            if (item.exchange == "BitMart") {
              investmentPrice = calPrice(item.filled, "TotalpriceBM");
            }

            if (
              !isEmpty(item?.filled?.feeFilled) &&
              item?.filled?.feeFilled.length > 0
            ) {
              for (let buyfeeItem of item?.filled?.feeFilled) {
                if (
                  buyfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                }
              }
            }
            let exitOrdervalue = 0;
            // if (combuybnbUsdt > 0) {
            //   exitOrdervalue = item.markPrice * totalOrgQty;
            // } else {
            //   exitOrdervalue = item.markPrice * finalQty;
            // }

            exitOrdervalue = item.markPrice * finalQty;
            exitOrdervalue = exitOrdervalue - combuybnbUsdt;
            let finalProfit = exitOrdervalue - investmentPrice;
            let finalProfitPercentage = (finalProfit / investmentPrice) * 100;
            finalProfit = toFixedDown(finalProfit, priceDecimal);

            let investment = 0;
            for (let data of item.entryPrice) {
              if (item.exchange == "BitMart") {
                investment =
                  investment +
                  data.orgquantity * data.price +
                  data.comissionFee;
              } else {
                investment = investment + data.orgquantity * data.price;
              }
            }
            let cmp1 = item.markPrice * item.quantity,
              curVal = investment + finalProfit;
            let cmp = item.markPrice * item.quantity - avgPrice * item.quantity;
            // console.log(item, "item");
            pairList.push({
              ...item,
              currentvalue: curVal,
              cmp: item.markPrice,
              cmp1: cmp1,
              investment: investment ? toFixed(investment, 4) : 0.0,
              // currentvalue: investmentPrice + finalProfit,
              pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
              pnlval: finalProfit,
              // profitinprice: profitinprice,
              StrategyPeriod:
                item && item.tradeSetting && item.tradeSetting.StrategyPeriod
                  ? item.tradeSetting.StrategyPeriod
                  : "",
              referralCode: item.referralCode,
              takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
              index: index,
            });
            // if (
            //   item.pairName == pairData(pair) &&
            //   ctab != "history" &&
            //   ctab != "Dhistory"
            // ) {
            //   socketFetch({
            //     pair: item.pairName,
            //     exchange: exchange,
            //     type: "open",
            //     bot: "dca",
            //     orderId: item._id,
            //   });
            // }
          }
        }
        const sumOfFields = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.currentvalue);
        }, 0);
        const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pl);
        }, 0);
        const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pnlval);
        }, 0);
        setTotCurrent(sumOfFields);
        setSumprofit({
          sumpnl: sumOfFields_pnl,
          sumpnlval: sumOfFields_pnl_val,
        });
        setData(pairList);
      }
      // }
    });
  }, [botType, pair, exchange]);
  useEffect(() => {
    socketContext.socket.on("tradeHistory", (result) => {
      if (exchange == result.exchange) {
        result = result.data;

        let index = 0;
        let sumProfit = 0,
          totcurVal = 0,
          totinv = 0,
          sumprofitpercentage = 0;
        for (let [rIndex, history] of result.entries()) {
          let filledArr = history?.filled;
          let finalProfitPercentage,
            finalProfit,
            closeDate,
            entrypricetot = 0,
            exitpricetot = 0,
            investmentPrice = 0,
            feeUsdt = 0,
            comsellbnbusdt = 0,
            combuybnbUsdt = 0;

          for (let item of filledArr) {
            if (item.buyorsell == "buy") {
              if (item.exchange == "BitMart") {
                feeUsdt = item.comissionFee;
              }
              entrypricetot += item.price * item.orgquantity + feeUsdt;
              investmentPrice += item.price * item.orgquantity + feeUsdt;

              if (!isEmpty(item?.feeFilled) && item?.feeFilled.length > 0) {
                for (let buyfeeItem of item?.feeFilled) {
                  if (
                    buyfeeItem?.commissionAsset == "BNB" &&
                    item.exchange == "Binance"
                  ) {
                    combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                  }
                }
              }
            }

            if (item.buyorsell == "sell") {
              // if (combuybnbUsdt > 0) {
              //   exitpricetot += item.price * item.orgquantity;
              // } else {
              //   exitpricetot += item.price * item.quantity;
              // }
              exitpricetot += item.price * item.quantity;

              closeDate = item.createdAt;

              if (!isEmpty(item?.feeFilled) && item?.feeFilled.length > 0) {
                for (let sellfeeItem of item?.feeFilled) {
                  if (
                    sellfeeItem?.commissionAsset == "BNB" &&
                    item.exchange == "Binance"
                  ) {
                    comsellbnbusdt += parseFloat(sellfeeItem?.commissionUSDT);
                  }
                }
              }
            }
          }
          let exitovalue = 0;

          if (exitpricetot > 0) {
            if (comsellbnbusdt == 0) {
              exitovalue =
                exitpricetot - exitpricetot * history.takerCommission;
            } else {
              exitovalue = exitpricetot - comsellbnbusdt;
            }
            finalProfit = exitovalue - combuybnbUsdt - investmentPrice;
            finalProfitPercentage = (finalProfit / investmentPrice) * 100;
            finalProfit = toFixedDown(finalProfit, history.priceDecimal);
          }

          let curVal = investmentPrice + finalProfit;
          result[index].currentvalue = curVal;
          result[index].pl = finalProfitPercentage;
          result[index].pnlval = finalProfit;
          result[index].investment = investmentPrice;
          result[index].closeDate = closeDate;
          result[index].index = rIndex;
          result[index].firstCurrency =
            result[index].Tradesetings?.firstCurrency;
          result[index].secondCurrency =
            result[index].Tradesetings?.secondCurrency;

          sumProfit += parseFloat(finalProfit);
          sumprofitpercentage += parseFloat(finalProfitPercentage);
          totcurVal += curVal;
          totinv += investmentPrice;
          index++;
          // if (
          //   history.pairName == pairData(pair) &&
          //   ctab != "history" &&
          //   ctab != "Dhistory"
          // ) {
          //   dispatch(setBotStatus({ status: false, crtBot: false }));
          //   socketFetch({
          //     pair: history.pairName,
          //     exchange: exchange,
          //     type: "history",
          //     bot: "dca",
          //     orderId: history.positionId,
          //   });
          // }
        }

        setSumprofitHistory({
          sumpnl: sumprofitpercentage,
          sumpnlval: sumProfit,
          curVal: totcurVal,
          inv: totinv,
        });

        setHistoryData(result);
      }
    });
  }, [botType, pair, exchange]);

  const handleSellSubmit = async (value) => {
    try {
      // let reqData = {},
      //   orgValue = (totalQuant * 100) / 100,
      //   orgPerc = 0;
      // if (parseFloat(sellAmount) == parseFloat(orgValue)) {
      //   orgPerc = 100;
      // }
      let reqData = {};
      let orgPerc = 100;
      if (value == "sell") {
        setSellLoader(true);
        let pairData = pair.replace(/\-/g, "");

        reqData = {
          pairName: orgPairName,
          amount: sellAmount,
          exchange: exchange,
          BotType: "GENIE",
          formType: "sellGenie",
          percentage: orgPerc > 0 ? orgPerc : posPerc,
        };
      }
      // if (value == "clrSell") {
      //   setClrLoad(true);
      //   reqData = {
      //     pairName: data.tikerRoot ? data.tikerRoot : data.pairName,
      //     amount: totalQuant,
      //     exchange: params.exchange,
      //     BotType: params.bot.toUpperCase(),
      //     formType: "sellGenie",
      //   };
      // }
      let validationError = validation(reqData);
      if (!isEmpty(validationError)) {
        setValidateError(validationError);
        setSellLoader(false);
        return;
      }
      const { status, message } = await comManSellOrder(reqData);
      if (value == "sell") {
        setSellLoader(false);
      }
      // if (value == "clrSell") {
      //   setClrLoad(false);
      // }
      if (status == "success") {
        toastAlert("success", message, "save");
        fetTotalBal();
        setBSForm(buySell);

        // fetchStatusTrade();
      } else {
        toastAlert("error", message, "error");
      }
    } catch (err) {
      console.log(err, "-------145");
    }
  };
  const fetchDetail = async () => {
    try {
      let reqData = {
        botType: "GENIE",
        exchange,
        pair: pairData(pair),
      };
      const { status, result, pairDetails } = await getOpenOrders(reqData);
      if (status == "success") {
        // console.log(pairDetails, "-----606");
        let pairList = [];

        for (let [index, item] of result.entries()) {
          let avgPrice = item.avgPrice / item.quantity;
          let priceDecimal = item.priceDecimal;
          let finalQty = calPrice(item.filled, "quantity");
          let totalOrgQty = calPrice(item.filled, "orgquantity");
          let combuybnbUsdt = 0;
          finalQty = toFixedDown(finalQty, item?.quantityDecimal);

          // console.log(finalQty, "------finalQty");
          let investmentPrice = calPrice(item.filled, "Totalprice");
          if (item.exchange == "BitMart") {
            investmentPrice = calPrice(item.filled, "TotalpriceBM");
          }

          for (let itemfill of item?.filled) {
            if (
              !isEmpty(itemfill?.feeFilled) &&
              itemfill?.feeFilled.length > 0
            ) {
              for (let buyfeeItem of itemfill?.feeFilled) {
                if (
                  buyfeeItem?.commissionAsset == "BNB" &&
                  item.exchange == "Binance"
                ) {
                  combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                }
              }
            }
          }

          let pairData = pairDetails.find(
            (el) => el._id.toString() == item.pairId.toString()
          );
          let exovaluewfee;
          // if (combuybnbUsdt > 0) {
          //   exovaluewfee = pairData.markPrice * totalOrgQty;
          // } else {
          //   exovaluewfee = pairData.markPrice * finalQty;
          // }

          exovaluewfee = pairData.markPrice * finalQty;

          exovaluewfee = exovaluewfee - combuybnbUsdt;
          let finalProfit = exovaluewfee - investmentPrice;
          finalProfit = toFixedDown(finalProfit, priceDecimal);
          let finalProfitPercentage = (finalProfit / investmentPrice) * 100;

          console.log(finalQty, "his1");
          console.log(totalOrgQty, "his3");
          console.log(exovaluewfee, "his3");
          console.log(pairData.markPrice, "his4");
          console.log(finalProfit, "his5");
          console.log(investmentPrice, "his6");
          console.log(combuybnbUsdt, "his7");

          let investment = 0;
          for (let data of item.entryPrice) {
            if (item.exchange == "BitMart") {
              investment =
                investment + data.orgquantity * data.price + data.comissionFee;
            } else {
              investment = investment + data.orgquantity * data.price;
            }
          }

          let cmp1 = pairData.markPrice * item.quantity;

          let cmp =
            pairData.markPrice * item.quantity - avgPrice * item.quantity;

          // console.log(investment, "investment----");
          // console.log(finalProfit, "finalProfit----");
          let curVal = investment + finalProfit;
          // console.log(curVal, "-------641");
          pairList.push({
            ...item,
            currentvalue: curVal,
            cmp: pairData.markPrice,

            cmp1: cmp1,
            // investment: investment ? toFixed(investment, 4) : 0.0,
            investment: investment ? investment : 0.0,
            pl: finalProfitPercentage ? finalProfitPercentage : 0.0,
            pnlval: finalProfit,
            // profitinprice: profitinprice,
            referralCode: item.referralCode,
            takeProfit: item.tradeSetting.Tradestrategy[0].takeprofitRatio,
            index: index,
          });
        }
        const sumOfFields = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.currentvalue);
        }, 0);

        const sumOfFields_pnl = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pl);
        }, 0);

        const sumOfFields_pnl_val = pairList.reduce((sum, currentField) => {
          return sum + parseFloat(currentField.pnlval);
        }, 0);

        setTotCurrent(sumOfFields);
        setSumprofit({
          sumpnl: sumOfFields_pnl,
          sumpnlval: sumOfFields_pnl_val,
        });

        setData(pairList);
      }
    } catch (err) {
      console.log(err, "00087");
    }
  };
  const fetchHistoryDetail = async (sDate, lDate) => {
    try {
      let reqData = {
        botType: "GENIE",
        pair: pairData(pair),
        exchange,
        sDate,
        lDate,
      };
      const { status, loading, result, message, error } = await getTradeHistory(
        reqData
      );
      if (status == "success") {
        let index = 0;
        let sumProfit = 0,
          totcurVal = 0,
          sumprofitpercentage = 0,
          totinv = 0;

        for (let [rIndex, history] of result.entries()) {
          let filledArr = history?.filled;
          let finalProfitPercentage,
            finalProfit,
            closeDate,
            exitpricetot = 0,
            investmentPrice = 0,
            feeUsdt = 0,
            combuybnbUsdt = 0,
            comsellbnbusdt = 0,
            exitovalue = 0;

          for (let item of filledArr) {
            if (item.buyorsell == "buy") {
              if (history.exchange == "BitMart") {
                feeUsdt = parseFloat(item.comissionFee);
              }
              investmentPrice += item.price * item.orgquantity + feeUsdt;
              if (!isEmpty(item?.feeFilled) && item?.feeFilled.length > 0) {
                for (let buyfeeItem of item?.feeFilled) {
                  if (
                    buyfeeItem?.commissionAsset == "BNB" &&
                    history.exchange == "Binance"
                  ) {
                    combuybnbUsdt += parseFloat(buyfeeItem?.commissionUSDT);
                  }
                }
              }
            }

            if (item.buyorsell == "sell") {
              // if (combuybnbUsdt > 0) {
              //   exitpricetot += item.price * item.orgquantity;
              // } else {
              //   exitpricetot += item.price * item.quantity;
              // }
              console.log(item.quantity, "hii000");
              exitpricetot += item.price * item.quantity;
              closeDate = item.createdAt;
              if (!isEmpty(item?.feeFilled) && item?.feeFilled.length > 0) {
                for (let sellfeeItem of item?.feeFilled) {
                  if (
                    sellfeeItem?.commissionAsset == "BNB" &&
                    history.exchange == "Binance"
                  ) {
                    comsellbnbusdt += parseFloat(sellfeeItem?.commissionUSDT);
                  }
                }
              }
            }
          }

          if (exitpricetot > 0) {
            if (comsellbnbusdt == 0) {
              exitovalue =
                exitpricetot - exitpricetot * result[0].takerCommission;
            } else {
              exitovalue = exitpricetot - comsellbnbusdt;
            }

            finalProfit = exitovalue - combuybnbUsdt - investmentPrice;

            finalProfitPercentage = (finalProfit / investmentPrice) * 100;

            finalProfit = toFixedDown(finalProfit, history.priceDecimal);

            console.log(exitovalue, "hiiii1");
            console.log(exitpricetot, "hiiii2");
            console.log(combuybnbUsdt, "hiiii3");
            console.log(comsellbnbusdt, "hiiii4");
            console.log(finalProfit, "hiiii5");
            console.log(investmentPrice, "hiiii6");
            console.log(finalProfitPercentage, "hiiii7");
            console.log(history.priceDecimal, "hiiii8");
            console.log(history.pairName, "hiiii9");
          }

          let curVal = investmentPrice + finalProfit;
          result[index].currentvalue = curVal;
          result[index].pl = finalProfitPercentage;
          result[index].pnlval = finalProfit;
          result[index].investment = investmentPrice;
          result[index].closeDate = closeDate;
          result[index].index = rIndex;
          result[index].firstCurrency =
            result[index].Tradesetings?.firstCurrency;
          result[index].secondCurrency =
            result[index].Tradesetings?.secondCurrency;

          sumProfit += parseFloat(finalProfit);
          sumprofitpercentage += parseFloat(finalProfitPercentage);
          totcurVal += curVal;
          totinv += investmentPrice;
          index++;
        }

        setHistoryData(result);

        setSumprofitHistory({
          sumpnl: sumprofitpercentage,
          sumpnlval: sumProfit,
          curVal: totcurVal,
          inv: totinv,
        });
      }
    } catch (err) {}
  };

  const chartChange = async (data, type) => {
    let tikerPair = data.pairName.split("USDT").join("-USDT");

    // let tikerPair = `${data.pairName}-${data.pairName}`;
    navigate(`/trade/${tikerPair}/${data.exchange}/dca?tb=${type}`);
    dispatch(setBotType({ tab: type }));
    dispatch(
      setBotStatus({
        status: true,
        type: type,
        id: data._id,
        positionId: type == "open" ? data._id : data.positionId,
        crtBot: false,
      })
    );
    updateChartData({
      pair: tikerPair,
      exchange: data.exchange,
      type: type,
      bot: "dca",
      orderId: type == "open" ? data._id : data.positionId,
      botId: data._id,
    });
  };
  const handleBotRow = (data, index) => {
    dispatch(
      setBotId({
        positionId: data._id,
      })
    );
    chartChange(data, "open");
  };

  const handleHistoryRow = (data) => {
    dispatch(
      setBotId({
        positionId: "",
      })
    );
    chartChange(data, "history");
  };

  const handletabTrade = (status, tab) => {
    // console.log("---------933", tab);
    navigate(`/trade/${pair}/${exchange}/dca?tb=${tab}`);

    setHistoryStatus(status);
    dispatch(setBotType({ tab: tab }));
    dispatch(setBotId({ positionId: "" }));
  };
  const handleDownload = async () => {
    let csvData = [
      [
        "Exchange",
        "Bot",
        "Pair / Bot type",
        "Current Value",
        "PNL(With fee) %",
        "PNL(With fee)",
        "Txns / Time",
        "Investment",
        "Status",
        "Closed Time",
      ],
    ];
    for (let item of historyData) {
      let arrData = [
        firstCapital(item.exchange),
        "dca",
        `${item.firstCurrency} / ${item.secondCurrency}`,
        `${toFixed(item.currentvalue, 4)} USDT`,
        `${toFixed(item.pl, 2)} %`,
        `${toFixed(item.pnlval, 2)} USDT`,
        `${item?.filled?.length} /  ${dateHoursclose(
          item.createdAt,
          item.updatedAt
        )}`,
        toFixed(item.investment, 4),
        firstCapital(item.status),
        dateTimeFormat(item.closeDate),
      ];
      csvData.push(arrData);
    }

    // Convert the data to CSV format
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    // Create a link element
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "tradehistory-dca.csv");
    document.body.appendChild(link);

    // Trigger the download
    link.click();
  };

  useEffect(() => {
    // Update popperPlacement based on screen width
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setPopperPlacement("left-end");
      } else {
        setPopperPlacement("bottom");
      }
    };

    // Initial placement based on screen width
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Function to filter dates after today
  const filterPassedDate = (date) => {
    const currentDate = new Date();
    return date <= currentDate;
  };
  const handleDate = (update) => {
    setDateRange(update);
    if (update[0] != null && update[1] != null) {
      fetchHistoryDetail(update[0], update[1]);
    }
  };

  $(".rdt_TableRow").click(function () {
    $(".rdt_TableRow").removeClass("selected-row-active");
    $(this).addClass("selected-row-active");
  });

  return (
    <div className="trade_main_left_bottom">
      <div className="card trade_card trade_history_card">
        <div className="card-header">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={historyStatus ? "nav-link active" : "nav-link"}
                onClick={() => handletabTrade(true, "Dopen")}
              >
                Spot Bots ({data && data.length})
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={!historyStatus ? "nav-link active" : "nav-link"}
                onClick={() => handletabTrade(false, "Dhistory")}
              >
                Bot History
              </button>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between flex-wrap my-2">
            <div className="bot_profit gap-1 flex-wrap w-100 justify-content-between align-items-center">
              {/* <p>
              <span>Sum, bot profit</span> $ 709.31{" "}
              <span className="greenTxt">+0.85%</span>
            </p> */}
              {historyStatus && historyStatus && (
                <>
                  <p>
                    <span>Sum, current value</span> $ {toFixed(totCurValue, 2)}
                  </p>
                  {/* <p>
                  <span>Sum, bot profit</span> ${" "}
                  {toFixed(sumProfit.sumpnlval, 2)}{" "}
                  <span
                    className={sumProfit.sumpnl > 0 ? "greenTxt" : "redText"}
                  >
                    {`${sumProfit.sumpnl > 0 ? "+" : "-"}${toFixed(
                      sumProfit.sumpnl,
                      4
                    )}`}
                    %
                  </span>
                </p> */}
                </>
              )}

              {!isEmpty(sumProfitHistory.sumpnl) && !historyStatus && (
                <>
                  <div className="d-flex flex-wrap gap-3">
                    <p>
                      <span>Sum, current value</span> ${" "}
                      {toFixed(sumProfitHistory.curVal, 2)}
                    </p>
                    <p>
                      <span>Sum, bot profit</span> ${" "}
                      {toFixed(sumProfitHistory.sumpnlval, 2)}{" "}
                      <span
                        className={
                          sumProfitHistory.sumpnlval > 0
                            ? "greenTxt"
                            : "redText"
                        }
                      >
                        {`${sumProfitHistory.sumpnlval > 0 ? "+" : ""}${toFixed(
                          (parseFloat(sumProfitHistory.sumpnlval) /
                            parseFloat(sumProfitHistory.inv)) *
                            100,
                          2
                        )}`}
                        %
                      </span>
                    </p>
                    <p>
                      <span>Sum, Investment</span> ${" "}
                      {toFixed(sumProfitHistory.inv, 2)}
                    </p>
                  </div>
                </>
              )}
              {!historyStatus && (
                <div className="filter_flx filter_flx_chg align-items-center date_cal">
                  <div className="dat">
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => handleDate(update)}
                      popperPlacement={popperPlacement}
                      filterDate={filterPassedDate} // Apply the filter function
                    />
                  </div>
                  <div className="svg_i">
                    <svg
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="DateRangeIcon"
                    >
                      <path d="M9 11H7v2h2zm4 0h-2v2h2zm4 0h-2v2h2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 16H5V9h14z"></path>
                    </svg>
                  </div>
                  <div
                    className="delete_cal"
                    onClick={() => setDateRange([null, null])}
                  >
                    <img
                      src={require(`../../assets/images/Calendar-Delete.png`)}
                      alt="Icon"
                      className="img-fluid"
                      width="18px"
                    />
                  </div>
                  <button class="continue w-auto" onClick={handleDownload}>
                    Download CSV
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            {historyStatus && (
              <div>
                <div className="primary_table primary_table_full_height">
                  <div className="table-responsive">
                    <DataTable
                      columns={spotColumns}
                      data={data}
                      pagination
                      onRowClicked={(data) => handleBotRow(data)}
                    />
                  </div>
                </div>
              </div>
            )}

            {!historyStatus && (
              <div>
                <div className="primary_table clickable-text primary_table_full_height">
                  <div className="table-responsive">
                    <DataTable
                      columns={historyColumns}
                      data={historyData}
                      pagination
                      onRowClicked={(data) => handleHistoryRow(data)}
                    />
                  </div>
                </div>
                {/* <p className="text-center mt-5">No Records Found</p> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Close Modal PopUp */}
      <div
        class="modal fade primary_modal"
        id="sell_modal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="sell_modal_label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="sell_modal_label">
                Are you sure to close this order?
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    value={sellAmount}
                    name="sellAmount"
                    className="form-control"
                  />
                  <div className="input-group-text">
                    {/* {splitOne(pair, "first")} */}
                    {firstCoin}
                  </div>
                </div>
                {/* {validateError && validateError.sellAmount && (
                  <p className="text-danger">{validateError.sellAmount}</p>
                )} */}
              </div>
              {/* <div
                className="input-group-text"
                onClick={() => handlePercent("25")}
              >
                25%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("50")}
              >
                50%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("75")}
              >
                75%
              </div>{" "}
              <div
                className="input-group-text"
                onClick={() => handlePercent("100")}
              >
                100%
              </div>{" "} */}
              <div className="text-center">
                <button
                  class="primary_btn primary_blue_btn w-50"
                  data-bs-dismiss="modal"
                  onClick={() => handleSellSubmit("sell")}
                  disabled={sellLoader}
                >
                  {sellLoader && <div class="loader"></div>}
                  {!sellLoader && "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
