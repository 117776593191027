// import package
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import $ from "jquery";
// import component
import EmailForm from "./EmailForm";
import MobileForm from "./MobileForm";
import Logo from "../../components/Logo";

const LoginForm = () => {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(".radio_inp_email").click(function () {
      $(".radio_inp_email").addClass("active");
      $(".radio_inp_mobile").removeClass("active");
    });
    $(".radio_inp_mobile").click(function () {
      $(".radio_inp_mobile").addClass("active");
      $(".radio_inp_email").removeClass("active");
    });
  }
  const createMarkup = (a) => {
    return { __html: a };
  };

  const [data, setData] = useState("emailform");
  const handleClick = (tab) => {
    // e.preventDefault();
    // console.log(tab, "______e");
    setData(tab);
  };

  return (
    <div className="row">
      <div className="col-md-6 col-lg-6 col-xl-5 mx-auto logo_beforeauth">
        <Logo />
        <div className="crediantial-form">
          <h2>LOGIN</h2>
          <h6>Enter details to sign in to your account</h6>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              {/* <button
                onClick={() => handleClick("emailform")}
                className="nav-link active"
                value="email"
                id="nav-email-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-email"
                type="button"
                role="tab"
                aria-controls="nav-email"
                aria-selected="true"
              >
                Email
              </button> */}
              {/* <button
                onClick={() => handleClick("mobileform")}
                className="nav-link"
                value="mobile"
                id="nav-mobNumber-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-mobNumber"
                type="button"
                role="tab"
                aria-controls="nav-mobNumber"
                aria-selected="false"
              >
                Mobile Number
              </button> */}
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade active show"
              id="nav-email"
              role="tabpanel"
              aria-labelledby="nav-email-tab"
            >
              {/*<div className="form-group">
                                        <label>Email Address</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                         <div className="label-flex">
                                            <label className="mb-0">Password</label>
                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </div>
                                        <input type="password" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <div className="switch-group">
                                            <label htmlFor="flexSwitchCheckChecked">2FA Code (Optional)</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                            </div>
                                        </div>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <button className="btn btn-green-big">Submit</button>
                                    </div>*/}
              <EmailForm tab={data} />
            </div>

            <div
              className="tab-pane fade"
              id="nav-mobNumber"
              role="tabpanel"
              aria-labelledby="nav-mobNumber-tab"
            >
              {/* <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <div className="label-flex">
                                            <label className="mb-0">Password</label>
                                            <Link to="/forgot-password">Forgot Password</Link>
                                        </div>
                                        <input type="password" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <div className="switch-group">
                                            <label htmlFor="flexSwitchCheckChecked">2FA Code (Optional)</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                            </div>
                                        </div>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <button className="btn btn-green-big">Submit</button>
                                    </div>*/}
              <MobileForm tab={data} />
            </div>
          </div>
        </div>
        {/* <h6 className="crediantila-bottom">
          Don’t have an account? <Link to="/">Sign up here</Link>
        </h6> */}
      </div>
    </div>
  );
};

export default LoginForm;
