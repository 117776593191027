// import package
import io from "socket.io-client";

// import lib
import config from "./index";
import store from "../store";

const socket = io(config.SOCKET_URL);
const socketTickerBinance = io(config.SOCKET_TICKERBINANCE_URL);
const socketTickerBitMart = io(config.SOCKET_TICKERBITMART_URL);
// const socketTickerKucoin = io(config.SOCKET_TICKERKUCOIN_URL);
const socketTickerBybit = io(config.SOCKET_TICKERBYBIT_URL);

const createSocketUser = (userId) => {
  socket.emit("CREATEROOM", userId);
};

socket.on("disconnect", function () {
  console.log("socket disconnected");
  const { isAuth, userId } = store.getState().auth;
  if (isAuth == true && userId) {
    // console.log(userId, "userIduserIduserId");
    createSocketUser(userId);
  }
});

// socketContext.socket.on('openOrder', (data) => {
//     console.log("----data--", data)
//     if (data.pairId == tradePair.pairId) {
//         // setPendingOrder(data.result)
//     }
// })

export {
  socket,
  socketTickerBinance,
  socketTickerBitMart,
  socketTickerBybit,
  // socketTickerKucoin,
  createSocketUser,
};
