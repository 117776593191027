import { combineReducers } from "redux";
import authReducer from "./authReducer";
// import errorReducer from "./errorReducers";

import account from "./account";
import kycValidate from "./kycValidate";
import tradeThemeReducers from "./tradeTheme";
import exchange from "./exchangeType";
import botType from "./botType";
import botStatus from "./botStatus";
import botEdit from "./botEdit";
import botId from "./botId";
import priceConversion from "./priceConversion";
import GridBotStatus from "./gridbotstatus";
import curGridBot from "./curGridBot";
import openSBGrid from "./openSBGrid";

export default combineReducers({
  auth: authReducer,
  account,
  kycValidate,
  exchange,
  botType,
  tradeThemeReducers,
  botStatus,
  botEdit,
  priceConversion,
  GridBotStatus,
  curGridBot,
  openSBGrid,
  botId,
});
