// import package
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  useRoutes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import HelperRoute from "./components/Route/HelperRoute";
import ConditionRoute from "./components/Route/ConditionRoute";
import Login from "./pages/Login.js";
import Trade from "./pages/Trade.js";
import NotificationSystemErrors from "./pages/NotificationSystemErrors.js";
import NotificationTradeRelated from "./pages/NotificationTradeRelated.js";
import NotificationAlerts from "./pages/NotificationAlerts.js";
import ApiBinding from "./pages/ApiBinding.js";
import EmailVerification from "./pages/EmailVerification.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import Profile from "./pages/Profile.js";
import TradeManual from "./pages/TradeManual.js";
import Security from "./pages/Security.js";
import ResetPassword from "./pages/ResetPassword.js";
import Terms from "./pages/terms";

// import routes from './routes'

// import action
import { decodeJwt } from "./actions/jsonWebToken";

// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";

// import Context
import SocketContext from "./components/Context/SocketContext";
// import config
import {
  socket,
  socketTickerBinance,
  socketTickerBitMart,
  socketTickerKucoin,
  socketTickerBybit,
} from "./config/socketConnectivity";
import Portfolio from "./pages/portfolio";
import Myexchange from "./pages/my-exchange";

function App() {
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <Router basename="/">
        <SocketContext.Provider
          value={{
            socket,
            socketTickerBinance,
            socketTickerBitMart,
            socketTickerBybit,
            // socketTickerKucoin,
          }}
        >
          <ToastContainer />
          <HelperRoute />
          <Routes>
            <Route
              path="/"
              element={
                <ConditionRoute type="auth">
                  <Login />
                </ConditionRoute>
              }
            />

            <Route
              path="/signin"
              element={
                <ConditionRoute type="auth">
                  <Login />
                </ConditionRoute>
              }
            />
            <Route
              path="/reset-password/:authToken"
              element={
                <ConditionRoute type="auth">
                  <ResetPassword />
                </ConditionRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <ConditionRoute type="auth">
                  <ForgotPassword />
                </ConditionRoute>
              }
            />
            <Route
              path="/trade/:pair/:exchange/:bot"
              element={
                <ConditionRoute type="private">
                  <Trade />
                </ConditionRoute>
              }
            />

            <Route
              path="/notification-system-errors"
              element={
                <ConditionRoute type="private">
                  <NotificationSystemErrors />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-trade-related"
              element={
                <ConditionRoute type="private">
                  <NotificationTradeRelated />
                </ConditionRoute>
              }
            />
            <Route
              path="/notification-alerts"
              element={
                <ConditionRoute type="private">
                  <NotificationAlerts />
                </ConditionRoute>
              }
            />
            <Route
              path="/api-binding"
              element={
                <ConditionRoute type="private">
                  <ApiBinding />
                </ConditionRoute>
              }
            />
            <Route
              path="/email-verification/:authToken"
              element={
                <ConditionRoute type="auth">
                  <EmailVerification />
                </ConditionRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ConditionRoute type="private">
                  <Profile />
                </ConditionRoute>
              }
            />
            <Route
              path="/trade-signal/:exchange"
              element={
                <ConditionRoute type="private">
                  <TradeManual />
                </ConditionRoute>
              }
            />
            <Route
              path="/security"
              element={
                <ConditionRoute type="private">
                  <Security />
                </ConditionRoute>
              }
            />
            <Route
              path="/portfolio"
              element={
                <ConditionRoute type="public">
                  <Portfolio />
                </ConditionRoute>
              }
            />
            <Route
              path="/my-exchange"
              element={
                <ConditionRoute type="public">
                  <Myexchange />
                </ConditionRoute>
              }
            />

            <Route
              path="/terms"
              element={
                <ConditionRoute type="auth">
                  <Terms />
                </ConditionRoute>
              }
            />
          </Routes>
        </SocketContext.Provider>
      </Router>
    </Provider>
  );
}
export default App;
